@import "../../variables.scss";

ctw-journey-planner-print{
    
    legend {
        font-size: 1rem
        }

    .cw-real-time {
        color: #e79200;
        font-size: 0.9rem;
        transform: translatey(-50%)
        }

    .alert-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        flex-shrink: 0;
        width: 1.625rem;
        height: 1.625rem;
        border-radius: 100rem
        }

    .alert-title {
        font-weight: 700
        }

    .alert-link {
        display: inline;
        padding: 0;
        border: 0;
        text-decoration: underline;
        line-height: 1.3
        }
        
    .alert-info .alert-icon {
        background-color: #0b40a2
        }

    .mode-rounded {
        padding: 0.35rem;
        font-size: 1.2rem;
        color: #fff;
        border: #444;
        border-radius: 100rem;
        background-color: #444
        }

    .item-price {
        padding: 0.2rem 0.5rem;
        font-size: 0.875rem;
        color: #fff;
        font-weight: 400;
        white-space: nowrap;
        border-radius: 100rem;
        background-color: #0b4f6c
        }

    .item-line {
        position: relative;
        max-width: 11rem;
        padding: 0;
        color: #fff;
        border: 0.125rem solid #000;
        border-radius: 0.7rem;
        }

    .item-line-mode {
        margin: 0.2rem;
        font-size: 1.4rem
        }

    .item-line-number {
        min-width: 2rem;
        margin: .2rem .3rem .2rem 0;
        padding: .25rem .2rem;
        font-size: .9375rem;
        color: #333;
        text-align: center;
        font-weight: 700;
        line-height: 1;
        word-wrap: break-word;
        border-radius: .5rem;
        background-color: #fff;
    }

    .item-location-label {
        margin-right: 0.5rem;
        font-size: 0.875rem;
        }

    .item-location-content {
        font-size: 0.875rem;
        }

    .next-departure-item-time {
        font-size: 0.75rem;
        font-weight: 700;
        white-space: nowrap
        }
//--
    .road-sheet-card {
        margin-bottom: 0.9375rem
        }
    .road-sheet-card-mode {
        flex-shrink: 0;
        justify-content: center;
        width: 3.75rem;
        margin-right: 1rem;
        text-align: center;
    }
    .road-sheet-card-info {
        font-size: .875rem;
        font-weight: 700;
    }
    .road-sheet-card-hour {
        width: 3.75rem;
        margin-right: 1rem;
        text-align: center;
        flex-shrink: 0;
    }
    .road-sheet-card-mode-item.middle {
        min-height: 1rem;
        border-left: .5rem dotted #6A6A6A;
    }
    .road-sheet-card .card-body > div:first-child {
        margin-bottom: 0.5rem
        }
    .road-sheet-card-mode-item {
        text-align: center
        }
    .road-sheet-card-mode-item .item-line-mode {
        margin: 0
        }

    .road-sheet-card-network {
        font-size: 0.75rem
        }

    .road-sheet-card-info-stop-content {
        margin-left: 1rem
        }
    .road-sheet-card-info-stop-content-location {
        color: #6a6a6a;
        font-weight: 400
        }

    .road-sheet-card-info-next-departure {
        margin-top: 0.5rem
        }

    .road-sheet-card:hover, .road-sheet-card:active, .road-sheet-card:focus-within {
        box-shadow: 0 0 0.4375rem #00000059;
    }

    .print-container {
        max-width: 650px;
        margin: 2rem auto;
        padding: 0 1rem;
        background-color: #fff
        }
    .print-container > .card {
        margin-top: 2rem
        }
    .print-container .map {
        min-height: 27rem
        }
    .print-container .road-sheet-card-pt .road-sheet-card-info {
        display: flex;
        flex-direction: column
        }
    .print-container .road-sheet-card-pt .road-sheet-card-info > div {
        margin-top: auto
        }
    .print-container legend {
        font-size: 0.875rem;
        font-weight: 700
        }
    .print-logo { // todo : fix
        width: 10rem;
        height: 3.5rem;
        background-image: url(../site/new-logo-myastuce.png);
        background-repeat: round;
        margin-bottom: 10px;
        }
    .print-option-card-btn {
        display: block;
        margin-left: auto;
        background-color: $primary;
        border-color: $primary;
        }
    .print-summary-card-dl {
        display: flex;
        flex-wrap: wrap
        }
    .print-summary-card-dt {
        width: 30%;
        font-weight: 400
        }
    .print-summary-card-dd {
        width: 70%;
        font-weight: 700
        }
    .print-summary-card-dd .list-inline-item {
        margin-bottom: 1rem
        }
    
    // Map
    .print-plan-card{
        .leaflet-container {
            width: 92%;
        }
        .marker-label-terminus, .marker-label {
            white-space: nowrap;
            position: absolute;
            left: 50%;
            padding: .2rem .5rem;
            font-family: Nunito, Times, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol;
            font-size: .75rem;
            border-radius: .6875rem;
            background-color: #535353;
            transform: translate(-50%, 110%);
        }
        .marker-label {
            bottom: 0;
            color: #fff;
            text-transform: lowercase;
        }
        .departure-marker-icon, .via-marker-icon, .arrival-marker-icon {
            font-size: 1.3rem;
            border-radius: 100rem;
            background-color: $primary;
        }
        .intermediate-stop-marker{
            font-size: 1rem;
        }
        .intermediate-stop-marker, .stop-marker {
            position: relative;
            z-index: -1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100rem;
            background-color: #fff;
        }
        .stop-marker{
            font-size: 1.3rem;
        }
        .line-marker:after {
            content: "";
            position: absolute;
            top: calc(100% + .125rem);
            left: calc(50% - .125rem);
            width: .25rem;
            height: 1.35rem;
            border-radius: 0 0 .1875rem .1875rem;
            background-color: #000;
        }
    }
}