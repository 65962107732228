@import "../../variables.scss";
@import "../../mixins.scss";

.modal.modal-jp-options {
  .modal-dialog-scrollable {
    .modal-content {
      .modal-header {
        margin: 0;
        align-items: center;
        padding: 1.5625rem 1.25rem;
      }
      .modal-body {
        .custom-switch.custom-switch-right {
          padding-left: 0;
        }
        .custom-switch .custom-control-label .optyweb {
          margin-right: 0.5rem;
        }
        .custom-switch.custom-switch-right
          .custom-control-input
          ~ .custom-control-label {
          padding-right: 3rem;
          display: flex;
          align-items: center;
        }
        .custom-switch.custom-switch-right
          .custom-control-input
          ~ .custom-control-label:before {
          right: 0;
          left: auto;
          top: 0.5rem;
        }
        .custom-switch.custom-switch-right
          .custom-control-input
          ~ .custom-control-label:after {
          right: 0.875rem;
          left: auto;
          top: calc(0.25rem + 6px);
        }
        .jp-options-trip-type {
          .jp-options-trip-type-legend {
            font-size: $font-size-locality;
            text-align: center;
          }
          .list-inline {
            display: flex;
            list-style: none;
            .list-inline-item {
              width: 100%;
              .jp-options-trip-type-form-check {
                width: 100%;
                label {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 1rem 0.875rem;
                  width: 100%;
                  font-size: $font-size-medium;
                  line-height: 1.42857;
                  font-weight: 600;
                  background-color: #f1f3f5;
                  color: #868e96;
                  cursor: pointer;
                  text-align: center;
                  border-radius: $global-border-radius;
                }
                input {
                  display: none;
                }
                input:checked + label {
                  background-color: $primary;
                  color: #fff;
                }
                .jp-options-trip-type-form-check-label {
                  &::before {
                    content: "";
                    width: 2rem;
                    height: 2rem;
                    margin-bottom: 0.5rem;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                  }
                }
                input:checked + .jp-options-trip-type-form-check-label-fastest {
                  &::before {
                    background-image: url(../site/jp-options-trip-fastest-white.svg);
                  }
                }
                .jp-options-trip-type-form-check-label-fastest {
                  &::before {
                    background-image: url(../site/jp-options-trip-fastest.svg);
                  }
                }
                input:checked
                  + .jp-options-trip-type-form-check-less-connection {
                  &::before {
                    width: 6rem;
                    background-image: url(../site/jp-options-trip-less-connection-white.svg);
                  }
                }
                .jp-options-trip-type-form-check-less-connection {
                  &::before {
                    width: 6rem;
                    background-image: url(../site/jp-options-trip-less-connection.svg);
                  }
                }
              }
            }
          }
        }
        .card-jp-options {
          border-radius: $global-border-radius;
          filter: $global-shadow;
          border: none;
          margin: 1rem 0;
          .card-body ~ .card-header {
            border-top-left-radius: $global-border-radius;
            border-top-right-radius: $global-border-radius;
          }
          .card-header {
            border-radius: $global-border-radius;
            background-color: #fff;
            border: none;
            font-size: $font-size-large;
            font-weight: bold;
          }
          .card-body {
            .form-group {
              margin-bottom: 1rem;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .form-group-slider {
              padding: 3rem 1rem 0.75rem;
              border-radius: $global-border-radius;
              background-color: #fff;
              box-shadow: 0 0 0.4375rem #0003;
              .slider-container {
                align-items: center;
                .slider-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex: 0 0 auto;
                  width: 2.813rem;
                  height: 2.813rem;
                  padding: 0.8rem;
                  box-shadow: 0 0 0.4375rem #0003;
                  border-radius: 100rem;
                  &.slider-btn-less {
                    margin-right: 1rem;
                    .slider-btn-less-icon {
                      display: block;
                      height: 0;
                      width: 100%;
                      border: 0.063rem solid #39322e;
                    }
                  }
                  &.slider-btn-more {
                    margin-left: 1rem;
                  }
                }
                .slider-input {
                  position: relative;
                  .slider-tooltip {
                    position: absolute;
                    top: -100%;
                    padding: 0.1875rem 0.3125rem;
                    font-size: $font-size-medium;
                    white-space: nowrap;
                    border-radius: 0.3rem;
                    background-color: #fff;
                    box-shadow: 0 0 0.4375rem #0003;
                    transform: translate(-50%, -130%);
                  }
                  .slider-custom-bar {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 0.625rem;
                    border-radius: 10rem;
                    background-color: #6a6a6a;
                    .slider-custom-bar-selection {
                      height: 0.625rem;
                      border-radius: 10rem;
                      background-color: $primary;
                    }
                  }
                  .form-control-range {
                    -webkit-appearance: none;
                    position: relative;
                    z-index: 1;
                    height: 0.625rem;
                    border: 1px solid transparent;
                    background-color: transparent;
                    display: block;
                    width: 100%;
                    &::-webkit-slider-thumb {
                      -webkit-appearance: none;
                      height: 25px;
                      width: 25px;
                      background-color: #fff;
                      border: solid 2px #6a6a6a;
                      border-radius: 100rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .modal-footer {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        border-top: 1px solid #b9b9b9;
        .btn {
          min-width: 46%;
          max-width: 46%;
        }
      }
    }
  }
}
