@import "../../variables.scss";

/* ALERT CHANGE HOUR */
#time {
  filter: $global-shadow;
  border-radius: $global-border-radius;
}
.member-recpetion-hour-modal-choice-text {
  width: 5em;
}
.member-recpetion-hour-modal-btn {
  width: 100%;
  background-color: $primary !important;
  border: none !important;
}

/* ALERT CHANGE DAY */
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary !important;
  border: none !important;
}

.member-recpetion-day-modal-btn {
  width: 100%;
  background-color: $primary !important;
  border: none !important;
}

/* MODAL CHANGE PASSWORD */
#pwdShowConfirm,
#pwdShow {
  margin-right: 1em;
  padding: 0.6em;
}

.member-forgotten-password-modal-back-btn {
  height: $size-round-button;
  width: $size-round-button;
  transition: all 150ms ease 0s;
  background-color: #fff !important;
  filter: $global-shadow;
  border: none !important;
  color: black !important;
  border-radius: 1em !important;
}
.member-change-password-modal-btn {
  width: 100%;
  background-color: $primary !important;
  border: none !important;
}
.member-change-password-modal-btn:hover {
  background-color: $primary !important;
}
#oldPassword,
#newPassword,
#newConfirmPassword {
  border-radius: $global-border-radius;
  padding: 1.5em 1em;
}

.input-group-append {
  position: absolute;
  z-index: 10;
  top: 4px;
  right: 0.5rem;
}

/*MODAL FAVORITE */
.favourite-dropdown-menu-item {
  gap: 5px;
  font-family: $font;
}

/*MODAL ADD PLACE*/

.form-control-btn-delete {
  position: absolute;
  right: 0;
  z-index: 3;
}

.member-add-favourite-place-input-group {
  align-items: center !important;
}

.autocompletion-no-result {
  padding: 1em;
}

.autocompletion-item-subitem-infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background-color: #f1f3f5;
  padding: 0.5rem;
  margin: 0.5rem 1rem;
}
.autocompletion-item-subitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  border-radius: 10px;
  background-color: #f1f3f5;
  margin-bottom: 0.1em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  padding-left: 20px;
}
.autocompletion-item-title {
  margin-top: 1em;
  font-size: $font-size-title;
  line-height: 1.5rem;
  font-weight: $font-weight-bold;
  padding: 0.2rem 1.3rem;
}
#keywordAddFavouritePlace {
  border-radius: 1rem !important;
}
.modal-content {
  border-radius: $global-border-radius !important;
  font-family: $font;
}

input[type="text"] {
  border-radius: 1rem;
  padding: 1.5em 1em;
}
.modal-footer {
  flex-direction: column;
}
#memberAddBtn {
  cursor: pointer;
  width: 100%;
  background-color: $primary;
  color: white;
  border: none !important;
}
#memberAddBtn:disabled {
  background-color: grey;
}
#memberAddBtn:hover {
  background-color: $primary;
}
#memberAddBtn2 {
  width: 100%;
}

/* MODAL FORGOT PASSWORD*/
.modal-header {
  border-bottom: none !important;
}
.modal-title,
.member-change-password-modal-title,
.member-forgotten-password-modal-title {
  font-size: $font-size-title;
  font-weight: bold;
}
.alert {
  border-radius: $global-border-radius !important;
}

input[type="email"] {
  border-radius: 1rem;
  padding: 1.5em 1em;
}

.alert-text {
  margin: 0;
}
.modal-content {
  font-family: $font;
  margin-bottom: 1em;
}

.ctw-login .text-center.member-change-password-title {
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center !important;
  margin-bottom: 1em;
}
.member-change-password-btn {
  width: 100%;
  background-color: $primary !important;
  border: none !important;
  border-radius: 20px !important;
  padding: 1em !important;
  font-weight: bold !important;
}
