@import "../../variables.scss";

.journey-planner-widget {
  #time,
  #date {
    padding: 0;
    margin: 0em 0.5em;
  }

  #time {
    filter: none !important;
    border-radius: none !important;
  }
  .card:not(.member-profil-edit-card) .form-group:first-child {
    margin-left: 1em;
  }
  .btn-secondary {
    background-color: $primary;
    border: none;
  }
  .card:not(.member-profil-edit-card) .form-group:last-child {
    border-right: 1px solid $primary;
  }
  .autocompletion-item-subitem-btn {
    font-family: $font;
    padding: 0.75rem 1.25rem;
    width: 100%;
    filter: none;
    font-size: $font-size-medium;
    line-height: 1.25rem;
    font-weight: normal;
    border-radius: 0.5rem;
    background-color: #f8f9fa;
  }

  .autocompletion-item-title {
    font-family: $font;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 700;
    padding: 0 1rem;
  }

  h2 {
    font-family: $font;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 700;
    padding: 0 1rem;
  }

  /**********************************************************************************
  ******************************** Planner Search Bar *******************************
  **********************************************************************************/

  .jp-widget {
    position: relative;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    border-radius: $global-border-radius;
    width: 33em;
    .btn-primary {
      position: absolute;
      z-index: -1;
      bottom: -50px;
      left: 50%;
      transform: translate(-50%);
      color: white;
      background-color: $primary !important;
      border-color: $primary !important;
      font-weight: 700;
      border-bottom-left-radius: $global-border-radius;
      border-bottom-right-radius: $global-border-radius;
      padding: 1rem 3rem;
    }
    .form-group {
      width: 100%;
      margin-bottom: 3px;
      margin-top: 3px;
      .jp-widget-control {
        padding: 2.5rem 1.5rem;
        .autocompletion-form-in-panel {
          border: #6a6a6a;
        }
      }
      .jp-time-search {
        border: none;
        select,
        input {
          cursor: pointer;
        }
        .input-date-time {
          justify-content: center;
          .input-date-time-text {
            margin: 0 1rem;
          }
          .input-date {
            .form-control {
              width: 9rem;
              padding: 0.375rem 0rem;
            }
          }
          .input-time {
            .form-control {
              margin: 0 !important;
            }
          }
        }
        // partie le et la date
        @supports (-webkit-appearance: none) {
          .jp-time-search-form-control, .input-date-time .input-date .form-control {
            appearance: none !important; /* Remove default styling */
            background-color: white;
            background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"><path d="M1 1l4 4 4-4" stroke="%23777" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 12px;
            padding-right: 30px; /* Ensure padding for arrow */
          }
          .input-date-time .input-time .form-control {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 5.5rem;
          }
        }
      }
    }
    .form-control {
      text-align: center;
      border: none;
      font-family: $font;
      background-color: transparent;
      &:focus {
        box-shadow: none;
      }
      &:focus-visible {
        outline: 0.125rem solid #646464;
        outline-offset: -2px;
        border-radius: 1rem !important;
      }
    }
    #jp-time-search {
      margin-left: 25px;
      width: 13rem;
      margin: auto;
    }
  }
  .autocompletion-panel {
    margin-top: 0;
    max-height: 50vh;
    border-radius: $global-border-radius;
    border: none;
    position: absolute;
    z-index: 1100;
    width: 100%;
    background-color: #fff;
    overflow: auto;
    margin-top: 10px;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    ul:last-child:not(:only-of-type) {
      margin-bottom: 1rem;
    }
    .autocompletion-item-title {
      font-size: 1.125rem;
      margin: 0.5rem 1.5rem;
    }
    .autocompletion-panel-position,
    .member-home-work-item {
      width: 100%;
      margin-bottom: 0.1rem;
      padding: 1rem;
      text-align: left;
      border-radius: 0;
      background-color: #f9f9f9;
      display: flex;
      align-items: center;
      font-size: $font-size-medium;
      cursor: pointer;
      .optyweb {
        margin-right: 1rem;
        font-size: 1.25rem;
      }
      &:hover {
        background-color: #f4f4f4;
      }
    }
    .autocompletion-items {
      .autocompletion-item-subitem {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: $font-size-medium;
        border-radius: 0;
        cursor: pointer;
        background-color: #fff;
        padding: 0.5rem 1.5rem;
        margin: 0;
        &:not(:last-child) {
          border-bottom: 0.0625rem solid #f9f9f9;
        }
        .autocompletion-item-subitem-icon {
          color: #fff;
          padding: 0.5rem;
          font-size: 1.25rem;
          border-radius: 100rem;
          background-color: $secondary;
        }
        .autocompletion-item-subitem-infos {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 0 1rem;
          padding: 0;
          background-color: #fff;
          .autocompletion-item-subitem-infos-additional {
            font-size: 0.75rem;
            color: #6a6a6a;
          }
        }
      }
    }
  }

  /**********************************************************************************
  **********************************************************************************/
}

.journey-planner-widget-mobile {
  font-family: $font;
  margin-bottom: 1em;
  padding: 0em 1em;
  .autocompletion-panel {
    margin-top: 0;
    max-height: 50vh;
    border-radius: $global-border-radius;
    border: none;
    position: absolute;
    z-index: 1100;
    width: 100%;
    background-color: #fff;
    overflow: auto;
    margin-top: 10px;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    ul:last-child:not(:only-of-type) {
      margin-bottom: 1rem;
    }
    .autocompletion-item-title {
      font-size: 1.125rem;
      margin: 0.5rem 1.5rem;
    }
    .autocompletion-panel-position,
    .member-home-work-item {
      width: 100%;
      margin-bottom: 0.1rem;
      padding: 1rem;
      text-align: left;
      border-radius: 0;
      background-color: #f9f9f9;
      display: flex;
      align-items: center;
      font-size: $font-size-medium;
      cursor: pointer;
      .optyweb {
        margin-right: 1rem;
        font-size: 1.25rem;
      }
      &:hover {
        background-color: #f4f4f4;
      }
    }
    .autocompletion-items {
      .autocompletion-item-subitem {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: $font-size-medium;
        border-radius: 0;
        cursor: pointer;
        background-color: #fff;
        padding: 0.5rem 1.5rem;
        margin: 0;
        &:not(:last-child) {
          border-bottom: 0.0625rem solid #f9f9f9;
        }
        .autocompletion-item-subitem-icon {
          color: #fff;
          padding: 0.5rem;
          font-size: 1.25rem;
          border-radius: 100rem;
          background-color: $secondary;
        }
        .autocompletion-item-subitem-infos {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 0 1rem;
          padding: 0;
          background-color: #fff;
          .autocompletion-item-subitem-infos-additional {
            font-size: 0.75rem;
            color: #6a6a6a;
          }
        }
      }
    }
  }

  .mobile-panel-search {
    margin-top: 5em;
  }
  .btn-primary {
    width: 100%;
    font-weight: 700;
    padding: 1rem 3rem;
    border-radius: $global-border-radius;
    background-color: $primary !important;
    border-color: $primary !important;
    margin-top: 3.5rem;
  }
  .form-group {
    height: 3em;
  }

  .jp-time-search {
    height: 100%;
  }
  .jp-time-search-form-control {
    height: 100%;
  }
}

@media screen and (min-width: 960px) {
  .jp-widget-inputs {
    padding: 0em;
  }
  .jp-time-search {
    padding: 0em;
  }
  .btn {
    margin: 0;
  }
  .journey-planner-widget {
    .jp-widget {
      width: 59em;
    }
  }
}
