@import "../../variables.scss";
@import "../../mixins.scss";

.breadcrumb-step {
  display: flex;
  justify-content: center;
  overflow: hidden;
  .breadcrumb-step-item {
    position: relative;
    text-align: center;
    line-height: 1.6;
    text-transform: uppercase;
    color: #868e96;
    font-weight: 600;
    font-size: $font-size-line-number;
    width: 15%;
    margin: 0 !important;
    @include media-max(768px) {
      width: 25%;
    }
    &.current {
      color: $primary;
      &::before {
        background-color: $primary;
        box-shadow: 0 0 0 4px $primary inset;
      }

      &:after {
        background-image: linear-gradient(to right, $primary, #868e96);
      }
    }
    &.previous {
      color: $primary;
      &::before {
        background-color: $primary;
        box-shadow: 0 0 0 4px $primary inset;
      }
      &:after {
        background-image: linear-gradient(to right, $primary, $primary);
      }
    }
    &::before {
      content: "";
      margin-left: auto;
      margin-right: auto;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      display: block;

      box-shadow: 0 0 0 4px #868e96 inset;
      background-color: transparent;

      margin-bottom: 0.75rem;
    }
    &.breadcrumb-step-item::after {
      content: "";
      top: 0.655rem;
      height: 2px;
      width: calc(100% - 32px);
      left: calc(50% + 16px);
      position: absolute;
      background-color: #868e96;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
