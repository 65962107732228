@import "../../variables.scss";
@import "../../mixins.scss";

.tod-login-title,
.tod-home-title {
  margin-top: 1.5rem;
  margin-bottom: 2.1875rem;
  font-size: $font-size-title;
  font-weight: bold;
}
.container.tod-login {
  margin-bottom: 5rem;
  .features-items {
    margin-top: 4rem;
    padding-left: 0.5rem;
    .features-item {
      margin: 20px;
      font-size: 1rem;
      .features-item-icon {
        width: 3.5rem;
        height: 3.5rem;
        min-width: 3.5rem;
        min-height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100% !important;
        background-color: $primary;
        color: #fff;
        margin-right: 1rem;
      }
    }
  }
  .tod-login-card {
    border: none;
    border-radius: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    .btn-primary {
      background-color: $primary;
      border-radius: $global-border-radius;
      padding: 1em;
      font-weight: 700;
      border: none;
    }
  }
}

.container.tod-home {
  max-width: 37.5rem;
  border-radius: $global-border-radius;
  padding: 1rem;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
  .tod-offer-items {
    display: flex;
    gap: 2rem;
    .tod-offer-item {
      width: 100%;
      .tod-offer-item-card {
        border-radius: $global-border-radius;
        &:hover {
          box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
        }
      }
      .tod-offer-item-icon {
        color: $primary;
        font-size: 25px;
        margin-bottom: 10px;
      }
    }
  }
  .nav-tabs {
    border: none;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: #f8f9fa;
    margin-bottom: 1.5rem;
    .nav-item {
      flex: 1;
      .nav-link {
        text-align: center;
        display: inline-flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-weight: 600;
        color: #000;
        border: none;
        padding: 0.75rem;
        border-radius: 0.75rem;
        font-size: 0.875rem;
        line-height: 1.71429;
        &.active {
          background-color: #b10058 !important;
          color: #fff !important;
        }
        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }
  .tod-home-new-booking {
    .nav-tabs-tod-home-content-reverse-btn {
      filter: drop-shadow(0 8px 16px rgba(33, 37, 41, 0.08));
      background-color: #fff;
      border-radius: 4em;
      padding: 0.75em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
    }
    .nav-tabs-tod-home-content-reverse-btn:hover {
      background-color: #f9f9f9;
    }
    .form-control {
      //padding: 0.375rem 3.5rem 0.375rem 0.75rem;
    }
    .form-control-btn-delete {
      top: -0.22rem;
      position: absolute;
      z-index: 10;
      right: 0;
      margin: 0.69rem;
      padding: 0;
      font-size: 1.375rem;
      border: 0;
      & span:not(.sr-only) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100rem;
        background-color: #f9f9f9;
      }
    }
    .input-date-time-text {
      margin: 0 0.5rem;
    }
    .numerical-range {
      margin-top: 1.5rem;
      .numerical-range-container {
        padding: 0.25rem;
        border-radius: 100rem;
        background-color: #ffffff;
        box-shadow: 0 0 0.4375rem 0 rgba(0, 0, 0, 0.2);
        .numerical-range-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          padding: 0;
          color: #ffffff;
          font-size: 1.625rem;
          font-weight: bold;
          line-height: 1;
          background-color: $primary;
          border-radius: 100rem;
          border: none;
          &.numerical-range-more-btn {
            background-color: $primary;
          }
        }
        .numerical-range-amount {
          min-width: 8rem;
          text-align: center;
          font-weight: bold;
        }
      }
    }
    .tod-equipment-btn {
      margin: 1.5rem 0 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      background-color: #fff;
      border-radius: 2rem;
      box-shadow: 0 0 0.4375rem #0003;
    }
    .tod-equipment-btn:hover {
      background-color: #f9f9f9;
      box-shadow: 0 0 0.4375rem #0003;
    }
    .text-center {
      .btn-primary:not(.numerical-range-btn) {
        border-radius: $global-border-radius;
        padding: 1em;
        font-weight: 700;
        border: none;
        margin: 0rem auto;
        background-color: $primary;
      }
      .btn-secondary {
        background-color: #fff;
        border-color: $primary;
        box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
        color: $primary;
        border-radius: $global-border-radius;
        padding: 0.69rem;
      }
    }
    .input-group-prepend .input-group-text {
      border: none;
      padding: 0;
      background-color: transparent;
      .item-line {
        margin-right: 1rem;
        border-radius: 0.7rem;
        color: #fff;
        border: 0.125rem solid #000000;
        position: relative;
        .item-line-mode {
          margin: 0.2rem;
          font-size: 1.4rem;
        }
        .item-line-number {
          min-width: 2rem;
          margin: 0.2rem 0.3rem 0.2rem 0;
          padding: 0.25rem 0.2rem;
          font-size: 0.9375rem;
          color: #333;
          text-align: center;
          font-weight: 700;
          line-height: 1;
          word-wrap: break-word;
          border-radius: 0.5rem;
          background-color: #fff;
        }
        .item-line-access-icon {
          position: absolute;
          right: 0;
          top: 0;
          width: 1.1rem;
          height: 1.1rem;
          font-size: 0.7rem;
          transform: translate(90%, -40%);
          margin: 0 0.5rem;
          border-radius: 100rem;
          background-color: #467ba9;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          &:before {
            color: #fff;
          }
        }
      }
    }
    .dropdown-menu.show {
      border: none;
      padding: 0;
      margin: 0;
      box-shadow: 0 0 0.3125rem #0000001a;
      border-radius: $global-border-radius;
      .dropdown-item {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0.7rem 1.5rem;
        &:first-child {
          border-top-right-radius: $global-border-radius;
          border-top-left-radius: $global-border-radius;
        }
        &:last-child {
          border-bottom-right-radius: $global-border-radius;
          border-bottom-left-radius: $global-border-radius;
        }
        &:not(:last-child) {
          border-bottom: 0.0625rem solid #f9f9f9;
        }
        &:hover {
          background-color: #f9f9f9;
        }
        &.active {
          color: black;
          background-color: #f9f9f9;
        }
        .item-line {
          margin-right: 1rem;
          border-radius: 0.7rem;
          color: #fff;
          border: 0.125rem solid #000000;
          position: relative;
          .item-line-mode {
            margin: 0.2rem;
            font-size: 1.4rem;
          }
          .item-line-number {
            min-width: 2rem;
            margin: 0.2rem 0.3rem 0.2rem 0;
            padding: 0.25rem 0.2rem;
            font-size: 0.9375rem;
            color: #333;
            text-align: center;
            font-weight: 700;
            line-height: 1;
            word-wrap: break-word;
            border-radius: 0.5rem;
            background-color: #fff;
          }
          .item-line-access-icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 1.1rem;
            height: 1.1rem;
            font-size: 0.7rem;
            transform: translate(90%, -40%);
            margin: 0 0.5rem;
            border-radius: 100rem;
            background-color: #467ba9;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .tod-website {
    color: #fff;
    background-color: #444;
    border-radius: $global-border-radius;
    margin: 0 -1rem -2.5rem;
    padding: 2.5rem 1rem;
    &:before {
      content: "";
      display: block;
      height: 2rem;
      background-color: white;
      width: calc(100% + 2rem);
      margin: -2.5rem -1rem 2rem;
      border-radius: 0 0 $global-border-radius $global-border-radius;
    }
    .btn-primary.tod-website-link {
      border-radius: $global-border-radius;
      padding: 1em;
      font-weight: 700;
      border: none;
      margin: 0rem auto;
      background-color: $primary;
    }
    .tod-customer-service-link {
      color: #fff;
      margin-top: 20px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .autocompletion-panel {
    box-shadow: 0 0 0.4375rem #0003;
    border-radius: $global-border-radius;
    margin-top: 1.25rem;
    overflow: auto;
    max-height: 40vh;
    h2 {
      font-size: $font-size-large;
      margin: 0.5rem 1.5rem;
      padding: 0;
    }
    .autocompletion-item-subitem {
      padding: 0.5rem 1.5rem;
      margin: 0;
      background-color: #fff;
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 0.0625rem solid #f9f9f9;
      }
      &:hover {
        background-color: #f9f9f9;
      }
      .optyweb {
        color: #fff;
        border-radius: 100rem;
        padding: 0.5rem;
        background-color: $secondary;
        font-size: $font-size-large-icon;
      }
      .autocompletion-item-subitem-infos {
        background-color: transparent;
        margin: 0 0 0 1rem;
        padding: 0;
        .autocompletion-item-subitem-infos-additional {
          font-size: $font-size-locality;
          color: #6a6a6a;
        }
      }
    }
  }
  .tod-home-my-booking {
    .tod-home-my-booking-pager {
      gap: 1rem;
    }
    .tod-home-my-booking-title {
      font-size: $font-size-large;
      font-weight: bold;
    }
    .card.tod-booking-card {
      box-shadow: 0 0 7px #0000001a;
      margin-bottom: 1rem;
      border: none;
      border-radius: $global-border-radius;
      .card-header {
        margin: 0;
        padding: 0.75rem 1rem;
        border-radius: $global-border-radius $global-border-radius 0 0;
        background-color: #6a6a6a;
        color: #fff;
        font-size: $font-size-content;
        align-items: center;
        .tod-trip-status {
          .optyweb {
            margin-right: 0.5rem;
          }
        }
        .tod-booking-card-menu {
          z-index: 2;
          .tod-dropdown-actions-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            padding: 0;
            border-radius: 100px;
            background-color: #fff;
            box-shadow: 0 0 8px #0003;
            &::after {
              display: none;
            }
          }
        }
      }
      .card-body {
        .item-line {
          margin-right: 1rem;
          border-radius: 0.7rem;
          color: #fff;
          border: 0.125rem solid #000000;
          position: relative;
          .item-line-mode {
            margin: 0.2rem;
            font-size: 1.4rem;
          }
          .item-line-number {
            min-width: 2rem;
            margin: 0.2rem 0.3rem 0.2rem 0;
            padding: 0.25rem 0.2rem;
            font-size: 0.9375rem;
            color: #333;
            text-align: center;
            font-weight: 700;
            line-height: 1;
            word-wrap: break-word;
            border-radius: 0.5rem;
            background-color: #fff;
          }
          .item-line-access-icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 1.1rem;
            height: 1.1rem;
            font-size: 0.7rem;
            transform: translate(90%, -40%);
            margin: 0 0.5rem;
            border-radius: 100rem;
            background-color: #467ba9;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
              color: #fff;
            }
          }
        }
        .tod-booking-card-passenger {
          gap: 0.5rem;
          .optyweb {
            font-size: 18px;
            color: $text-color !important;
          }
        }
        .tod-road-sheet-items {
          .tod-road-sheet-item {
            &:first-child {
              .tod-road-sheet-item-icon {
                color: $primary;
                border-radius: 100px;
                background-color: $primary;
              }
              &::before {
                position: absolute;
                top: 0;
                bottom: 50%;
                left: -0.5rem;
                content: "";
                border-left: 0.5em solid #ffffff;
              }
            }
            &:last-child {
              .tod-road-sheet-item-icon {
                border-radius: 100px;
                background-color: #fff;
              }
              &::before {
                position: absolute;
                top: 50%;
                bottom: 0;
                left: -0.5rem;
                content: "";
                border-left: 0.5em solid #ffffff;
              }
            }
            .tod-road-sheet-item-icon {
              top: 2rem;
            }
            .tod-road-sheet-item-infos-label,
            .tod-road-sheet-item-infos-stop {
              font-size: $font-size-locality;
              color: $text-color;
            }
          }
        }
        .tod-road-sheet-alert {
          margin: 0;
          font-size: $font-size-medium;
          .btn.alert-link {
            font-size: $font-size-medium;
            text-align: left;
            padding: 0;
          }
        }
      }
    }
  }
}

.tod-map {
  .panel-wrapper {
    .tod-back-btn {
      margin-left: 0 !important;
    }
  }

  .tod-reservation-da {
    .card-body {
      padding: 1rem;
      .flex-grow-1 {
        align-items: center;
        display: flex;
      }
    }
    .tod-reservation-da-item,
    .tod-reservation-info-item {
      margin: 0.25rem 0.5rem 0.25rem 0;
      .cw-pin-departure {
        color: $primary;
      }
      .optyweb {
        margin-right: 0.5rem;
        font-size: 1rem;
      }
    }
  }
}

/* PARTIE ctw-tod-subscription */
ctw-tod-subscription{
  // la div qui contient toute la page
  .row{
    margin-bottom: 2rem; // espace en bas de la page pour eloigner le container du footer;
    // titre h2
    .h2{
      margin-top: 2rem;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.7;
    }
  }
  // le formulaire à remplir avec les infos personnelles pour la souscription
  .card{
    margin-bottom: 2rem;
    // bouton modifier dans l'input du Téléphone portable
    .member-profil-edit-phone-btn:hover{
        color: $primary;
    }
    // checkbox pour Fauteuil roulant
    .custom-control.custom-switch.custom-switch-right .custom-control-label {
        margin-left: 0.5rem !important; // important because already defined in thems.scss
        margin-right: 0.5rem !important; // important because already defined in thems.scss
        padding-top: 0.5rem;
    }
    .custom-switch .custom-control-label::before {
        background-color: #6a6a6a;
        left: -2.5rem;
        width: 2rem;
        pointer-events: all;
        border-radius: 100rem;
    }
    .custom-control-label::before {
        position: absolute;
        top: 0.25rem;
        display: block;
        height: 1rem;
        content: "";
        border: 0.063rem solid #646464;
        box-shadow: 0 0 0.438rem #0000001a;
    }
    .custom-switch .custom-control-label::after {
        left: -2.4rem;
        width: 1rem;
        height: 0.7rem;
        border-radius: 100rem;
        border-color: #fff;
        background-color: #fff;
        transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .custom-control-label:after {
        position: absolute;
        display: block;
        content: "";
        background: 50%/50% 50% no-repeat;
    }
  }
}

/* PARTIE SPINNERS DANS LES INPUT*/
ctw-tod-home{
  .spinner-border {
    vertical-align: -10px;
    margin-right: 40px;
    margin-left: 5px;
  }
  // spinner dans le bouton continuer dans la page de souscription
  ctw-tod-bind-account{
    .spinner-border{
      vertical-align: middle;
    }
  }
}

/* VUE TOD HOME : MODAL*/
.modal.show.tod-equipment-modal{
  @media (min-width: 576px) {
    .numerical-range-amount {
        min-width: 8rem;
    }
  }
  .numerical-range-amount {
      min-width: 3rem;
      text-align: center;
      font-weight: 700;
  }
  // bouton + et - dans le modal
  .btn-primary{
    background-color: $primary;
    border-color: $primary;
  }
}
.modal.modal-jp-options{
  .custom-day.calendar-selected {
    color: #fff;
    border-radius: 100rem;
    background-color: $primary;
  }
  .custom-day.calendar-selected:hover {
    color: #fff;
  }
  .custom-day:hover{
    color: $primary;
  }
}

/**********************************************************************************
  ************************** VUE RESERVATION TOD DETAIL ***************************
  *********************************************************************************/

.tod-map .tod-reservation,
.tod-reservation-summary-cancellation {
  .tod-reservation-date {
    margin: 0.5rem 0;
  }
  .tod-trip-status {
    margin: 0 -1rem 1rem;
    padding: 0.75rem 1rem;
    background-color: #6a6a6a;
    color: #fff;
    .optyweb {
      margin-right: 1rem;
      font-size: $font-size-large-icon;
    }
  }
  .card {
    border-radius: 0.9375rem;
    box-shadow: 0 0 7px #0000001a;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    .card-body {
      padding: 0;

      .tod-solution-item-info {
        .tod-solution-item-info-date {
          margin: 0.5rem 0;
          padding: 0.2rem 0.5rem;
          color: #fff;
          border-radius: 100rem;
          background-color: #333;
        }
      }
    }
    .card-footer {
      border-radius: 1rem !important;
      font-size: $font-size-medium;
      display: block;
      .item-location-label {
        margin-right: 0.5rem;
        color: #6a6a6a;
      }
    }
    &.tod-num-traveler {
      .tod-num-traveler-number {
        font-weight: bold;
      }
      .tod-num-traveler-validation {
        gap: 0.5rem;
        color: $text-color !important;
        .optyweb {
          font-size: 18px;
          color: $text-color !important;
        }
      }
    }
    &.tod-detail-card {
      .card-header {
        padding: 0;
        gap: 1rem;
        .tod-detail-card-duration {
          font-size: $font-size-content;
          font-weight: bold;
          .tod-detail-card-duration-approx {
            font-size: $font-size-locality;
            font-weight: normal;
          }
        }
      }
      .card-body {
        .tod-road-sheet-items {
          .tod-road-sheet-item {
            &:first-child {
              .tod-road-sheet-item-icon {
                color: $primary;
                border-radius: 100px;
                background-color: $primary;
              }
              &::before {
                position: absolute;
                top: 0;
                bottom: 50%;
                left: -0.5rem;
                content: "";
                border-left: 0.5em solid #ffffff;
              }
            }
            &:last-child {
              .tod-road-sheet-item-icon {
                border-radius: 100px;
                background-color: #fff;
              }
              &::before {
                position: absolute;
                top: 50%;
                bottom: 0;
                left: -0.5rem;
                content: "";
                border-left: 0.5em solid #ffffff;
              }
            }
            .tod-road-sheet-item-icon {
              top: 2rem;
            }
            .tod-road-sheet-item-infos-label,
            .tod-road-sheet-item-infos-stop {
              font-size: $font-size-locality;
              color: $text-color;
            }
          }
        }
        .tod-road-sheet-alert,
        .tod-last-update-alert {
          margin: 0;
          font-size: $font-size-medium;
          margin-bottom: 1rem;
          .btn.alert-link {
            font-size: $font-size-medium;
            text-align: left;
            padding: 0;
          }
          .optyweb:not(.cw-alert-info2) {
            font-size: $font-size-large-icon;
          }
        }
      }
    }
  }
  .tod-reservation-btn,
  .tod-reservation-summary-cancellation-actions-btn {
    padding: 0.4em 1.5rem;
    border: 3px solid $primary;
    background-color: #fff;
    color: #1c1c28;
    margin-top: 1em;
    border-radius: $global-border-radius;
    &:hover {
      color: #fff;
    }
  }
}

/**********************************************************************************
  ************************** VUE PROFIL TOD RESERVATION ***************************
  *********************************************************************************/

.member-tod-card .card.tod-booking-card {
  border-radius: 0.9375rem;
  box-shadow: 0 0 7px #0000001a;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 0.75rem;
  margin-bottom: 1rem;
  .card-body {
    padding: 0;
  }
  .card-header {
    padding: 0rem 0 0.75rem 0 !important;
    gap: 1rem;
    .tod-trip-status {
      .optyweb {
        margin-right: 0.75rem;
      }
    }
  }
  .card-body {
    & > .d-flex.align-items-center {
      flex-direction: column;
      align-items: flex-start !important;
      position: relative;
      .tod-booking-card-date {
        font-weight: bold;
      }
      .item-line {
        margin-top: 0.5rem;
      }
      .tod-booking-card-passenger {
        position: absolute;
        right: 0;
        top: 0;
        gap: 0.5rem;
        .optyweb {
          font-size: 18px;
          color: $text-color !important;
        }
      }
    }
    .tod-road-sheet-items {
      .tod-road-sheet-item {
        &:first-child {
          .tod-road-sheet-item-icon {
            color: $primary;
            border-radius: 100px;
            background-color: $primary;
          }
          &::before {
            position: absolute;
            top: 0;
            bottom: 50%;
            left: -0.5rem;
            content: "";
            border-left: 0.5em solid #ffffff;
          }
        }
        &:last-child {
          .tod-road-sheet-item-icon {
            border-radius: 100px;
            background-color: #fff;
          }
          &::before {
            position: absolute;
            top: 50%;
            bottom: 0;
            left: -0.5rem;
            content: "";
            border-left: 0.5em solid #ffffff;
          }
        }
        .tod-road-sheet-item-icon {
          top: 2rem;
        }
        .tod-road-sheet-item-infos-label,
        .tod-road-sheet-item-infos-stop {
          font-size: $font-size-locality;
          color: $text-color;
        }
      }
    }
    .tod-road-sheet-alert {
      margin: 0;
      font-size: $font-size-medium;
      .btn.alert-link {
        font-size: $font-size-medium;
        text-align: left;
        padding: 0;
      }
    }
  }
}
.tod-dropdown-actions-menu {
  .tod-dropdown-actions-item {
    padding: 0.5rem 0.75rem;
    &:active {
      background-color: $primary;
    }
  }
}

.tod-reservation-btn,
.tod-reservation-summary-cancellation-actions-btn {
  padding: 0.4em 1.5rem;
  border: 3px solid $primary;
  background-color: #fff;
  color: #1c1c28;
  margin-top: 1em;
  border-radius: $global-border-radius;
  &:hover {
    color: #fff;
  }
}

/**********************************************************************************
  ************************** Partie Solution de trajet ***************************
  *********************************************************************************/
ctw-tod{
  .panel-content{
    margin-bottom: 10rem;
  }
}

// Partie spécifique à Datepicker (le modal) dans la page Tod home
ctw-tod-home{
  ctw-datepicker{
    .datepicker{
      .datepicker-table-wrap{
        .datepicker-table{
          tbody{
            td:hover{
              color: $primary;
            }
            td[aria-selected="true"] {
              span{
                color: #fff;
                border-radius: 100rem;
                background-color: #b10058;
                font-weight: $font-weight-bold;
              }
            }
            td[aria-selected="true"]:hover {
              span{
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}