@import "../../variables.scss";

ctw-member{
  /**
  * Overrides for `ctw-member-home`
  * ---------------------------------------
  */
  ctw-member-home {
    // pour tous les cards dans mon-compte
    // Do a box shadow on the $primary #b10058 color when hovering the card
    .card:hover {
      box-shadow: 0px 4px 20px rgba(177, 0, 88, 0.1);
    }

    ctw-member-link-card {
      .member-card-link-consumption {
        .member-card-link-icon {
          // We Make sure the icon container is capable of accepting margin
          display: inline-flex;
          align-items: center;
          justify-content: center;
  
          // Target the icon itself with increased specificity
          & > span.optyweb.cw-account-service {
            background-color: $primary; // Pink background color for the icon container
            color: #ffffff; // White color for the icon
            font-size: 2rem;
            padding: .5em;
            border-radius: 50%;
            margin-right: 2rem; // Adds space on the right
          }

          // Target the icon for "Mes reçus de paiement" in "Mes inforamtions de paiements"
          & > span.optyweb.cw-sale-price-euro {
            margin-right: 1.5rem;
          }

          // Target the icon "SEPA" in "Mes informations de paiements"
          & > span.optyweb.cw-misc-sepa {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #b10058;
            color: #ffffff;
            width: 4rem;
            height: 4rem;
            border-radius: 100%;
            margin-right: 1.5rem;
          }
          // Target the icon "before" inside the icon "SEPA" in "Mes informations de paiements"
          & > span.optyweb.cw-misc-sepa::before {
            font-size: 2em;
          }
        }
      }
    }
    // Partie Mon profil - mticket
    ctw-member-profile-card{
      .member-ticket-card{
        .card-body{
          // Les items en flex
          & > .align-items-center{
            justify-content: space-between;
          }
          // icon en premier à gauche
          .card-link-icon{
            .cw-misc-smartphone.mode-rounded{
              color: white;
              background-color: $primary;
            }
          }
          // text 
          .card-link-content{
            h3{
              font-size: $font-size-content;
              font-weight: $font-weight-bold;
              // petit text
              .badge-custom {
                color: #fff;
                background-color: #0b4f6c;
              }
            }
          }
          // The tooltip
          ctw-tooltip {
            margin-left: auto;
          }
          // button
          .btn{
            span.optyweb.cw-alert-help.mode-rounded.mode-rounded-small {
              font-size: 0.875rem !important;
            }
            span.optyweb.cw-alert-help.mode-rounded.mode-rounded-small:hover {
              background-color: #525252;
              color: white;
              border: #525252;
            }
          }
          // the alert
          ctw-alert{
            // icon a gauche x  (cas alert success)
            .alert-success .alert-icon {
              color: #fff;
              background-color: #398725;
            }
            // icon a gauche x (cas alert danger) 
            .alert-danger .alert-icon {
              color: #fff;
              background-color: #b10058;
            }
            // icon a gauche x (cas alert warning)
            .alert-warning .alert-icon {
              color: #fff;
              background-color: #eeb22d;
            }
            // icon a gauche x (cas pour toues les alertes)
            .alert{
              align-items: center;
              .alert-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: center;
                flex-shrink: 0;
                width: 1.625rem;
                height: 1.625rem;
                margin-right: 1rem;
                font-size: .9rem;
                border-radius: 100rem;
              }
              // text 
              .alert-content{
                .alert-text{
                  font-size: $font-size-locality;
                  color: black;
                }
              }
            }
          }
        }
      }
    }
    // Partie mes reservations, rubrique taxi
    ctw-member-taxi-card{
      // Partie mes réservations, détails de réservation
      ctw-taxi-reservation-card {
        .taxi-booking-card{
            margin-bottom: 1rem;
            
            .card-header{
                // style pour la partie qui englobe l'icon et le text dans le header
                .align-items-center.d-flex.tod-trip-status.tod-trip-status-blue{
                  margin-top: 10px;
                }
                // style pour l'icon dans le header
                .tod-trip-status-icon{
                    margin-right: 1rem;
                }

                // style pour le text dans le header
                .tod-trip-status-text{
                  font-size: $font-size-content;
                  font-weight: $font-weight-bold;
                }

                // permet de visualiser le dropdow du boutton pour annuler la réservation
                .taxi-booking-card-menu {
                    z-index: 2;
                }
            }

            .card-body{
                .align-items-center{
                  padding-left: 1rem;
                  padding-right: 1rem;
                }
                .taxi-booking-card-date {
                    padding: 0.2rem 0.5rem !important;
                    color: #fff;
                    border-radius: 393rem;
                    background-color: $primary;
                }
                .taxi-booking-card-date:hover {
                    color: #fff;
                }
                .taxi-booking-card-fare {
                    margin-top: 1rem;
                    .taxi-booking-card-fare-title {
                        font-size: .9rem;
                    }
                    .item-price{
                        padding: 0.2rem 0.5rem;
                        font-size: .875rem;
                        color: #fff;
                        font-weight: 400;
                        white-space: nowrap;
                        border-radius: 100rem;
                        background-color: #0b4f6c;
                    }
                }
                .tod-road-sheet-items{
                    margin-top: 1.5rem;
                }
                // icon de départ taxi
                .tod-road-sheet-item-icon.tod-road-sheet-item-icon-rounded {
                    left: -1.05rem;
                    top: -1.2rem;
                }
                // sous icon de départ taxi
                .taxi-road-sheet-item-icon-departure {
                    border-radius: 100rem;
                    background-color: white;
                    border-style: solid;
                    border-color: #b10058;
                    border-width: 2px;
                    padding: 0.1rem;
                    width: unset;
                    height: unset;
                }
                .tod-road-sheet-item-taxi-infos {
                    transform: translateY(-30%);
                    margin: 0 1rem 1rem;
                    .tod-road-sheet-item-taxi-infos-time {
                        font-weight: 700;
                        .cw-real-time {
                            color: #e79200;
                            font-size: .9rem;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }
    }
    // Partie Fidélité
    .member-card-link-loyalty{
      background-color: #ffcb49;
      margin-bottom: 2em;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      padding: 1em;
      // apply this style only the the .member-card-link-icon class that has a direct child with a class .member-card-link-loyalty-point
      // compte souscrit au programme de fidélité
      .member-card-link-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #424242;
        color: #FFFFFF;
        min-width: 4rem;
        min-height: 4rem;
        width: 4rem;
        height: 4rem;
        border-radius: 100%;
        margin-right: 1.5rem;
        flex-direction: column;
        & > .member-card-link-loyalty-point{
          font-weight: $font-weight-bold;
        }
      }
      // Compte non souscrit au programme de fidélité
      .member-card-link-icon{
        background-color: transparent;
        color: transparent;
        height: unset;
        & > ctw-image{
          & > .member-card-link-loyalty-img{
            min-width: 4.5rem;
            min-height: 4.5rem;
            width: 4.5rem;
            height: 4.5rem;
          }
        }
      }
      .member-card-link-content{
        .stretched-link.member-card-link-link{
          font-weight : bold;
        }
        .member-card-link-text {
          font-weight: bold;
          font-size: 12px;
        }
      }
      .member-card-link-external {
        display: flex;
        align-items: center;
        margin-left: 5px;
        color: #424242;
        .optyweb.cw-toolbar-new-window {
          font-size: $font-size-x-large-icon;
        }
      }
    }
    // Toutes les sous cartes dans la cards prncipaux de la page mon compte.
    //càd la liste des cards qui s'ouvrent en cliquant sur chaque card principale
    .member-home-card-item {
      margin-top: 1rem;
    }

    // Partie "mes arrets"
    .member-stop-card-item{
      .card-physical-stop-info {
        display: block;
        .item-location{
          .item-location-content {
            padding-left: 0.2rem;
          }
        }
      }
      .card-physical-stop{
        .card-physical-stop-info{
          margin-left: 1rem;
        }
      }
    }

    // Partie Mes lignes
    .member-line-card-item{
      position: relative;
      z-index: 10;
      // Menu
      .card-line-menu{
        .dropdown-menu.dropdown-menu-right{
          left: 0;
          transform: translate(2em, -3.5em) !important;
        }
      }
    }

    // Partie Mes lieux
    .member-place-card-items{
      .card-body{
        align-items: center;
        justify-content: center;
        margin: 10px;
        .place-card-icon {
          color: $primary;
          margin-right: 1rem;
        }
        // catégorie (premier element dans la sous card)
        .place-card-category {
          font-weight: $font-weight-bold;
          font-size: $font-size-medium;
        }
      }
    }
  }
  // Page de Login et signup
  ctw-member-login-signin{
      // la partie login
      ctw-member-signin{
        // formulaire entier
        .member-signin{
          // partie se connecter
          .card-header{
            margin-top: 10%
          }
          // bouton sinscrire
          .member-signin-btn{
            background-color: $primary;
            border-color: $primary;
          }
        }
      }
  }

  // ctw-member-connect 
  ctw-member-connect{
    .d-flex.justify-content-center.flex-column.align-items-center {
      margin-top: 50px;
      margin-bottom: 50px;
      color: $primary;
      span{
        color: black;
      }
    }
  }

  // Ctw-member context editProfile
  ctw-member-profil-edit{
    input#phoneNumber {
      padding-left: 4rem;
    }
  }

  // Partie commune à tous les logos rond dans mon compte dans le composant ctw-member
  .member-card-link-icon .optyweb{
    margin-right: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    color: #fff;
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
    background-color: $primary;
  }
  .member-card-link-icon .optyweb::before{
    font-size: 2rem;
    color: white;
  }

  .card-physical-stop-network{
    font-size: $font-size-medium;
    font-weight: normal;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
}

/** Partie login avec FranceConnect commune dans : 
  - Ctw-memebr context editProfile
  - ctw-member page de login et signup principale
**/
ctw-member-franceconnect-login{
  // Le lien "Quest ce que FranceConnect"
  .france-connect-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    text-decoration: none;
    color: $primary;
    .france-connect-link-text {
      text-decoration: underline;
    }
    .france-connect-link-icon {
      padding-left: 5px;
      font-size: 1.2rem;
    }
  }
  // le "ou" entre utiliser franceConnect et se connecter normalement
  .france-connect-or {
    font-weight: bold;
    font-size: larger;
    margin-bottom: 2%;
  }
}

/** Partie commune aux : 
  - ctw-member context editProfile partie modal : Modifier le mot de passe
  - Page de Login et signup
**/

// ctw-member context editProfile partie modal : Modifier le mot de passe
.btn.show-password:hover{
  color: $primary;
}
// Permet de fonctionner le click sur le span optyweb pour afficher ou masquer le mot de passe
.show-password span {
    pointer-events: none;
}