@import "../../variables.scss";

// permet au modal de s'afficher bien au dessus de tout
.modal-backdrop {
  z-index: 2 !important;
}

.modal .modal-dialog {
  display: flex;
  min-height: 100%;
  margin: auto;
  padding: 0.5rem;
  .modal-content {
    margin-top: auto;
    margin-bottom: auto;
    .modal-header {
      align-items: center;
      padding: 1.5625rem 1.25rem;
      height: 0;
      margin-bottom: -30px;
    }
    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem;
      .modal-title {
        margin: 0;
        color: #333333;
        font-weight: bold;
        font-size: $font-size-content;
      }
      .modal-header-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7rem;
        height: 7rem;
        margin: -5rem auto 1rem;
        font-size: 3rem;
        color: #ffffff;
        border-radius: 100rem;
        background-color: $primary !important;
      }
      .basket-summary-remove-item-modal-title {
        span:last-child {
          font-size: $font-size-content;
          font-weight: bold;
        }
      }
      .add-title-confirm-modal-title {
        font-size: $font-size-content;
        font-weight: bold;
      }
      .es-card-card-unlink-modal-title,
      .es-card-card-lost-modal-title {
        font-weight: bold;
        font-size: $font-size-content;
        .es-card-card-unlink-modal-icon,
        .es-card-card-lost-modal-icon {
          background-color: $primary !important;
        }
      }
    }
    .modal-footer {
      flex-direction: column;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      padding: 0.75rem;
      border-top: 0 solid #dee2e6;
      border-bottom-right-radius: 1.875rem;
      border-bottom-left-radius: 1.875rem;
      .btn {
        border: 0.125rem solid transparent !important;
        padding: 0.69rem 0.69rem;
        max-width: 20.3125rem;
        min-width: 60%;
        font-weight: bold;
        line-height: 1.5;
        border-radius: 2rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
      .btn-primary {
        color: #ffffff;
        background-color: $primary;
        border-color: $primary;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
          0 1px 1px rgba(0, 0, 0, 0.075);
      }
      .btn-secondary {
        background-color: #ffffff !important;
        border-color: $primary !important;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
          0 1px 1px rgba(0, 0, 0, 0.075);
        color: $primary;
      }
    }
  }
}
.ngb-toasts {
  left: 50%;
  right: inherit !important;
  transform: translateX(-50%);
  .toast.show {
    .bg-danger {
      background-color: #feeae4 !important;
      .optyweb {
        color: #ffffff;
        background-color: $red;
      }
    }
    .optyweb {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 1.625rem;
      height: 1.625rem;
      margin-right: 1rem;
      font-size: 0.9rem;
      color: #ffffff;
      background-color: $red;
      border-radius: 100rem;
    }
    .toast-body {
      padding: 1rem 0.9375rem;
      color: #333333;
      border: 0;
      background-color: transparent;
    }
    .close {
      padding-left: 1rem;
    }
  }
}
/**
  ----------------------------------------------------------------
  ------------------  road-sheet-fare-modal  ---------------------
  ----------------------------------------------------------------
  **/

.road-sheet-fare-modal {
  .modal-content {
    .modal-header {
      height: inherit !important;
      margin-bottom: 0;
    }
    .modal-body {
      .card {
        border-radius: $global-border-radius;
        box-shadow: 0 0 7px #0000001a;
        font-size: $font-size-medium;
        border: none;
        h2.card-header.d-flex {
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 1.7;
        }
        .card-header {
          padding: 0.75rem 1rem;
          margin-bottom: 0;
          background-color: #fff;
          border-bottom: none;
          font-size: $font-size-medium;
          .item-price {
            padding: 0.2rem 0.5rem;
            font-size: 0.875rem;
            color: #fff;
            font-weight: 400;
            white-space: nowrap;
            border-radius: 100rem;
            background-color: #312783;
          }
          &:first-child {
            border-radius: $global-border-radius $global-border-radius 0 0;
          }
          .item-location-label {
            margin-right: 0.5rem;
            color: #6a6a6a;
          }
        }
        .card-body {
          .item-price-num {
            padding: 0.2rem 0.5rem;
            font-size: 0.875rem;
            color: #fff;
            font-weight: 400;
            white-space: nowrap;
            border-radius: 100rem;
            background-color: #312783;
          }
          .flex-grow-1 {
            font-size: 16px;
          }
        }
      }
    }
  }
}

/**
  ----------------------------------------------------------------
  ------------ Partie Couches Cartographiques sur la map--------------------
**/
ctw-modal{
  .modal-content{
    .modal-header{
      .modal-title{
        font-size: $font-size-large;
      }
    }
    .modal-body{
      .map-layers-stroke-items {
        margin-top: 2rem;
        .map-layers-stroke-item {
          position: relative;
          font-size: .875rem;
          border-radius: 0.5rem;
          .map-layers-stroke-item-btn{
            .map-layers-stroke-item-btn-img {
              display: block;
              width: 4.5rem;
              height: 4.5rem;
              margin: 0 auto 0.5rem;
              border: 0.125rem solid #ffffff;
              border-radius: 0.25rem;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
            }
            .map-layers-stroke-item-btn-img-road{
              background-image: url(../site/layer-road-traffic.png);
            }
            .map-layers-stroke-item-btn-img-path{
              background-image: url(../site/layer-bike-path.png);
            }
          }
          .map-layers-stroke-item-btn:not(:disabled):not(.disabled).active .map-layers-stroke-item-btn-img {
            border: 2px solid $primary;
          }
        }
      }
    }
  }
}

/**
  ----------------------------------------------------------------
  ------------ Partie spécifique pour le modal en général--------------------
**/
ctw-modal{
  .modal .modal-content .modal-header .close{
    padding: unset;
    margin: unset;
    z-index: 1;
    position: relative;
  }
}

/**
  ----------------------------------------------------------------
  ------------ Partie spécifique mon panier--------------------
**/
ctw-modal{
  .modal-content .modal-body h2{
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
  }
}

/**
  ----------------------------------------------------------------
  ------------ Partie spécifique date picker, ex : TAD et consommation dans profil--------------------
**/
ctw-datepicker{
  .datepicker{
    text-align: center;
    border: 1px solid #dfdfdf;
    border-radius: .25rem;
    margin-bottom: 1rem !important;
    max-width: unset !important;
    .datepicker-header{
      border-bottom: 0;
      border-radius: .25rem .25rem 0 0;
      padding-top: .25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--light);
      //arrows previous and next
      .datepicker-header-btn{
        .cw-nav-first,
        .cw-nav-next-left,
        .cw-nav-next-right,
        .cw-nav-last{
          color: $primary;
        }
      }
      .datepicker-header-date{
        flex-grow: unset !important;
      }
    }
    .datepicker-table-wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      .datepicker-table{
        thead{
          color: $primary-contrast;
          border-bottom: 1px solid rgba(0, 0, 0, .125);
          border-radius: 0;
        }
        tr{
          height: 2rem;
        }
        tbody{
          td{
            cursor: pointer;
          }
        }
      }
    }
  }
}