$primary: #b10058;
$primary-contrast: #800040;
$secondary: #312783;
$accent: #eceff1;
$text-color: #525252;
$green: #28a745;
$red: #e1001a;
$orange: #eeb22d;

$font: Red-Hat-Display;

$font-weight-bold: 700;

$font-size-title: 1.5rem;
$font-size-content: 1rem;
$font-size-line-number: 0.75rem;
$font-size-locality: 0.75rem;
$font-size-large-icon: 1.25rem;
$font-size-x-large-icon: 1.5rem;
$font-size-big-icon: 1.75rem;
$font-size-extra-small: 0.5rem;
$font-size-small: 0.75rem;
$font-size-large: 1.25rem;
$font-size-medium: 0.875rem;

$global-border-radius: 1.25rem;

$global-shadow: drop-shadow(0 8px 16px rgba(33, 37, 41, 0.08));

$size-round-button: 2.75rem;

$background-color-alert: #a94442; /* Arrière-plan rouge foncé pour un meilleur contraste */
