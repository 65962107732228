@import "../../variables.scss";

.ctw-login .member-email-confirmation {
  max-width: 550px;
  padding: 0 20px;
  margin: auto;
  .cw-alert-success2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 3.55rem;
    height: 3.55rem;
    margin: 0 auto;
    font-size: 1.9rem;
    color: #ffffff;
    border-radius: 100rem;
    background-color: $primary;
  }
}

.ctw-confirm-mail-page,
.member-email-confirmation {
  .cw-toolbar-close1.modal-header-icon {
    display: none;
  }
  .member-email-confirmation {
    padding: 20px;
    max-width: 550px;
    margin: auto;
    .text-center {
      .member-email-confirmation-content-btn {
        background-color: $primary;
        border-color: $primary;
        color: #ffffff;
        width: auto;
        padding: 1em 3em;
      }
    }
    .text-center:last-child {
      text-align: center !important;
    }
  }
  .member-email-confirmation-title,
  h2 {
    font-size: $font-size-title;
    line-height: 1.2;
    font-weight: $font-weight-bold;
  }
  p,
  h1,
  h2 {
    text-align: left;
  }
  .member-email-confirmation-btn {
    background-color: $primary;
    border-color: $primary;
    color: #ffffff;
    width: auto;
    padding: 1em 3em;
    & + p {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 5px;
      & + button {
        color: $primary;
        text-decoration: underline;
        font-weight: 600;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .cw-alert-success2 {
    display: none;
  }
  .member-disruption-no-disruption .cw-alert-success2 {
    display: flex;
  }
  .alert-danger {
    align-items: center;
    gap: 15px;
    .cw-toolbar-close1 {
      color: #ffffff;
      background-color: $primary;
      padding: 4px 4px 4px 5px;
      border-radius: 100px;
      font-size: 10px;
    }
  }
  .member-email-confirmation-content {
    margin: 2rem 0 75px 0;
    h2 {
      margin-bottom: 20px;
    }
  }
}
