@import "../../variables.scss";

ctw-taxi{
    ctw-taxi-home{

        // Le loader dans l'unput de recherche
        .form-control-loader {
            display: flex;
            justify-content: center;
            align-items: center;
            .spinner-border {
                margin-right: 40px;
                margin-left: 5px;
            }
        }

        // alert message toast
		@media (min-width: 768px) {
            .ngb-toasts {
                left: 50%;
                right: inherit;
                transform: translate(-50%);
            }
        }
        .ngb-toasts {
            position: fixed;
            top: 0;
            margin: 0.5em;
            z-index: 1200;
        }

        // no reservation message alert
		.tod-no-reservation-alert {
		    margin-top: 10px;
		}

        // navtabs
		.nav-tabs {
		    margin: 1rem 0;
		}
        .nav-tabs .nav-item .nav-link.active {
            font-weight: 700;
            color: #fff;
            background-color: $primary;
        }
        .nav-tabs .nav-item .nav-link {
            color: #1c1c28a6;
            margin-bottom: 0;
        }
		.container.tod-home .tod-home-new-booking .form-control {
		    padding-right: 1.5rem;
		}

        // check box pour voyage accessible
        .tod-home .tod-home-new-booking{
            .custom-control.custom-switch.custom-switch-right .custom-control-label {
                margin-left: 0.5rem !important; // important because already defined in thems.scss
                margin-right: 0.5rem !important; // important because already defined in thems.scss
            }
            .custom-switch .custom-control-label::before {
                background-color: #6a6a6a;
                left: -2.5rem;
                width: 2rem;
                pointer-events: all;
                border-radius: 100rem;
            }
            .custom-control-label::before {
                position: absolute;
                top: 0.25rem;
                display: block;
                height: 1rem;
                content: "";
                border: 0.063rem solid #646464;
                box-shadow: 0 0 0.438rem #0000001a;
            }
            .custom-switch .custom-control-label::after {
                top: 0.376rem;
                left: -2.4rem;
                width: 1rem;
                height: 0.748rem;
                border-radius: 100rem;
                border-color: #fff;
                background-color: #fff;
                transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .custom-control-label:after {
                position: absolute;
                display: block;
                content: "";
                background: 50%/50% 50% no-repeat;
            }

            // Autocompletion
            .autocompletion-panel .autocompletion-panel-position {
                width: 100%;
                margin-bottom: 0.1rem;
                padding: 1rem;
                text-align: left;
                border-radius: 0;
                background-color: #f9f9f9;
                display: flex;
                align-items: center;
                font-size: .875rem;
                cursor: pointer;
            }
        }

        // Partie mes réservations, détails de réservation
        ctw-taxi-reservation-card {
            .taxi-booking-card{
                margin-bottom: 1rem;
                
                .card-header{
                    // style pour l'icon dans le header
                    .tod-trip-status-icon{
                        margin-right: 1rem;
                    }
    
                    // permet de visualiser le dropdow du boutton pour annuler la réservation
                    .taxi-booking-card-menu {
                        z-index: 2;
                    }
                }

                .card-body{
                    .taxi-booking-card-date {
                        padding: 0.2rem 0.5rem;
                        color: #fff;
                        border-radius: 393rem;
                        background-color: $primary;
                    }
                    .taxi-booking-card-date:hover {
                        color: #fff;
                    }
                    .taxi-booking-card-fare {
                        margin-top: 1rem;
                        .taxi-booking-card-fare-title {
                            font-size: .9rem;
                        }
                        .item-price{
                            padding: 0.2rem 0.5rem;
                            font-size: .875rem;
                            color: #fff;
                            font-weight: 400;
                            white-space: nowrap;
                            border-radius: 100rem;
                            background-color: #0b4f6c;
                        }
                    }
                    .tod-road-sheet-items{
                        margin-top: 1.5rem;
                    }
                    // icon de départ taxi
                    .tod-road-sheet-item-icon.tod-road-sheet-item-icon-rounded {
                        left: -1.05rem;
                        top: -0.3rem;
                    }
                    // sous icon de départ taxi
                    .taxi-road-sheet-item-icon-departure {
                        border-radius: 100rem;
                        background-color: white;
                        border-style: solid;
                        border-color: #b10058;
                        border-width: 2px;
                        padding: 0.1rem;
                    }
                    .tod-road-sheet-item-taxi-infos {
                        transform: translateY(-30%);
                        margin: 0 1rem 1rem;
                        .tod-road-sheet-item-taxi-infos-time {
                            font-weight: 700;
                            .cw-real-time {
                                color: #e79200;
                                font-size: .9rem;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
    }
    // Partie ctw-taxi-subscription
    ctw-taxi-subscription{
        // la div qui contient toute la page
        .container{
            margin-bottom: 50px; // espace en bas de la page pour eloigner le bouton du footer
            .btn-primary{
                background-color: $primary;
            }
            // le formulaire à remplir avec les infos personnelles pour la souscription
            .card{
                // bouton modifier dans l'input du Téléphone portable
                .member-profil-edit-phone-btn:hover{
                    color: $primary;
                }
                // checkbox pour Fauteuil roulant
                .custom-control.custom-switch.custom-switch-right .custom-control-label {
                    margin-left: 0.5rem !important; // important because already defined in thems.scss
                    margin-right: 0.5rem !important; // important because already defined in thems.scss
                }
                .custom-switch .custom-control-label::before {
                    background-color: #6a6a6a;
                    left: -2.5rem;
                    width: 2rem;
                    pointer-events: all;
                    border-radius: 100rem;
                }
                .custom-control-label::before {
                    position: absolute;
                    top: 0.25rem;
                    display: block;
                    height: 1rem;
                    content: "";
                    border: 0.063rem solid #646464;
                    box-shadow: 0 0 0.438rem #0000001a;
                }
                .custom-switch .custom-control-label::after {
                    top: 0.376rem;
                    left: -2.4rem;
                    width: 1rem;
                    height: 0.748rem;
                    border-radius: 100rem;
                    border-color: #fff;
                    background-color: #fff;
                    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                .custom-control-label:after {
                    position: absolute;
                    display: block;
                    content: "";
                    background: 50%/50% 50% no-repeat;
                }
            }
        }
    }
}