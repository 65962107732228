@import "../../variables.scss";

/*Map*/
.map,
.ctw-confirm-mail-page {
  font-family: $font;
  left: 1.5rem;
  margin-bottom: 1.5rem;
  .alert-content {
    font-weight: bold;
  }

  .title-with-icon-icon {
    margin: 0px !important;
    background: none !important;
    color: black !important;
  }
  .title-with-icon {
    flex-direction: row;
    gap: 1em;
  }

  .jp-time-search,
  .jp-time-search .d-flex {
    gap: 10px;
  }

  h2 {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 1em 0 1.2em 0;

    flex-direction: column;
  }

  /**
  ----------------------------------------------------------------
  ------------------panel-search----------------------------------
  ----------------------------------------------------------------
  **/

  .nearby-mode-item-btn {
    display: flex;
    gap: 10px;
    filter: $global-shadow;
  }

  .nearby-mode-item-btn-icon {
    position: inherit !important;
    transform: inherit !important;
  }
  .nearby-mode-item-btn-icon,
  .schedule-logical-physical-stop-mode-icon {
    padding: 0.5em !important;
    font-size: $font-size-large;
    display: flex;
  }
  .mb-0 {
    justify-content: center;
  }

  #keywordVia {
    border-radius: 1.25rem;
  }

  [inputclass="jp-search-form-control jp-search-form-control-via"] {
    margin-top: 20px;
    @media screen and (max-width: 600px) {
      margin-top: 8px;
    }
  }

  .autocompletion-form-via-duration {
    display: none;
  }

  /**
  ----------------------------------------------------------------
  ------------------  pan-panel----------------------------------
  ----------------------------------------------------------------
  **/
  .custom-switch.custom-switch-right
    .custom-control-input
    ~ .custom-control-label:after {
    right: 0.875rem;
  }
  .mode-rounded {
    border: 2px solid $primary;
    border-radius: 2em;
    margin: 1rem 0;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cw-misc-access.mode-rounded.member-profil-edit-pmr {
    margin: 0;
  }

  .custom-control-access-trip-label::before {
    right: 0;
    left: auto;
  }
  .custom-switch {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  .cw-mode-trolley {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    color: #fff;
    color: #868e96;
    background-color: $primary;
    color: #fff;
    align-self: center;
    position: relative;
  }

  .cw-mode-trolley:after {
    background-color: $primary !important;
  }
  .alert-info {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 1em;
  }
  .cw-mode-metro,
  .cw-mode-bus-access,
  .cw-mode-train,
  .cw-mode-ter {
    display: inline-block;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    color: #fff;
    color: #868e96;
    background-color: $primary;
    color: #fff;
    align-self: center;
    position: relative;
  }

  .cw-mode-taxibus,
  .cw-mode-bus,
  .cw-mode-tod,
  .cw-mode-boat,
  .cw-mode-coach {
    border-radius: 100% !important;
    color: #fff;
    color: #868e96;
    background-color: $primary;
    color: #fff;
    align-self: center;
    position: relative;
  }

  .ctw-physical-stop-card {
    margin-top: 1em;
  }

  .input-date-time-text {
    display: none;
  }

  .jp-options-trip-type-form-check-input {
    display: none !important;
  }

  .item-line-disruption-icon {
    width: 1.5rem;
    height: 1.5rem;
    top: -0.85rem;
    right: -0.5rem;
  }

  /**
  ----------------------------------------------------------------
  ------------------  card-jp-solution  --------------------------
  ----------------------------------------------------------------
  **/

  .card-jp-solution {
    margin-bottom: 1rem;
    &:hover {
      background-color: #f8f9fa;
    }
    .card-body {
      padding: 1rem;
      font-size: $font-size-medium;
      .card-jp-solution-times {
        margin-bottom: 0.5rem;
        .card-jp-solution-times-hour {
          font-size: $font-size-medium;
          padding: 0;
        }
        .card-jp-solution-times-walk {
          margin-right: 1rem;
          color: $secondary;
          .card-jp-solution-times-walk-icon {
            font-size: 0.9rem;
          }
        }
        .card-jp-solution-times-duration {
          font-weight: bold;
        }
      }
      .card-jp-solution-mode-items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .card-jp-solution-mode-item {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          .optyweb {
            margin: 0;
          }
          &:not(:last-child):after {
            content: "";
            display: block;
            width: 0.3rem;
            height: 0.3rem;
            margin-left: 0.5rem;
            border-radius: 100rem;
            background-color: #1c1c28;
          }
        }
      }
      .card-jp-solution-network {
        font-size: 0.75rem;
        color: #6a6a6a;
      }
      .item-price {
        padding: 0.2rem 0.5rem;
        font-size: $font-size-medium;
        color: #fff;
        font-weight: 400;
        white-space: nowrap;
        border-radius: 100rem;
        background-color: #0b4f6c;
      }
    }
  }

  /**
  ----------------------------------------------------------------
  ----------------------------------------------------------------
  **/

  /**
  ----------------------------------------------------------------
  -------------------  road-sheet-card  --------------------------
  ----------------------------------------------------------------
  **/

  .road-sheet-card {
    margin-bottom: 1rem;
    .card-body {
      padding: 1rem;
      & > div:first-child {
        margin-bottom: 0.5rem;
      }
      .road-sheet-card-hour,
      .road-sheet-card-mode {
        justify-content: center;
        width: 3.75rem;
        margin-right: 1rem;
        text-align: center;
      }
      .road-sheet-card-hour,
      .road-sheet-card-info,
      .road-sheet-card-distance,
      .road-sheet-card-duration {
        font-size: $font-size-medium;
        font-weight: bold;
      }
      .text-right {
        .road-sheet-card-distance {
          line-height: 1;
          color: $secondary;
          font-weight: normal;
        }
      }
      .road-sheet-card-mode {
        .road-sheet-card-mode-item {
          .road-sheet-card-mode-icon-mode {
            .optyweb {
              position: relative;
              z-index: 1;
              margin: 0;
              width: 30px;
              height: 30px;
            }
          }
        }
        .middle {
          min-height: 1rem;
          border-left: 0.5rem dotted #6a6a6a;
        }
      }

      .road-sheet-card-info {
        font-weight: normal;
        .road-sheet-card-map {
          min-height: 3rem;
          margin-top: 1rem;
          border-radius: 0.5rem;
          background-image: url(../site/mini-map.png);
          background-size: cover;
          background-position: center center;
        }
      }
      .road-sheet-card-details-stops {
        display: block;
        margin: 0.5rem 0;
        & > .card {
          overflow: visible;
        }
        .collapse:not(.show) {
          display: none !important;
        }
        .card-body {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
        .road-sheet-card-details-stops-item {
          color: #6a6a6a;
          font-weight: 400;
          font-size: 0.75rem;
        }
        .road-sheet-card-details-stops-btn {
          margin-bottom: 0;
          font-size: 0.75rem;
          color: #1c1c28;
          font-weight: 700;
        }
        .btn-collapse {
          padding: 0.6rem 1rem;
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 0.5rem;
          color: #6a6a6a;
          font-size: $font-size-medium;
          text-align: left;
        }
      }
      .road-sheet-card-detail {
        .road-sheet-card-detail-link {
          margin-top: 1rem;
          font-size: $font-size-medium;
          font-weight: normal;
          text-decoration: underline;
        }
      }
    }
    .road-sheet-card-alert {
      margin-top: 15px;
    }
  }

  .road-sheet-card-carpool-btn {
    padding: 0.4em !important;
    border: 3px solid $primary;
    background-color: white;
    color: #1c1c28;
    margin-top: 1em;
    border-radius: $global-border-radius;
    position: relative;
    z-index: 2;
    &:hover {
      border: 3px solid $primary;
      background-color: $primary;
      color: white;
    }
  }

  .road-sheet-card.road-sheet-card-wait {
    .d-flex.align-items-center {
      .road-sheet-card-info-stop {
        .item-line.align-items-center {
          margin: 6px 15px 0 0;
        }
      }
    }
  }

  /**
  ----------------------------------------------------------------
  ----------------------------------------------------------------
  **/

  .jp-solution-mode-item-title-icon:before {
    margin-right: 1em !important;
  }
  .jp-solution-mode-item-title-icon {
    color: black !important;
    top: 0em !important;
    transform: none !important;
    left: auto !important;
    align-self: unset !important;
    margin: unset !important;
    background-color: unset !important;
    padding: 0em !important;
  }

  .jp-solution-own-means-item {
    margin-bottom: 1rem;
    .jp-solution-own-means-item-link {
      width: 100%;
      padding: 0.5rem;
      border-radius: $global-border-radius;
      box-shadow: 0 0 0.4375rem #0003;
      background-color: #fff;
      &:hover {
        background-color: #f8f9fa;
      }
      .p-solution-own-means-item-icon {
        margin: 0;
        border: 2px solid #b10058;
        border-radius: 100rem;
      }
      .jp-solution-own-means-item-content {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        font-size: $font-size-content;
        .jp-solution-own-means-item-content-info {
          display: flex;
          align-items: center;
          margin-left: auto;
          font-weight: bold;
          .carbon-footprint {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .jp-solution-mode-btn {
    background-color: white;
    color: black;
    width: 100%;
    border: 3px solid $primary;
    border-radius: 1em;
  }

  .jp-solution-mode-item-title {
    margin-bottom: 1em;
    font-size: $font-size-content;
    font-weight: bold;
  }
  .list-inline-item .nearby-mode-item {
    filter: $global-shadow;
    border-radius: 2rem;
  }
  .list-inline-item .cw-mode-trolley {
    top: 0;
  }

  .line-marker {
    display: flex;
    align-items: center;
    //top: -1.5rem !important;
    padding: 4px;
    border-radius: 1.5rem;
    margin-top: -1rem !important;
    margin-bottom: 1.25rem;
    z-index: 2;
    //left: 50%;
    //transform: translateX(-50%);
    width: auto;
    //position: absolute;
  }
  .line-marker-name {
    color: black;
    padding: 0px 0.5rem;
    border-radius: 1.5rem;
    background-color: #fff;
    font-size: $font-size-line-number;
    white-space: nowrap;
    font-weight: 600;
    min-width: 1.125rem;
  }
  .line-marker-icon {
    background: unset !important;
    left: unset !important;
    transform: unset !important;
    top: unset !important;
    padding: 0.1rem !important;
    width: 1.25rem;
    height: 1.25rem;
    font-size: $font-size-large-icon;
    margin: 0 4px;
    display: flex;
    align-items: center;
  }

  .item-line-mode {
    margin: unset !important;
    align-self: unset !important;
    position: unset !important;
    left: unset !important;
    transform: unset !important;
    top: unset !important;
    background-color: unset !important;
    font-size: 20px;
  }

  .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
    margin-left: -26px;
    margin-top: -26px;
    width: 52px;
    height: 52px;
    transform: translate3d(635px, 305px, 0px);
    z-index: 1305;
    display: inline-grid;
    .rt-vehicle {
      position: relative;
      .rt-vehicle-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.25rem;
        height: 3.25rem;
        color: #444;
        background-color: #44444496;
        border-radius: 100rem;
      }
      .rt-vehicle-arrow {
        position: absolute;
        top: -0.75rem;
        display: inline-block;
        height: 0;
        width: 0;
        border-right: 0.75rem solid transparent;
        border-bottom: 1.625rem solid currentColor;
        border-left: 0.6875rem solid transparent;
      }
      .rt-vehicle-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        color: #fff;
        border-radius: 100rem;
        background-color: #444;
      }
    }
  }

  .leaflet-marker-icon {
    border: none;
    background: none;
    display: flex;
  }

  .leaflet-div-icon
    .optyweb:not(.stop-marker):not(.line-marker-icon):not(
      .departure-marker
    ):not(.cw-poi-stop2):not(.arrival-marker-icon):not(
      .poi-parking-bikestation-marker
    ):not(.categ-4):not(.categ-5):not(.categ-11):before {
    font-size: 100%;
    color: inherit;
  }
  .leaflet-div-icon
    .optyweb:not(.stop-marker):not(.line-marker-icon):not(
      .departure-marker
    ):not(.cw-poi-stop2):not(.arrival-marker-icon):not(
      .poi-parking-bikestation-marker
    ):not(.categ-4):not(.categ-5):not(.categ-11).stop-mode-marker:after {
    content: "";
    height: 1.5rem;
    width: 4px;
    filter: $global-shadow;
    border-radius: 0 0 2px 2px;
    background-color: $primary;
    top: calc(100%);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .leaflet-zoom-animated leaflet-interactive:focus {
    width: 20em;
  }

  .stop-mode-marker {
    padding: 0.3em;
  }
  .stop-mode-marker:before {
    font-size: $font-size-title !important;
  }
  .arrival-marker-label:after {
    content: "";
    height: 1.5rem;
    width: 4px;
    filter: $global-shadow;
    border-radius: 0 0 2px 2px;
    background-color: $primary;
    top: calc(100% + 2px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .departure-marker-label:after {
    content: "";
    height: 1.5rem;
    width: 4px;
    filter: $global-shadow;
    border-radius: 0 0 2px 2px;
    background-color: $primary;
    top: calc(100% + 2px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .arrival-marker-label {
    font-size: $font-size-line-number;
    line-height: 1.33333;
    font-weight: 600;
    width: auto;
    color: #fff;
    text-align: center;
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;
    background-color: $primary;
    white-space: nowrap;
    order: -1;
    margin-top: -2.5rem;
    position: absolute;
    margin-bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .departure-marker-label {
    font-size: $font-size-line-number;
    line-height: 1.33333;
    font-weight: 600;
    width: auto;
    color: #fff;
    text-align: center;
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;
    background-color: $primary;
    white-space: nowrap;
    order: -1;
    margin-top: -2.5rem;
    position: absolute;
    margin-bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .dropdown-toggle::after {
    display: none;
  }
  .custom-control-access-trip-label-icon {
    display: flex;
    align-items: center;
  }
  .custom-control-access-trip-label-icon::before {
    color: $secondary;
  }

  #time,
  #date,
  .jp-time-search-form-control {
    border-radius: 1em;
    border: 3px solid $primary;
    height: 2.5em;
  }

  .jp-search-input-group {
    filter: $global-shadow;
    margin-bottom: 1em;
  }

  .jp-search-options-dropdown-menu {
    border-radius: $global-border-radius;
    margin-top: 0.5em;
    transform: translate(70px, -7px) !important;
    @media screen and (min-width: 900px) {
    }
  }

  .panel-search .dropdown-toggle::after {
    display: none;
  }

  .jp-search-back-btn {
    margin: 0 !important;
  }

  .btn-step-next:disabled {
    border: 3px solid grey;
  }
  .btn-step-previous:disabled {
    border: 3px solid grey;
  }

  .btn-step-next,
  .btn-step-previous {
    padding: 0.4em 1rem !important;
    margin-top: 1rem;
    border: 3px solid $primary;
    border-radius: 100px;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .carbon-footprint-a {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #0c6644;
  }

  .carbon-footprint-c {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #9d6e04;
  }
  .journey-planner-no-result-mode-alert {
    background-color: white;
    border: 1px solid #f1f3f5;
    color: black;
    font-weight: bold;
    border-radius: 0.75rem;
    font-size: $font-size-content;
  }

  .carbon-footprint-text {
    font-size: $font-size-extra-small;
    line-height: 1;
    font-weight: 700;
  }

  .carbon-footprint-rank {
    font-size: $font-size-line-number;
    line-height: 1;
    font-weight: 700;
  }

  .card-body {
    padding: 0em 1.25rem 1.25rem 1.25rem;
  }

  .card-footer {
    padding: 1em;
    align-items: center;
    border: none;
    background-color: #ecf0f5;
    display: flex;
    justify-content: space-between;
    font-size: $font-size-content;
    line-height: 1.42857;
    color: black;
    border-radius: 0em 0em 1em 1em !important;
  }
  .cw-toolbar-calendar::before {
    color: $primary;
  }
  .cw-toolbar-print::before {
    color: $primary;
  }
  .cw-social-share::before {
    color: $primary;
  }

  .dropdown-item {
    padding: 0.5rem 0.5rem !important;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .dropdown-menu {
    padding: 0.5em;
    width: 14em;
    padding: 0rem 0rem;
    filter: $global-shadow;
    @media screen and (max-width: 600px) {
      left: -170px !important;
    }
  }
  .custom-control-access-trip,
  .jp-search-options-dropdown-item {
    padding: 0.5em;
    align-items: center;
    display: flex;
  }
  .jp-search-options-dropdown-item:hover {
    background: none !important;
    color: $primary;
  }
  .cw-toolbar-settings::before {
    color: $primary;
  }
  .cw-pin-via::before {
    color: $primary;
  }
  .custom-control-access-trip-label {
    display: flex;
    width: 100%;
    gap: 7px;
  }

  .panel-content {
    width: 28.5rem !important;
    margin-left: 1em;
  }

  .input-group
    button:not(.btn-previous-page):not(.member-profil-edit-email-btn):not(
      .member-profil-edit-password-btn
    ):not(.member-profil-edit-phone-btn) {
    position: absolute;
    right: 10px;
    z-index: 3;
    font-weight: bold;
  }

  .btn-from-to-item-btn {
    border-radius: 0.75rem;
  }

  .schedule-logical-physical-stop-mode-title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .item-location-content {
    font-size: $font-size-medium;
    font-weight: bold;
    color: black;
  }

  .card-line {
    filter: $global-shadow;
    margin-bottom: 1em;
  }

  .card-line-item {
    padding: 0.25rem 0.5rem;
    background-color: #f1f3f5;
    border-radius: 0.75rem;
    font-size: $font-size-content;
    line-height: 1.33333;
    color: #212529;
    font-weight: 600;
    margin-bottom: 0.2em;
  }

  .card-line-info {
    display: inline-block;
    color: #212529;
    text-align: left;
    padding-left: 1em;
    font-weight: bold;
    font-size: $font-size-medium;
    line-height: 1.5;
  }

  .card-physical-stop {
    margin-bottom: 1em;
  }
  .next-departure-item-time {
    padding: 0.25rem 0.5rem;
    border-radius: 0.75rem;
    font-size: $font-size-medium;
    line-height: 1.33333;
    color: #212529;
    font-weight: 600;
  }

  .schedule-logical-physical-stop-item {
    margin-bottom: 1em;
  }

  .btn-previous-page {
    height: $size-round-button;
    width: $size-round-button;
    transition: all 150ms ease 0s;
    border-radius: 50%;
    background-color: $primary;
    filter: $global-shadow;
    border: none;
    color: white;
  }

  .next-departure-item-terminus {
    margin-bottom: 0.2em;
  }

  .next-departure-item {
    margin-bottom: 0.2em;
    font-size: $font-size-medium;
  }

  .next-departure-container {
    background-color: white;
    border-radius: 30px;
    margin-bottom: 1em;
  }

  .next-departure-items {
    border-radius: 30px;
    margin-bottom: 0.2em;
  }

  .next-departure-items .next-departure-item {
    color: #e79200;
    display: flex;
    align-items: center;
    transition: all 150ms ease 0s;
    border-radius: 30px;
    margin-bottom: 0.2em;
    padding: 0.5rem 1rem;
  }

  .card-physical-stop {
    filter: $global-shadow;
    border-radius: 1.5rem;
  }
  .card-header {
    border-radius: 1.5rem !important;
    background-color: inherit !important;
    align-items: center;
    border: none;
  }

  .next-departure-single {
    font-size: $font-size-line-number;
    background-color: #f8f9fa;
    border-radius: 30px;
    margin-bottom: 1em;
    padding: 0.5rem 1rem;
  }

  .item-location-content {
    font-weight: bold;
  }

  .btn.stretched-link {
    text-align: left;
  }

  .schedule-logical-physical-stop-city {
    font-size: $font-size-content;
    font-weight: normal;
  }

  .item-line {
    padding: 0.5rem;
    border-radius: 1.5rem;
    height: 30px;
    position: relative;
  }

  .btn-collapse:before {
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    right: 1.25rem;
    position: absolute;
    top: 30px;
    transform: translateY(-50%);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/assets/site/chevron-bottom.svg");
  }

  .cw-mode-wait::before {
    font-size: $font-size-large;
  }

  .item-line-number {
    border-radius: 2rem;
    min-width: 1em;
    text-align: center;
    background-color: white;
    font-size: $font-size-line-number;
    font-weight: bold;
    padding: 0.125rem 0.5rem;
  }

  .card {
    border-radius: 1.5rem;
    h2 {
      margin-top: 0;
    }
  }

  .nav-tabs .nav-item .nav-link {
    text-align: center;
    display: inline-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    color: black;
    border: none;
    padding: 0.75rem;
    border-radius: 0.75rem;
    font-size: $font-size-medium;
    line-height: 1.71429;
  }

  #pan-panel .panel-content-scroll {
    padding: 1rem;
    a.anchor-panel:focus {
      box-shadow: none !important;
      border-color: none !important;
    }
  }

  .nav-item {
    flex: 1;
  }
  .nav-link.active {
    background-color: $primary !important;
    color: white !important;
  }

  .nav-tabs {
    border: none;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: #f8f9fa;
    margin-bottom: 1.5rem;
  }

  .panel-content-scroll-body {
    margin-top: 1.5rem;
  }

  .schedule-logical-physical-stop-title {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .panel-content-desktop {
    border-radius: 1.5rem;
    @media screen and (max-width: 1337px) {
      margin-bottom: 8em;
    }
  }
}

.road-sheet-steps {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 3rem;
    height: calc(100% - 32px);
    width: 0.5rem;
    background-color: $primary;
  }
}
.road-sheet-footer {
  color: #fff;
  background-color: #444;
  border-radius: $global-border-radius;
  margin: 0 -1rem -2.5rem;
  padding: 2.5rem 1rem;
  &:before {
    content: "";
    display: block;
    height: 2rem;
    background-color: white;
    width: calc(100% + 2rem);
    margin: -2.5rem -1rem 2rem;
    border-radius: 0 0 $global-border-radius $global-border-radius;
  }
  .road-sheet-footer-ecology {
    align-items: center;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    border: none;
    p {
      margin-bottom: 0.3rem;
      margin-top: 0;
    }
    .carbon-footprint {
      margin: 0.4rem 1.5rem 0.4rem 1rem;
      transform: scale(1.5);
    }
  }
  .rs-misc-info {
    margin-bottom: 1.5rem;
    .rs-misc-info-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      margin: 0 1rem 0 0.65rem;
      font-size: 1.6rem;
      border-radius: 100rem;
      background-color: #fff;
      color: #1c1c28;
    }
    .rs-misc-info-title {
      font-size: 0.875rem;
      font-weight: 700;
    }
    .rs-misc-info-text {
      font-size: 0.75rem;
    }
  }
  .legend {
    font-size: 0.875rem;
    color: #1c1c28;
    .btn-collapse {
      margin-bottom: 0;
      font-size: 0.9375rem;
      color: #1c1c28;
      padding: 0.6rem 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .card {
      margin-bottom: 1rem;
      border-radius: 1.5rem;
      box-shadow: none;
      background-color: #fff;
      overflow: visible;
      margin-left: 0;
      margin-right: 0;
      .card-header {
        padding: 0;
      }
      .legend-accordion-item {
        margin-bottom: 1rem;
        .legend-accordion-item-label {
          position: relative;
          left: auto;
        }
        .optyweb {
          margin-right: 1rem;
          font-size: 1.25rem;
        }
        .legend-stroke-walk {
          height: 0;
          border-top: 0.5rem dotted #000000;
        }
        .legend-stroke {
          width: 3rem;
          margin-right: 1rem;
          height: 0.5rem;
        }
        .legend-stroke-pt {
          background-color: black;
        }
        // les légendes dans la zone ZFE
        .legend-stroke-bike,.legend-stroke-bike-service {
          background-color: #336d33
        }
        
        .legend-stroke-bicyclepath {
            background-color: #74ff6e
        }
        
        .legend-stroke-car,.legend-stroke-unknown {
            background-color: #6a6a6a
        }
        
        .legend-stroke-freeflow {
            background-color: #55a903
        }
        
        .legend-stroke-heavy {
            background-color: #d47905
        }
        
        .legend-stroke-congested {
            background-color: #db1f06
        }
        
        .legend-stroke-impossible {
            background-color: #000
        }
      }
    }
  }
}

.legend-accordion-item {
  .cw-mode-trolley,
  .cw-mode-metro,
  .cw-mode-train,
  .cw-mode-ter {
    transform: none;
    left: auto;
    background-color: transparent;
    border-radius: 0;
    color: black;
    position: unset;
  }
  .cw-mode-taxibus,
  .cw-mode-bus,
  .cw-mode-tod,
  .cw-mode-boat,
  .cw-mode-coach {
    background-color: transparent;
    border-radius: 0;
    color: black;
  }
}
.road-sheet-details-tc-waiting-line {
  align-items: center;
  margin-bottom: 1em;
}
.road-sheet-details-tc-stops-item.first
  .road-sheet-details-tc-stops-item-infos {
  background-color: #ffd9c3;
}
.road-sheet-details-tc-stops-item-infos {
  padding: 0.75rem 1rem;
}
.road-sheet-details-tc-stops-item-time {
  font-weight: bold;
}
.road-sheet-details-tc-stops-item-name {
  font-weight: bold;
}
.road-sheet-details-tc-stops-item.last .road-sheet-details-tc-stops-item-infos {
  background-color: #f8f9fa;
  border-radius: 1.25rem;
}
.road-sheet-details-tc-stops-item.first
  .road-sheet-details-tc-stops-item-infos {
  padding: 0.75rem 1rem;
  border-radius: 1.25rem;
}
.road-sheet-details-title-instruction {
  font-size: $font-size-title;
  font-weight: bold;
}
.road-sheet-details-tc-waiting-btn {
  font-weight: bold !important;
  color: $primary !important;
  text-decoration: underline;
  margin-bottom: 1em 0em !important;
}
.road-sheet-details-tc-waiting-btn:hover {
  text-decoration: none;
}
.accessibility-icon::before {
  color: $secondary;
}
.road-sheet-details-tc-waiting-line-direction {
  margin-left: 1em;
}

.road-sheet-details-title-distance,
.road-sheet-details-go-to-item-distance {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
  color: #312783;
}
.road-sheet-details-title-distance {
  padding: 0em 1em;
}
.road-sheet-details-title-duration {
  gap: 10px;
  font-weight: bold;
  border-radius: 4em;
  padding: 0.2em 1em;
  background-color: $secondary;
  color: white;
}
.road-sheet-footer {
  .btn-primary {
    background-color: white;
    color: black;
    width: 100%;
    border: 3px solid $primary;
    border-radius: $global-border-radius;
  }
}
.card-footer {
  .btn-primary {
    background-color: white;
    color: black;
    width: 100%;
    border: 3px solid $primary;
    border-radius: $global-border-radius;
  }
}
.road-sheet-footer-return-trip-text {
  margin-top: 1em;
  font-size: $font-size-line-number;
}

.road-sheet {
  align-items: center;
}

.road-sheet-header-ecology {
  order: 2;
}

.road-sheet-header-walk {
  display: flex;
  color: $secondary;
  font-weight: bold;
  font-size: $font-size-line-number;
  padding: 0.7em;
  background-color: #f1f3f5;
  border-radius: 20px;
  margin-left: 1em;
}
.road-sheet-details-title-stop-access-icon {
  margin-left: 10px;
}
.road-sheet-details-go-to-item {
  background-color: #f8f9fa;
  transition: all 150ms ease 0s;
  border-radius: $global-border-radius;
  margin-bottom: 0.2em;
  padding: 0.2em 1em;
}
.road-sheet-details-go-to-item-instruction {
  font-size: $font-size-medium;
}
.road-sheet-details-go-to-item-address {
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
}
.road-sheet-details-go-to-item-duration {
  font-size: $font-size-line-number;
}
.road-sheet-details-go-to-item-distance {
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
}
.road-sheet-details-tc-stops-item-name-locality {
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
  color: $primary;
}
.road-sheet-details-title-duration-value {
  min-width: 3em;
}

/**
  --------------------------------------------------------------------
  --------------------------------------------------------------------
  ----------------------Mode Mobile ----------------------------------
  --------------------------------------------------------------------
  --------------------------------------------------------------------
  */
@media screen and (max-width: 768px) {
  .jp-search-options-dropdown-menu {
    margin: 9em 0em 0em -11em !important;
  }
  .input-date-time {
    justify-content: space-between;
  }
  .jp-time-search {
    margin-top: 2px !important;
    flex-direction: column;
    gap: 20px;
  }
  .panel-content-mobile {
    padding-top: 0px !important;
    border-radius: 1.5rem;
    padding: 0 !important;
    min-height: 100%;
  }
  .mobile-panel-search {
    padding: 1.5rem;
    align-items: flex-start;
    background-color: #fff;
    margin-top: 70px;
  }
  .btn-previous-page {
    margin-right: 1em;
  }

  #pan-panel:hover {
    background-color: #f8f9fa;
  }

  .panel-content-scroll {
    padding: 0rem 1.5rem 15rem 1.5rem !important;
  }
  #panel-content-btn {
    padding: 1rem 1rem 0rem;
    color: #fff;
    display: flex;
    justify-content: center;
    margin: 0;
    background-color: $primary;
    position: relative;
    filter: none;
    touch-action: none;
    user-select: none;
    height: 5em;
    border-radius: 20px;
    border: none;
  }
  #panel-content-btn:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2rem;
    width: 100%;
    background-color: #fafafa;
    border-radius: 1.5rem 1.5rem 0 0;
  }

  .btn-previous-page {
    margin-right: 1em;
  }

  #pan-panel {
    margin-left: 0em !important;
    width: 100% !important;
    border-radius: 20px;
  }

  #panel-content-btn {
    padding: 1rem 1rem 0rem;
    color: #fff;
    display: flex;
    justify-content: center;
    margin: 0;
    background-color: $primary;
    position: relative;
    filter: none;
    touch-action: none;
    user-select: none;
    height: 5em;
    border-radius: 20px;
    border: none;
  }
  #panel-content-btn:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2rem;
    width: 100%;
    background-color: white;
    border-radius: 1.5rem 1.5rem 0 0;
  }
}


ctw-journey-planner{
  // le footer ses solutions proposées, avant la légende
  .road-sheet-footer{
    // la partie écologie 
    .road-sheet-footer-ecology{
      .road-sheet-footer-ecology-symbole{
        .carbon-footprint.carbon-footprint-c.road-sheet-footer-ecology-symbole{
          width: 1.75rem;
          height: 1.75rem;
          line-height: 1;
          text-align: center;
          display: block;
          min-width: 1.75rem;
          min-height: 1.75rem;
          padding: 0.15rem;
          .carbon-footprint-rank { // le symbole C
            display: block;
            font-size: .875rem;
            font-weight: 700;
          }
          .carbon-footprint-text{
            font-size: 0.45rem
          }
        }
      }
      // le text de l'écologie 
      .road-sheet-footer-ecology-text{
        .btn.btn-link {
          position: relative;
          top: -0.0625rem;
          text-decoration: underline;
          padding: 0;
          color: #fff;
          font-size: .75rem;
        }
      }
    }
  }

  // Style pour l'alert infos supp pour ZFE
  .alert.alert-warning.d-flex.mt-2{
    font-weight: $font-weight-bold;
    .alert-content{
      margin-left: 0px;
    }
    .alert-text{
      button.btn.alert-link {
        color: #333;
        font-size: .75rem;
        text-decoration: underline;
        line-height: 1.3;
        padding-left: 0px;
      }
    }
  }
}
