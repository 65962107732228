@import "../../variables.scss";

.map-nearby {
  font-family: $font;
  left: 1.5rem;
  margin-bottom: 1.5rem;

  .nearby-mode-item-btn-icon {
    font-size: 2em;
    color: $primary;
  }
  #searchElem {
    // Configure la zone de saisie
    height: $size-round-button;
    border-radius: $global-border-radius;
    border: solid;
    border-color: $primary;
    margin-left: 10px;
  }
  #pan-panel {
    max-width: 700px;
    margin-left: 17px;
    border-radius: $global-border-radius;
  }
  #pan-panel .panel-content-scroll {
    padding: 1rem;
    .accordion.legend {
      margin-bottom: 2rem;
    }
    .nearby-popup {
      position: relative;
      margin-top: 0.5rem;
      .panel-content-scroll-body {
        .btn-secondary {
          background-color: #fff;
          color: $primary;
          border-radius: $global-border-radius;
          border: 3px solid $primary;
        }
      }

      .schedule-header-menu {
        position: absolute;
        right: 0;
        margin: 0;
        top: -5px;
      }
      .nearby-popup-menu {
        position: absolute;
        top: 0;
        right: 0;
        .btn-favourite,
        .dropdown-option-btn {
          box-shadow: 0 0 0.4375rem #0003;
        }
      }
    }
  }
  .nearby-mode-items {
    gap: 1rem;
    margin: 0;
    padding: 1rem 1.5rem;
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    .nearby-mode-item {
      width: 100%;
      display: flex;
      justify-content: center;
      .nearby-mode-item-btn:last-child {
        &:hover {
          color: $primary;
        }
      }
      .nearby-mode-item-btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100rem;
        box-shadow: 0 0 0.4375rem #0003;
        width: 3rem;
        height: 3rem;
      }
    }
  }

  ul.recharging-station-items {
    margin-top: 2rem;
    margin-bottom: 1rem !important;
    overflow: hidden;
    border-radius: 0.9375rem;
    position: inherit;
    right: inherit;
    .recharging-station-item {
      display: flex;
      align-items: center;
      margin-bottom: 0.125rem;
      padding: 0.75rem 1rem;
      background-color: #f9f9f9;
      .recharging-station-outlet-items {
        .disabled {
          opacity: 0.5;
        }
      }
    }
  }

  // This style is used for the selected icons and the cards items in the list of card items
  // When Showing the 'Plan interactif des lignes' in the 'Transport en commun' menu in the 'carte interactive menu'
  .list-unstyled {
    .lines-map-line-items {
      li.selected:first-of-type {
        margin-top: 1rem;
      }
      .lines-map-line-item-selected-icon {
        font-size: 1rem;
        position: absolute;
        z-index: 2;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0.4rem;
        color: #fff;
        border-radius: 100rem;
        background-color: $primary;
        transform: translate(-25%,-25%);
      }
      ctw-line-card{
        .card-header{
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        }
      }
    }
  }
}
