@import "../../variables.scss";
@import "../../mixins.scss";

//ADD FAVORITE
.member-signin-modal-title {
  font-size: 2rem;
  margin-bottom: 1em;
}
.member-signin-modal-btn {
  width: 100%;
  background-color: $primary !important;
  border: none !important;
}
.member-features-items {
  padding: 0em 1em;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.member-features-item-icon {
  width: 3.5rem;
  height: 3.5rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100% !important;
  background-color: $primary;
  color: #fff;
  margin-right: 1rem;
  @media screen and (max-width: 900px) {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
  }
}
.member-features-item-icon::before {
  font-size: 1.2rem;
  @media screen and (max-width: 900px) {
    font-size: $font-size-medium;
  }
}

.modal-dialog {
  font-family: $font;
}

.custom-control-access-trip-label-icon {
  color: $secondary;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
  font-size: $font-size-title;
}

.jp-options-confirm-btn {
  width: 100%;
  background-color: $primary !important;
  border: none !important;
}
.jp-options-reset-btn {
  color: $primary !important;
}
