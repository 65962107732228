@import "../../variables.scss";

.ctw-login,
.ctw-confirm-mail-page {
  /* ALERT */

  .favourite-dropdown-btn {
    filter: $global-shadow;
    background-color: white;
    border-radius: 4em;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .member-home-logout-btn {
    background-color: $primary;
    border-color: $primary;
  }
  .card-line-info {
    margin-left: 0.5em;
  }

  .card {
    border-radius: 1.5rem;
    &.member-home-card {
      .card-header {
        margin-top: 0;
        border-radius: 1.5rem !important;
        background-color: inherit !important;
        align-items: center;
        border: none;
        margin-bottom: 0 !important;
        padding: 0 1rem;
      }
      h2 > .collapsed:before {
        transform: inherit !important;
      }
      .btn-collapse:before {
        content: "";
        width: 0.75rem;
        height: 0.75rem;
        right: 1.25rem;
        position: absolute;
        top: 30px;
        transform: translateY(-50%);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/assets/site/chevron-bottom.svg);
      }
    }
  }

  .member-disruption-no-disruption-icon:before {
    font-size: 2rem;
  }
  .member-disruption-no-disruption-content {
    text-align: center;
  }
  .member-disruption-no-disruption-icon {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    color: #fff;
    color: #868e96;
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
    background-color: #71b852;
    color: #fff;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  ctw-data-protection > .btn-collapse::before {
    content: none !important;
  }
  .custom-switch.custom-switch-right
    .custom-control-input
    ~ .custom-control-label {
    padding-right: 3rem;
  }

  .member-alert-settings-periods {
    margin-bottom: 1em;
  }
  .member-alert-settings-periods-text {
    color: #6c757d;
    margin-bottom: 0.5em;
    font-weight: bold;
  }
  .cw-toolbar-calendar:before {
    font-family: "Material Icons";
    content: "\ebcc";
    color: $primary;
    font-size: 1.8em;
  }

  .cw-misc-hour1:before {
    font-family: "Material Icons";
    content: "\efd6";
    color: $primary;
  }
  .member-alert-settings-periods-icon {
    margin-right: 1em;
  }
  .member-alert-settings-title:not(h1) {
    font-size: 1.5rem;
  }
  .member-alert-settings-title:not(h2) {
    @media screen and (max-width: 900px) {
      margin-top: 0.5em !important;
      margin-bottom: 0.5em !important;
      text-align: center;
    }
  }

  .member-alert-settings-title {
    padding: 0 !important;
  }
  .member-alert-settings-card {
    padding: 1em;
    filter: $global-shadow;
    @media screen and (max-width: 900px) {
      border: none !important;
    }
  }
  .member-alert-settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 36em;
    @media screen and (max-width: 900px) {
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
    }
  }

  /* PROFIL */
  .member-profil-edit-title {
    @media screen and (max-width: 900px) {
      font-size: 2rem;
      margin-top: 0.5em !important;
      margin-bottom: 0.5em !important;
    }
    padding: 0em 1em;
  }

  .member-profil-edit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 50em;
    @media screen and (max-width: 900px) {
      padding: 0 !important;
      margin: 0 !important;
    }
    .input-group .btn {
      height: 2.5rem;
      padding: 0.375rem 0.75rem;
      display: flex;
      align-items: center;
      border: 0;
    }
    .btn-container {
      margin: 2rem auto;
      width: 200px;
      .btn-secondary {
        &:hover {
          background-color: $primary-contrast !important;
        }
      }
    }
    .member-login-signin-rgpd {
      .btn-link {
        margin-bottom: 0;
      }
    }
    .member-profil-edit-profile-delete-btn {
      margin-top: 2rem;
    }
  }
  .member-profil-edit-password-btn {
    position: initial !important;
  }
  .member-profil-edit-profile-gdpr-btn {
    color: $primary;
    margin-bottom: 1em;
  }
  .member-profil-edit-profile-save-btn {
    margin-bottom: 1em;
  }

  label {
    font-weight: bold;
  }
  .member-profil-edit-card {
    padding: 2em 0;
    width: 90%;
    margin: 0 auto;
    filter: $global-shadow;
    border-radius: $global-border-radius;
    @media screen and (max-width: 900px) {
      border: none !important;
      .card-body {
        min-width: 100%;
        margin: 0 !important;
      }
    }
    .custom-control {
      padding-left: 1.5rem !important;
    }
    .card-body {
      width: 60%;
      margin: 0 auto;
      padding: 1rem !important;
      .mandatory-fields {
        font-size: 0.75rem;
        text-align: right;
      }
      .custom-control-inline {
        display: inline-flex;
        margin-right: 1rem;
        min-height: 1.5rem;
        padding-left: 1.5rem;
        position: relative;
      }
    }
    .btn-primary {
      background-color: $primary;
      border-color: $primary;
    }
  }
  #phone,
  #password,
  #email,
  #lastname,
  #firstname {
    filter: $global-shadow;
  }
  #password,
  #phone {
    border-radius: 1rem !important;
    padding: 1.5em 1em;
  }

  #email {
    border-radius: 1rem !important;
  }

  /** DASHBOARD **/
  .cw-map-map3,
  .cw-pin-departure,
  .cw-pin-arrival,
  .cw-toolbar-edit,
  .cw-toolbar-delete {
    color: $primary;
  }
  .dropdown-item {
    gap: 10px;
    &:active {
      background-color: $primary;
      .optyweb {
        color: white;
      }
    }
  }

  .dropdown-menu {
    position: absolute !important;
    border-radius: $global-border-radius;
    z-index: 99;
    transform: translate(3.5em, -9px) !important;
    li:first-child {
      .dropdown-item {
        &:active,
        &:hover {
          border-top-left-radius: $global-border-radius;
          border-top-right-radius: $global-border-radius;
        }
      }
    }
    li:last-child {
      .dropdown-item {
        &:active,
        &:hover {
          border-bottom-left-radius: $global-border-radius;
          border-bottom-right-radius: $global-border-radius;
        }
      }
    }
  }
  .btn-collapse {
    width: 100%;
  }
  .member-newsletter-card-icon:before {
    font-family: "Material Icons";
    content: "\e158";
    color: $primary;
    font-size: 2em;
  }

  .member-home-work-distance {
    font-size: $font-size-medium;
    line-height: 1.71429;
    color: $secondary;
  }
  .close {
    color: white;
    opacity: inherit;
  }
  .close:hover {
    color: white;
  }
  .bg-success {
    background-color: $primary !important;
  }
  .toast-header {
    background-color: $primary;
    color: white;
    gap: 5px;
  }
  .toast-body {
    background-color: $secondary;
    color: white;
  }
  .ngb-toasts {
    top: 77px;
  }
  .bg-success {
    margin-top: 1em;
  }
  .member-alert-settings-title,
  .member-profil-edit-title,
  .member-alert-settings-title:not(h2),
  .member-home-title {
    margin-bottom: 1em;
    font-size: 2rem;
    font-weight: bold;
    @media screen and (max-width: 900px) {
      margin-top: 0.5em;
      font-size: 2rem;
      margin-bottom: 0.5em;
    }
    padding: 0em 1em;
  }
  .card-header {
    font-size: x-large;
    padding-left: unset;
  }
  .member-disruption-card-btn-icon,
  .member-home-add-btn-icon {
    margin-right: 1em;
    color: $primary;
  }

  .member-place-card-btn,
  .member-line-card-btn,
  .member-stop-card-no-result-btn,
  .member-disruption-card-btn {
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid $primary;
    text-align: left;
    font-weight: 700;
    border-radius: $global-border-radius;
    width: 100%;
    font-size: $font-size-medium;
  }

  .member-newsletter-card-icon {
    margin-right: 1em;
  }
  .custom-control {
    padding: 0 !important;
  }
  .custom-control
    .member-alert-settings-reception-switch
    > label[for="newsletter"],
  .custom-control-label:not(label[for="rememberMe"]):not(label[for="gcu"]):not(
      label[for="gdpr"]
    ):not(label[for="newsletter"]):not(label[for="ownerCivility1"]):not(
      label[for="ownerCivility2"]
    ) {
    display: flex;
    margin-bottom: 2em;
    margin-top: 1em;
    align-items: center;
  }
  .member-alert-settings-reception-switch > label[for="newsletter"]::after,
  .custom-control-label:not(label[for="rememberMe"]):not(label[for="gcu"]):not(
      label[for="gdpr"]
    ):not(label[for="newsletter"])::after:not(label[for="ownerCivility1"]):not(
      label[for="ownerCivility2"]
    ) {
    position: absolute !important;
    left: auto !important;
    top: 15px !important;
  }
  .member-alert-settings-reception-switch > label[for="newsletter"]::before,
  .custom-control-label:not(label[for="rememberMe"]):not(label[for="gcu"]):not(
      label[for="gdpr"]
    ):not(label[for="newsletter"])::before:not(label[for="ownerCivility1"]):not(
      label[for="ownerCivility2"]
    ) {
    right: 0;
    left: auto !important;
    top: 13px;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
  }
  .stretched-link {
    padding: 0 !important;
  }

  .member-my-profil-card-link {
    font-weight: bold;
    padding: 0 !important;
    color: $primary;
    text-decoration: underline;
    margin-top: 0.5em;
  }
  .member-my-profil-card-link:hover {
    text-decoration: none;
    color: $primary;
  }
  .member-home-card,
  .member-my-profil-card {
    margin-bottom: 2em;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    padding: 1em;
    .btn-collapse:focus {
      border: none;
    }
  }

  /**
  ----------------------------------------------------------------
  -------------------  member-tod-card-btn  ----------------------
  ----------------------------------------------------------------
  **/

  .member-home-cardm,
  .member-tod-card {
    .form-group {
      margin-left: 0 !important;
      border-right: none !important;
      margin-top: 1rem;
      .form-control {
        border-radius: 50px !important;
      }
    }
    .member-tod-card-btn {
      font-size: $font-size-medium;
      text-align: left;
      font-weight: 700;
      background-color: #fff;
      border: 1px solid #b10058;
      border-radius: $global-border-radius;
      padding: 10px 20px;
      margin: 1rem 0;
      .member-home-add-btn-icon {
        margin-right: 1rem;
        color: $primary;
      }
    }
  }

  /**
  ----------------------------------------------------------------
  ----------------------------------------------------------------
  **/

  .card-body {
    padding: 0 !important;
  }
  .flex-grow-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .member-home-work-icon,
  .member-my-profil-card-user-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    color: #fff;
    color: #868e96;
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
    background-color: $primary;
    color: #fff;
    margin-right: 1.5em;
  }
  .member-home-work-icon::before,
  .member-my-profil-card-user-icon::before {
    font-size: 2em;
  }

  .member-my-profil-card-user {
    align-items: center;
  }
  .btn-collapse,
  .member-alert-settings-title,
  .member-home-work-title,
  .member-home-work-text,
  .member-my-profil-card-email {
    font-weight: bold;
    font-size: 1rem;
  }

  /** LOGIN **/
  .form-group {
    margin-bottom: 1em !important;
  }
  .member-login-btn {
    margin-bottom: 1em;
    background-color: $primary;
    border: none;
  }
  #emailAddress {
    border-radius: 1rem !important;
    filter: $global-shadow;
  }
  .custom-checkbox {
    position: inherit !important;
    margin-left: 2em;
  }
  .grecaptcha-badge {
    margin-top: 2em;
  }
  input[type="password"],
  input[type="text"],
  input[type="date"],
  input#password,
  input#email {
    border-radius: 5rem !important;
    padding: 1.5em 1em;
  }

  .member-profil-edit-card input[type="date"] {
    border: 1px solid #ced4da !important;
    width: 100% !important;
    padding: 1.5em 1em !important;
    margin: 0 !important;
  }

  .input-group-append {
    align-items: center;
  }
  .show-password {
    height: inherit !important;
    display: flex !important;
    right: 20px !important;
    top: 5px;
  }
  h2 > .btn-collapse:before {
    top: 2em !important;
    transform: rotate(180deg) !important;
  }
  h2 > .collapsed::before {
    transform: inherit !important;
  }
  .btn:focus,
  .btn.focus {
    box-shadow: none !important;
  }
  .btn-collapse {
    text-align: left;
    text-decoration: none;
  }
  .collapsed {
    text-align: left;
    width: 100%;
  }
  .member-forgotten-password-modal-title {
    color: blue;
  }

  .custom-checkbox {
    margin-bottom: 1em;
  }
  .p-0 {
    color: black !important;
    font-size: 0.625rem !important;
    text-decoration: none !important;
    font-weight: none !important;
  }
  .text-right {
    text-align: center !important;
  }
  .btn-link {
    color: $primary;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 1em;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
  .text-center {
    margin-bottom: 1em;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary;
  }
  .btn-secondary {
    width: 100%;
    border-radius: $global-border-radius;
    padding: 1em;
    font-weight: bold;
  }
  .btn-secondary:hover {
    background-color: $primary-contrast;
    border-color: $primary-contrast;
  }
  .btn-primary {
    width: 100%;
    border-radius: $global-border-radius;
    padding: 1em;
    font-weight: bold;
  }

  .member-features-items {
    padding: 0em 1em;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  /**
  ----------------------------------------------------------------
  -------------------  member-tod-card-btn  ----------------------
  ----------------------------------------------------------------
  **/

  .member-login-signin {
    margin-bottom: 50px;
    &-btn-gcu {
      margin-top: 2em;
      padding: 1em;
      border: 1px solid $primary;
      border-radius: $global-border-radius;
      @media screen and (max-width: 900px) {
        margin-top: 1em;
      }
    }
    &-rgpd {
      margin-top: 4em;
      font-size: 0.625rem;
      .btn-link {
        margin-bottom: 0;
      }
    }
    &-row {
      justify-content: center;
      .card {
        border: none;
        box-shadow: none;
      }
    }
    &-col {
      flex-basis: auto;
      flex-grow: inherit;
      min-width: 100%;
      margin-top: 2em;
      @media screen and (min-width: 900px) {
        min-width: 45%;
        max-width: 45%;
        padding: 2em;
      }
      &:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.125);
        @media screen and (max-width: 900px) {
          border-right: none;
        }
      }
    }
    &-title {
      font-size: 2rem;
      margin-bottom: 1em;
    }
    .nav-tabs {
      border: none;
      padding: 0.5rem;
      border-radius: 1rem;
      background-color: #f8f9fa;
      margin-bottom: 1.5rem;
      .nav-item {
        flex: 1;
      }
      .nav-link {
        text-align: center;
        display: inline-flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-weight: 600;
        color: #000;
        border: none;
        padding: 0.75rem;
        border-radius: 0.75rem;
        font-size: 0.875rem;
        line-height: 1.71429;
        &.active {
          background-color: #b10058 !important;
          color: #fff !important;
        }
      }
    }
  }

  /**
  ----------------------------------------------------------------
  ----------------------------------------------------------------
  **/

  .member-features-item-icon {
    width: 3.5rem;
    height: 3.5rem;
    min-width: 3.5rem;
    min-height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    background-color: $primary;
    color: #fff;
    margin-right: 1rem;
    @media screen and (max-width: 900px) {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
    }
  }
  .member-features-item-icon::before {
    font-size: 1rem;
    @media screen and (max-width: 900px) {
      font-size: $font-size-medium;
    }
  }

  .member-login-signin-tab-link-title {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bold;
    display: contents;
  }
}
