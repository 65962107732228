@import "../../variables.scss";

ctw-member-button{
    margin-left: 1rem;
    background-color: $primary;
    border-radius: 1rem;
    .btn.member-button{
      background-color: $primary;
      border-radius: 1rem;
      padding: 0.9rem 1.5rem;
      border-color: $primary;
        span.optyweb.cw-misc-man2.member-button-icon {
            margin-right: 5px;
        }
    }
  }