.member-change-password-alert {
  align-items: center;
  .alert-title {
    font-weight: bold;
  }
  .cw-alert-info2 {
    margin-right: 0.75rem;
    font-size: 10px;
    &::before {
      color: #fff;
      background-color: #364fc7;
      border-radius: 50px;
      padding: 4px 3px 4px 5px;
    }
  }
  .cw-alert-success2 {
    margin-right: 0.75rem;
    &::before {
      color: #155724;
    }
  }
  .cw-toolbar-close1 {
    margin-right: 0.75rem;
    &::before {
      color: #c92a2a;
    }
  }
}
