@import "../../variables.scss";

.member-card-link-card,
.member-card-link-ticket {
  margin-top: 1.5rem;
  filter: drop-shadow(0 8px 16px rgba(33, 37, 41, 0.08));
  padding: 1em;

  .cw-card,
  .cw-misc-smartphone {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    color: #fff;
    color: #868e96;
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
    background-color: $primary;
    color: #fff;
    margin-right: 1.5em;
    &::before {
      font-size: 2em;
    }
  }
  .member-card-link-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .member-card-link-link {
      font-weight: bold;
    }
    .member-card-link-text {
      margin-bottom: 0;
    }
  }
}

/**********************************************************************************
************************ mTicket acceptation des conditions ***********************
**********************************************************************************/

.card.service-activation-card {
  margin-bottom: 1rem;
  margin-top: 6rem;
  border-radius: $global-border-radius;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  h2 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.7;
  }
  .optyweb {
    background-color: #b10058 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    margin: -5rem auto 1rem;
    font-size: 3rem;
    color: #fff;
    border-radius: 100rem;
  }
  & ~ form .btn-link {
    color: $primary;
    text-decoration: underline;
  }

  & ~ form button.btn-primary {
    border-radius: 1.25rem;
    padding: 1em;
    font-weight: bold;
    background-color: #b10058;
    border: none;
    margin: 1rem auto 4rem;
  }
}

/**********************************************************************************
**************************** Partie titres de transport ***************************
**********************************************************************************/

.my-wallet-page-h1my-tickets-h1,
.my-wallet-page-h1ticket-selection-h1,
.my-wallet-page-h1add-to-cart-h1 {
  font-size: $font-size-title;
}

.my-tickets-container,
.my-wallet-container,
.ticket-selection-container {
  max-width: 600px !important;
  .font-weight-bold {
    margin-top: 1.5rem;
    & + p {
      margin-bottom: 2rem;
    }
  }
  a:not(.btn),
  .ticket-selection-btn {
    color: $primary;
    text-decoration: underline;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
  h2 {
    font-size: $font-size-large;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .cw-mode-bus.mode-rounded {
    padding: 0.35rem;
    font-size: 1.2rem;
    color: #ffffff;
    border: $secondary;
    border-radius: 100rem;
    background-color: $secondary;
    margin-right: 0.5rem;
  }

  // tous les icons mode-rounded
  .mode-rounded {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 2.25rem;
    min-height: 2.25rem;
    padding: .35rem;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    border: $primary;
    border-radius: 100rem;
    background-color: $primary;
  }

  // bouton "?" dans le formulaire d'achat
  .mode-rounded.mode-rounded-small {
    min-width: inherit;
    min-height: inherit;
    padding: .25rem;
    font-size: .875rem;
    background-color: #6a6a6a;
  }
  .mode-rounded.mode-rounded-small:hover {
    background-color: #444;
  }

  .add-to-cart-card{
    margin-top: 4rem;
  }

  .pass-card,
  .ticket-card,
  .ticket-network-card,
  .add-to-cart-card {
    margin-bottom: 1rem;
    border-radius: $global-border-radius;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    .card-body {
      padding: 1rem;
      // span contenant le logo tout en haut au centre du header
      .mode-rounded.mode-rounded-xl {
        padding: .8rem;
        font-size: 2.2rem;
      }
      .add-to-cart-card-icon {
          display: inline-block;
          margin-top: -50%;
          transform: translateY(-32%);
      }
      .bg-mobility-blue {
          background-color: $primary;
      }
      .pass-card-name,
      .ticket-card-name,
      .add-to-cart-card-name {
        font-weight: bold;
        margin-bottom: 1rem;
        button {
          padding: 0;
          font-weight: bold;
        }
      }
      .pass-card-info {
        font-size: $font-size-line-number;
      }
      .ticket-network-card-info button {
        font-weight: bold;
      }
      .pass-card-date {
        font-size: $font-size-medium;
        .item-location-label {
          margin-right: calc(1rem * 0.5);
          font-size: $font-size-medium;
          color: $text-color;
        }
      }
      .amount {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-left: 1rem;
        padding-left: 1rem;
        white-space: nowrap;
        border-left: 0.063rem solid #f9f9f9;
        .amount-title {
          font-size: $font-size-line-number;
          color: $text-color;
          margin-bottom: 0.3rem;
        }
        .amount-content {
          display: inline-flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0.2rem 0.7rem;
          color: #ffffff;
          border-radius: 100rem;
          background-color: $secondary;
        }
      }
      .ticket-network-card-logo {
        width: 6rem;
        margin-bottom: 0.25rem;
      }
      .add-to-cart-card-logo {
        max-width: 5rem;
        margin-bottom: 0.25rem;
      }
      .add-to-cart-card-network {
        font-size: 0.75rem;
        color: $text-color;
      }
      .add-to-cart-card-dates {
        margin: 1rem 0;
        padding: 0.5rem 1rem;
        border-radius: $global-border-radius;
        background-color: $accent;
        .item-location {
          .item-location-label {
            margin-right: calc(1rem * 0.5);
            font-size: $font-size-medium;
            color: $text-color;
            font-weight: bold;
          }
          .item-location-content {
            font-size: $font-size-medium;
          }
        }
      }
      .add-to-cart-card-description {
        font-size: $font-size-locality;
      }
      .add-to-cart-card-validity {
        padding: 0.2rem 0.7rem;
        color: $red;
        font-size: $font-size-locality;
        font-weight: bold;
        border-radius: 100rem;
        background-color: $accent;
      }
    }
    .add-to-cart-card-price {
      border-radius: 0 0 $global-border-radius $global-border-radius;
      color: #ffffff;
      font-size: $font-size-large;
      font-weight: bold;
      background-color: $secondary;
    }
    .pass-card-info.d-flex,
    .ticket-card-info.d-flex {
      margin-top: 0.5rem;
      .add-to-cart-card-logo,
      .ticket-card-info-network-logo {
        max-width: 6rem;
        margin-bottom: 0.25rem;
      }
      .ticket-card-info-network {
        font-size: $font-size-line-number;
        color: $text-color;
      }
      .pass-card-info-hour,
      .ticket-card-info-hour {
        padding: 0.2rem 0.7rem;
        font-size: $font-size-line-number;
        color: $red;
        border-radius: 100rem;
        background-color: #f9f9f9;
      }
    }
  }

  .numerical-range {
    margin-top: 1.5rem;
    .numerical-range-container {
      padding: 0.25rem;
      border-radius: 100rem;
      background-color: #ffffff;
      box-shadow: 0 0 0.4375rem 0 rgba(0, 0, 0, 0.2);
      .numerical-range-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        color: #ffffff;
        font-size: 1.625rem;
        font-weight: bold;
        line-height: 1;
        background-color: #6a6a6a;
        border-radius: 100rem;
        border: none;
        &.numerical-range-more-btn {
          background-color: $primary;
        }
      }
      .numerical-range-amount {
        min-width: 8rem;
        text-align: center;
        font-weight: bold;
      }
    }
  }

  .total-amount {
    width: calc(100% + 30px);
    margin: 1rem -15px;
    padding: calc(30px / 2);
    border-radius: $global-border-radius;
    background-color: #f9f9f9;
    .total-amount-label {
      font-weight: bold;
    }
    .amount-content {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.2rem 0.7rem;
      color: #ffffff;
      border-radius: 100rem;
      background-color: $secondary;
      font-weight: bold;
    }
    .add-to-cart-total-price-btn {
      border-radius: $global-border-radius;
      padding: 1em;
      font-weight: bold;
      background-color: $primary;
      border: none;
      margin: 1rem auto 1rem;
      &:hover {
        background-color: $primary;
      }
    }
  }

  .add-to-cart-btn {
    color: $primary;
    text-decoration: underline;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .my-tickets-btn {
    border-radius: $global-border-radius;
    padding: 1em;
    font-weight: bold;
    background-color: $primary;
    border: none;
    margin: 1rem auto 1rem;
    &:hover {
      background-color: $primary;
    }
  }

  .see-cart-container {
    .see-cart-btn {
      border-radius: 1.25rem;
      padding: 1em;
      font-weight: bold;
      color: #fff;
      background-color: $primary;
      border: none;
      margin: 1rem auto 1rem;
      .cw-misc-basket {
        margin-left: 1rem;
        font-size: $font-size-large;
      }
    }
  }

  .ticket-type-card {
    border-radius: $global-border-radius;
    border-radius: 0.9375rem;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    .card-body {
      padding: 1rem;
      .stretched-link {
        padding: 0 0.75rem;
        font-weight: bold;
      }
    }
    .purchase-card-service {
      padding: 0 0.75rem;
    }
    .ticket-type-card-icon .mode-rounded {
      padding: 0.35rem;
      font-size: 1.2rem;
      color: #ffffff;
      border: $secondary;
      border-radius: 100rem;
      background-color: $secondary;
    }

    .amount {
      margin-left: 1rem;
      padding-left: 1rem;
      .amount-content {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.2rem 0.7rem;
        color: #ffffff;
        border-radius: 100rem;
        background-color: $secondary;
        font-weight: bold;
      }
    }
  }
  .alert-info {
    align-items: center;
    .cw-alert-info2 {
      margin-right: 0.75rem;
      font-size: $font-size-large-icon; // 1.75rem
      &::before {
        color: #fff;
        background-color: #364fc7;
        border-radius: 50px;
        padding: 4px 3px 4px 5px;
      }
    }
  }

  .my-tickets-no-result {
    text-align: center;
    .my-tickets-no-result-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100% !important;
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      min-height: 4rem;
      background-color: $primary;
      font-size: 2rem;
      color: #fff;
      margin: auto;
    }
    .my-tickets-no-result-title {
      margin: 1rem 0;
      font-weight: 700;
    }
  }
}

// Le header dans la page "Acheter des titres" (Premier card)
.container.my-wallet-container.ticket-selection-container > .ticket-network-card{
  box-shadow: none;
  background-color: unset;
  border: none;
  align-items: center;
  justify-content: center;
  
  & > .card-body .ticket-network-card-info{
    display: none;
  }
}

// Le dropdown button dans Mes titres de transport
.pass-card{
  .dropdown {
    button#optionTripMenu {
      border-radius: 10px;
      padding: 3px;
    }
    button#optionTripMenu:active{
      background-color: $primary-contrast;
    }
    button#optionTripMenu:focus{
      background-color: $primary-contrast;
    }
  }
  .dropdown.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color:$primary;
    border-color: $primary;
  }
  .dropdown.show{
    .dropdown-menu.dropdown-menu-right {
      width: 11rem;
      padding: 0rem;
      filter: drop-shadow(0 8px 16px rgba(33, 37, 41, 0.08));
      text-align: center;
      border-radius: 0.25rem;
    }
  }
}