/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2IxMDE1YyIsIj9lcjwjZThiM2NlIiwiO2VyPCM5ODAxNDF$LCIlPmBePCNkZmRmZGYiLCI~ZXI8I2Y1ZjVmNSIsIjtlcjwjZDJkMmQyfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjExfQ==
*/
@use "@angular/material" as mat;
@import "../../variables.scss";
@import "../../mixins.scss";
@import "@angular/material/theming";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, "Roboto", -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, "Roboto", -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, "Roboto", 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, "Roboto", 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, "Roboto", 0em),
  title: mat.define-typography-level(20px, 32px, 500, "Roboto", 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, "Roboto", 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, "Roboto", 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, "Roboto", 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, "Roboto", 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, "Roboto", 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, "Roboto", 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, "Roboto", 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

mat.$light-theme-foreground-palette: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

mat.$dark-theme-foreground-palette: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

mat.$light-theme-background-palette: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
mat.$dark-theme-background-palette: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config

body {
  --primary-color: #b1015c;
  --primary-lighter-color: #e8b3ce;
  --primary-darker-color: #980141;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #b1015c,
  lighter: #e8b3ce,
  darker: #980141,
  200: #b1015c,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #dfdfdf;
  --accent-lighter-color: #f5f5f5;
  --accent-darker-color: #d2d2d2;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}
$mat-accent: (
  main: #dfdfdf,
  lighter: #f5f5f5,
  darker: #d2d2d2,
  200: #dfdfdf,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
  .mat-badge-content {
    font-family: "Roboto";
  }
}

.card {
  border-radius: $global-border-radius !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
}

.alert-warning {
  align-items: center;
  .cw-event-warning {
    margin-right: 0.75rem;
  }
}

.alert-info {
  align-items: center;
  .cw-alert-info2 {
    margin-right: 0.75rem;
    font-size: 10px;
    &::before {
      color: #fff;
      background-color: #364fc7;
      border-radius: 50px;
      padding: 4px 3px 4px 5px;
    }
  }
}

.btn-primary:not([disabled]) {
  &:hover {
    color: #fff;
    background-color: $primary-contrast !important;
    border-color: $primary-contrast !important;
  }
}
.btn-primary:disabled {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $primary-contrast !important;
  border-color: $primary-contrast !important;
}

.btn-primary:focus {
  background-color: $primary-contrast !important;
  border-color: $primary-contrast !important;
  box-shadow: 0 0 0 0.2rem rgba(177, 0, 88, 0.44) !important;
}

button:focus {
  box-shadow: none !important;
  border-color: none !important;
}

.iti {
  position: relative;
  display: inline-block;
}

.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input,
.iti input[type="text"],
.iti input[type="tel"] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
  padding-left: 4rem;
}

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px #0003;
  background-color: #fff;
  border: 1px solid #ccc;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.modal-dialog,
.modal {
  .modal-header {
    margin-bottom: -10px !important;
    .close {
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.form-control {
  border-radius: 100rem !important;
}

.custom-control.custom-switch-right {
  .custom-control-label {
    margin: 0 0 1rem 0 !important;
    &::before {
      left: auto;
      right: 0;
      top: 0.8rem;
    }
    &::after {
      left: auto;
      right: 0;
      top: calc(2px + 0.8rem);
    }
  }
}

.form-control:focus,
:focus {
  //outline: none !important;
}

.btn-link:focus-visible {
  outline: 0.125rem solid #646464 !important;
  border-radius: 1rem;
  outline-offset: 0.188rem;
}

:focus-visible {
  outline: 0.125rem solid #646464;
  border-radius: 1rem;
  outline-offset: 0.188rem;
}

select.form-control {
  padding: 0.375rem 1.75rem;
}

.member-password-policy-items {
  .member-password-policy-item-icon,
  .member-password-policy-item-text {
    &:not(.cw-alert-success2) {
      color: #dc3545;
    }
    color: green;
  }
  .cw-alert-success2.member-password-policy-item-icon
    ~ .member-password-policy-item-text {
    color: green;
  }

  li {
    &:first-child {
      margin-bottom: 3px;
    }
    .member-password-policy-item-icon {
      margin-right: 5px;
    }
  }
}

.ngb-toasts {
  top: 77px !important;
  .toast {
    margin: 1rem;
    color: #333;
    border: 0;
    border-radius: $global-border-radius;
    padding: 1rem;
    box-shadow: none;
    max-width: 100%;
    width: 600px;
    &.bg-success {
      background-color: #e3feef !important;
      .optyweb {
        color: #fff;
        background-color: $green !important;
      }
    }
    &.bg-danger {
      background-color: #feeae4 !important;
    }
    .toast-body {
      .align-items-start {
        align-items: center !important;
        .d-flex {
          align-items: center;
          flex-direction: row;
        }
      }
      .toast-title {
        font-weight: bold;
      }
      .close {
        color: #333;
      }
    }
    @include media-max(768px) {
      max-width: 20rem;
    }
  }
}

.atos-main-app a, .atos-main-app .btn-link{
  color: $primary;
  font-weight: bold;
}

.atos-main-app a:hover, .atos-main-app .btn-link:hover{
  color: $primary;
  text-decoration: underline;
}

// Important because it overrides the general style
.atos-main-app .btn-primary, .atos-main-app .btn-secondary {
  background-color: $primary;
  border-color: $primary;
  color: white;
}

.atos-main-app .btn-primary:hover, .atos-main-app .btn-secondary:hover {
  background-color: $primary-contrast;
  border-color: $primary-contrast;
  color: white;
}

// Completion panel
.autocompletion-panel .autocompletion-items {
  position: relative;
}
.autocompletion-panel .autocompletion-item-reset {
  position: absolute;
  top: 0;
  right: 0;
  margin: .35rem .5rem;
  font-size: .875rem;
  color: $primary;
  text-decoration: underline;
}
.autocompletion-panel .autocompletion-item-reset:hover, .cityway-v8 .autocompletion-panel .autocompletion-item-reset:active {
  color: $primary;
  text-decoration: none;
  cursor: pointer;
}

// La panel à gauche de l'ecran sur la map
.panel-content{
  margin-bottom: 15rem !important;
}