@import "../../variables.scss";

.disrupt-major {
  font-family: $font;

  .list-inline-item:not(:last-child) {
    margin-right: 0.1rem !important;
  }

  .alert {
    padding: 0 !important;
  }
  .list-major-disruption-item-alert-title {
    margin: auto;
    text-align: center;
    color: white;
    max-width: 100%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.15rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word; /* Breaks long unbroken text lines */
    overflow-wrap: anywhere; /* Ensures wrapping even without spaces */
  }
  .list-major-disruption {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.2rem;
  }
  .list-major-disruption-item {
    width: 100%;
    flex: 0 1 calc(33.33% - 1rem);
    box-sizing: border-box;
    overflow: hidden;
    flex-grow: 1;
  }
  .list-major-disruption-item-alert {
    border-radius: 0em !important;
    display: flex;
    justify-content: center;
    background-color: $background-color-alert;
    border-color: $background-color-alert;
    height: 66px;
    margin-bottom: 0 !important;
  }
  .list-major-disruption-item-alert-link {
    color: white;
    width: 80%;
  }
  .list-major-disruption-item-alert-link:hover {
    color: white;
    text-decoration: underline;
  }
  .close {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    position: absolute;
    width: 26px;
    height: 26px;
    right: 5px !important;
    top: 20px !important;
    background: #eceff1;
    border-radius: 1em;
    opacity: 0.5;
  }
}
