@import "../../variables.scss";

.ctw-disrupt {
  font-family: $font;
  margin: 2rem 3rem;

  .member-disruption-no-disruption-icon:before {
    font-size: 2rem;
  }
  .member-disruption-no-disruption-content {
    text-align: center;
  }

  .member-disruption-no-disruption-icon {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    color: #fff;
    color: #868e96;
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
    background-color: #71b852;
    color: #fff;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .mb-0 {
    margin-top: 1em;
    justify-content: inherit !important;
  }
  .disruption-h1 {
    margin-bottom: 1em;
  }

  h2 {
    margin: 1em 0em;
  }
  .card-header {
    margin: 0 !important;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: none;
    font-size: $font-size-title;
  }
  h2 {
    font-size: $font-size-title;
  }
  .card-disruption-item-title {
    font-size: $font-size-title;
  }
  .card-disruption-detail {
    //margin: 0px;
    .card {
      box-shadow: none;
    }
    .collapse-disruption-detail-content .card-body {
      display: flex;
      align-items: center;
      background-color: $accent;
      margin: 2px;
      border-radius: $global-border-radius;

      .disruption-detail-date {
        font-size: $font-size-content;
      }
      .card-disruption-detail-type {
        font-size: $font-size-content;
      }
      .card-disruption-detail-description {
        font-size: $font-size-content;
      }
      .disruption-detail-item-line {
        justify-content: center;
        width: 20%;
      }
      .disruption-detail-line {
        width: 80%;
        margin-left: 2rem;
        font-size: $font-size-content;
      }
      .disruption-detail-line-direction {
        font-weight: $font-weight-bold;
      }
    }
    .collapse-disruption-detail-btn {
      text-transform: uppercase;
      font-size: $font-size-content;
    }
  }

  .nav-tabs {
    border-bottom: none;
  }
  .card-disruption-item-date {
    font-size: $font-size-content;
  }
  .disruption-h1 {
    font-weight: $font-weight-bold;
  }
  .disruption-detail-title {
    font-size: $font-size-title;
  }
  .disruption-by-line-item-line {
    display: flex;
    justify-content: center;
  }
  .disruption-by-line-title {
    font-size: $font-size-title;
    font-weight: $font-weight-bold;
    line-height: 1.33333;
    text-align: center;
    margin: 1rem 0;

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .disruption-by-line-title::after {
    content: "";
    display: block;
    width: 6.875rem;
    height: 0.25rem;
    margin: 0.3rem auto;
    border-radius: 0.125rem;
    background-color: $primary;
  }
  .disruption-detail-item-line {
    display: flex;
  }
  .item-line {
    border-radius: $global-border-radius;
    height: 30px;
    position: relative;
  }

  .item-line-number {
    font-size: $font-size-line-number;
    line-height: 1.33333;
    font-weight: $font-weight-bold;
    min-width: 2rem;
    padding: 0.125rem 0.5rem;
    margin-left: 0.2rem;
    border-radius: $global-border-radius;
    background-color: #fff;
    text-align: center;
  }

  .item-line-disruption-icon {
    background-color: $primary;
    filter: drop-shadow(0 8px 16px rgba(33, 37, 41, 0.08));
    border-radius: 100%;
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    top: -0.625rem;
    right: -0.5rem;
  }
  .item-line-disruption-icon:before {
    font-size: 0.75rem;
    color: #fff;
  }

  .optiweb {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .collapse-list-disruption-mode-item-btn {
    line-height: 2;
    color: $text-color;
    font-weight: $font-weight-bold;
    border-radius: $global-border-radius;
    text-transform: uppercase;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .nav-tabs .nav-item .nav-link.active {
    filter: $global-shadow;
    border-style: solid;
    border-color: $primary;
  }
  .nav-tabs .nav-item .nav-link {
    text-align: center;
    font-size: $font-size-content !important;
    display: inline-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: $font-weight-bold;
    color: #212529;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: $global-border-radius;
    font-size: $font-size-medium;
    line-height: 1.71429;
  }
  .disruption-by-line-network {
    text-align: center;
    font-size: $font-size-content;
  }
  .disruption-row {
    display: flex;
    justify-content: center;
  }
  .alert {
    font-size: $font-size-content;
    margin: 20px 0 20px 0;
    border-radius: $global-border-radius;
  }
  .alert-text {
    font-size: $font-size-content;
  }
  .alert-icon {
    padding: 5px;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 3rem;
  }

  // la card à gauche
  .member-disruption-card{
    // Les logos et les texts
    .member-home-add-btn-icon, .member-home-add-btn-content:hover,
    .member-disruption-card-btn-icon, .member-disruption-card-btn-content:hover{
      color : $primary;
    }
  }

  // Les logos des transpors
  .card-body{
    .list-inline-item.list-line-item{
      margin-bottom: 1rem;
    }
  }

  // La liste des autres infos 
  .card-disruption-item{
    margin-bottom: 1rem;
  }
}
