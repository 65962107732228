@import "../../variables.scss";

ctw-eshop{
    // partie "Mes coordonnées bancaires"
    .file-to-download-link {
        padding: .75rem 1rem;
        border-radius: .9375rem;
        background-color: #fff;
        box-shadow: 0 0 .4375rem #0003;
        color: $primary;
        text-decoration: underline;
    }
    .file-to-download-link:hover {
        color: $primary;
    }
    .file-to-download-name {
        flex: 1;
    }
    // tous les icons mode-rounded
    .mode-rounded {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 2.25rem;
    min-height: 2.25rem;
    padding: .35rem;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    border: $primary;
    border-radius: 100rem;
    background-color: $primary;
    }
    // bouton "?" dans le formulaire d'achat
    .mode-rounded.mode-rounded-small {
    min-width: inherit;
    min-height: inherit;
    padding: .25rem;
    font-size: .875rem;
    background-color: #6a6a6a;
    }
    .mode-rounded.mode-rounded-small:hover {
    background-color: #444;
    }

    // Page principale eshop
    .es-card-card-badge {
        margin-right: 4rem;
    }

    // le formulaire à remplir avec les infos personnelles pour la souscription
    // checkbox pour Fauteuil roulant
    .custom-control.custom-switch.custom-switch-right .custom-control-label {
        margin-left: 0.5rem !important; // important because already defined in thems.scss
    }
    .custom-control.custom-switch.custom-switch-right .custom-control-label::before {
        background-color: #6a6a6a;
        left: -2.5rem;
        width: 2rem;
        pointer-events: all;
        border-radius: 100rem;
    }
    .custom-control-label::before {
        position: absolute;
        display: block;
        height: 1rem;
        content: "";
        border: 0.063rem solid #646464;
        box-shadow: 0 0 0.438rem #0000001a;
    }
    .custom-control.custom-switch.custom-switch-right .custom-control-label::after {
        left: -2.4rem;
        width: 1rem;
        height: 0.748rem;
        border-radius: 100rem;
        border-color: #fff;
        background-color: #fff;
        transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .custom-control-label:after {
        position: absolute;
        display: block;
        content: "";
        background: 50%/50% 50% no-repeat;
    }
}