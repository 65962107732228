@use "@angular/material" as mat;

@import "@angular/material/theming";

@include mat.core();

@import "./assets/theme/theme.scss";

@import "./styles-ctw.scss";

@import "variables.scss";

@font-face {
  font-family: "Red-Hat-Display";
  src: local("red-hat-display"),
    url(./assets/fonts/red-hat-display/RedHatDisplay-Regular.ttf)
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon/icomoon.eot?wjlf5p");
  src: url("./assets/fonts/icomoon/icomoon.eot?wjlf5p#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon/icomoon.ttf?wjlf5p") format("truetype"),
    url("./assets/fonts/icomoon/icomoon.woff?wjlf5p") format("woff"),
    url("./assets/fonts/icomoon/icomoon.svg?wjlf5p#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "optyweb";
  src: url("./assets/fonts/optyweb/optyweb.woff") format("woff"),
    url("./assets/fonts/optyweb/optyweb.woff2") format("woff2");
  // chemins en absolus
  font-weight: bold;
  font-style: normal;
}

/**
@font-face {
  font-family: "icomoon";
  src: local("icomoon"),
    url(./assets/fonts/icomoon/icomoon.eot?82nfi8#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/icomoon/icomoon.ttf?82nfi8) format("truetype"),
    url(./assets/fonts/icomoon/icomoon.woff?82nfi8) format("woff"),
    url(./assets/fonts/icomoon/icomoon.svg?82nfi8#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
**/

body,
h1,
h2,
h3,
h4 {
  font-family: $font !important;
}

.mat-menu-panel {
  max-width: 350px !important;
  &.cssMrn {
    min-width: 250px;
    max-width: 350px;
    margin-top: 10px;
    color: $primary;
    border-radius: $global-border-radius !important;
    padding: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1) !important;
  }
  &.cssMrnLink {
    font-family: $font;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: black;
  }
  .mat-menu-content {
    .mat-menu-item {
      display: flex;
      &:focus-visible {
        outline: 0.125rem solid #646464 !important;
      }
      label {
        margin-top: 10px;
        margin-right: 10px;
      }
    }
  }
}

.mat-menu-item.cssMrnSubMenu {
  font-family: $font;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
  &:hover:not([disabled]) {
    background-color: #f8f9fa !important;
    border-radius: $global-border-radius;
  }
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  color: $primary;
  background: none !important;
  .mat-icon-menu {
    color: $primary;
  }
}
