@import "../../variables.scss";
@import "../../mixins.scss";

.map-common .panel-search {
  left: 0;
  right: 0;
  z-index: 1101;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  width: 456px;
  margin: 0.9375rem 3.7375rem 0.9375rem 0.9375rem;
  @include media-max(768px) {
    width: auto;
  }
  .autocompletion-form-group {
    .form-control {
      border: 3px solid $primary;
      border-radius: 100rem;
      height: $size-round-button;
      padding: 0.375rem 0.75rem;
    }
    .jp-search-form-control {
      background-repeat: no-repeat;
      background-position: left 0.9rem center;
      background-size: 1.475rem 1.475rem;
      padding-left: 2.6875rem;

      &.jp-search-form-control-departure {
        background-image: url(../site/pin-departure.svg);
      }
      &.jp-search-form-control-arrival {
        background-image: url(../site/pin-arrival.svg);
      }
    }
    .form-control-btn-delete {
      top: -0.1rem;
      position: absolute;
      right: 0;
      margin: 0.69rem;
      padding: 0;
      font-size: 1.375rem;
      border: 0;
      span:not(.sr-only) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100rem;
      }
      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }
  }
  .justify-content-between {
    button:not(.dropdown-item) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: #1c1c28;
      border: 0;
      border-radius: 2rem;
      background-color: #fff;
      box-shadow: 0 0 0.4375rem #0003;
      min-height: $size-round-button;
      min-width: $size-round-button;
      margin: 0 0 0.4375rem 0.6375rem;
    }
    .jp-search-options-dropdown {
      .dropdown-toggle {
        &::after {
          display: none;
        }
      }
    }
    .jp-search-return-jp-btn {
    }
  }

  /**********************************************************************************
  ******************************** Autocomplete panel *******************************
  **********************************************************************************/

  .autocompletion-panel {
    margin-top: 0;
    max-height: 50vh;
    border-radius: $global-border-radius;
    border: none;
    position: absolute;
    z-index: 1100;
    width: 100%;
    background-color: #fff;
    overflow: auto;
    ul:last-child:not(:only-of-type) {
      margin-bottom: 1rem;
    }
    .autocompletion-item-title {
      font-size: 1.125rem;
      margin: 0.5rem 1.5rem;
    }
    .autocompletion-panel-position,
    .member-home-work-item {
      width: 100%;
      margin-bottom: 0.1rem;
      padding: 1rem;
      text-align: left;
      border-radius: 0;
      background-color: #f9f9f9;
      display: flex;
      align-items: center;
      font-size: $font-size-medium;
      cursor: pointer;
      .optyweb {
        margin-right: 1rem;
        font-size: 1.25rem;
      }
      &:hover {
        background-color: #f4f4f4;
      }
    }
    .autocompletion-items {
      .autocompletion-item-subitem {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: $font-size-medium;
        border-radius: 0;
        cursor: pointer;
        background-color: #fff;
        padding: 0.5rem 1.5rem;
        margin: 0;
        &:not(:last-child) {
          border-bottom: 0.0625rem solid #f9f9f9;
        }
        .autocompletion-item-subitem-icon {
          color: #fff;
          padding: 0.5rem;
          font-size: 1.25rem;
          border-radius: 100rem;
          background-color: $secondary;
        }
        .autocompletion-item-subitem-infos {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 0 1rem;
          padding: 0;
          background-color: #fff;
          .autocompletion-item-subitem-infos-additional {
            font-size: 0.75rem;
            color: #6a6a6a;
          }
        }
      }
    }
  }
}
