@import "../../variables.scss";
@import "../../mixins.scss";

.eshop-page-header {
  margin-bottom: 2rem;
  h1 {
    font-size: $font-size-title;
    @include media-max(768px) {
      margin-bottom: 3rem;
      margin-top: 2rem;
    }
  }
  .basket-btn-component {
    .basket-btn {
      border-radius: $global-border-radius;
      padding: 1em;
      font-weight: bold;
      color: #fff;
      background-color: $primary;
      border: none;
      &:hover:not([disabled]) {
        background-color: $primary-contrast;
      }
      .basket-btn-nb-item {
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: $font-size-medium;
        background-color: $secondary;
        width: 26px;
        height: 26px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include media-max(990px) {
        border-radius: 100px;
        min-width: 3.125rem;
        height: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.breadcrumb-step {
  max-width: 60%;
  margin: 0 auto 2rem auto;
  @include media-max(768px) {
    max-width: 100%;
  }
}

.order-card-container {
  h2 {
    &.card-header {
      border-radius: $global-border-radius $global-border-radius 0 0;
    }
  }
}

.eshop-container {
  margin-bottom: 4rem;
  h2 {
    font-size: $font-size-large;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .card {
    margin-bottom: 1rem;
    border-radius: $global-border-radius;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    &.es-card-card {
      overflow: hidden;
      .btn-primary {
        font-weight: bold;
        padding: 0.75em;
        color: #ffffff;
        background-color: $primary;
        border-color: $primary;
        border-radius: $global-border-radius;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
          0 1px 1px rgba(0, 0, 0, 0.075);
      }
      .es-card-card-btn {
        margin: 0.5rem 0;
      }
      .btn-link {
        color: $primary;
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 1em;
        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }
      .es-card-card-logo {
        max-width: 6.25rem;
        margin-bottom: 0.5rem;
      }
      .es-card-card-name {
        font-weight: bold;
      }
      .es-card-card-number {
        font-size: $font-size-locality;
      }
      .es-card-card-duplicata {
        margin: 0.5rem;
        font-size: $font-size-medium;
        color: $text-color;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 4rem;
        height: 100%;
        background: linear-gradient(
          360deg,
          $primary 0%,
          $primary 33%,
          rgba(225, 0, 26, 0.65) 33%,
          rgba(225, 0, 26, 0.65) 66%,
          #f9f9f9 66%,
          #f9f9f9 100%
        );
      }
    }
    &.es-holder-card {
      margin-top: 3rem;
      .es-holder-card-header {
        margin-top: -3rem;
        .es-holder-card-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 4rem;
          height: 4rem;
          font-size: 2.2rem;
          color: #ffffff;
          border-radius: 100rem;
          background-color: $secondary;
        }
        .es-holder-card-name {
          margin-top: 0.5rem;
          margin-bottom: 1.5rem;
          font-size: $font-size-large;
          font-weight: bold;
        }
      }
      .es-holder-card-nb-card {
        margin-bottom: 1rem;
        font-weight: bold;
      }
      .offers-on-card-card {
        .card-body div {
          &:first-child {
            font-weight: bold;
          }
        }
      }
    }
    &.es-offer-card {
      .flex-grow-1 {
        .es-offer-card-name {
          font-size: $font-size-content;
          font-weight: bold;
        }
        .es-offer-card-network {
          font-size: $font-size-medium;
        }
      }
      .es-offer-card-price {
        padding: 0.2rem 0.5rem;
        font-size: $font-size-medium;
        color: #fff;
        font-weight: bold;
        white-space: nowrap;
        border-radius: 100rem;
        background-color: $secondary;
      }
      .es-offer-card-description {
        margin-bottom: 1rem;
      }
      .es-offer-card-payment-title {
        font-size: $font-size-medium;
        font-weight: bold;
      }
      .es-offer-card-payment-content {
        font-size: $font-size-medium;
      }
      .btn-primary {
        margin: 0;
      }
    }
    .card-body {
      padding: 1rem;
      .alert .alert-title{
        font-weight: $font-weight-bold;
        color: black;
      }
    }
    .es-button-card-label {
      padding: 0;
      font-weight: bold;
    }
  }
  .btn-primary {
    border-radius: $global-border-radius;
    padding: 1em;
    font-weight: bold;
    background-color: $primary;
    border: none;
    margin: 1rem auto 1rem;
    color: #fff;
    &:disabled {
      background-color: grey;
    }
  }
  .btn-link {
    color: $primary;
    text-decoration: underline;
    &:not(.p-0) {
      margin-bottom: 1em;
    }
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}
