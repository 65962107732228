@import "../../variables.scss";

.card-body {
  .member-card-link.member-card-link-consumption {
    margin-top: 2em;
    margin-bottom: 0;
  }
}

.member-card-link-consumption {
  margin-bottom: 2em;
}
.card {
  &.member-card-link-consumption {
    margin-top: 2em;
    filter: drop-shadow(0 8px 16px rgba(33, 37, 41, 0.08));
    padding: 1em;

    .cw-regularize-payment {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100% !important;
      color: #fff;
      color: #868e96;
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      min-height: 4rem;
      background-color: $primary;
      color: #fff;
      margin-right: 1.5em;
      &::before {
        font-size: 2em;
      }
    }
    .member-card-link-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .member-card-link-link {
        font-weight: bold;
      }
      .member-card-link-text {
        margin-bottom: 0;
      }
    }
  }
}

.my-wallet-page-header {
  margin: 20px;
  .my-wallet-page-h1credit-card-h1 {
    font-size: $font-size-title !important;
  }
}
.card-body {
  .cw-payement {
    margin-right: 1.5rem;
  }
}
.cw-payement,
.cw-sale-price-euro {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100% !important;
  color: #fff;
  color: #868e96;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  background-color: $primary;
  color: #fff;
  margin: auto;
  &::before {
    font-size: 2em;
  }
}

.credit-card-container {
  .credit-card-btn {
    background-color: $primary;
    border-radius: $global-border-radius;
    border: none;
    padding: 1em;
    font-weight: bold;
    &:hover {
      background-color: $primary;
    }
  }
}
