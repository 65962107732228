@import "../../variables.scss";

.my-wallet-page-header {
  margin: 20px;
  .my-wallet-page-h1monitoring-type-h1 {
    font-size: $font-size-title !important;
  }
}

.my-wallet-page-h1monitoring-invoice-h1,
.my-wallet-page-h1banking-details-h1 {
  font-size: $font-size-title;
}

.my-wallet-container {
  max-width: 600px !important;
  margin: 0 auto 80px auto;
  h2 {
    font-size: $font-size-large;
    margin-top: 40px;
  }
  li:not(.list-inline-item) {
    margin-bottom: 1.5rem;
  }
  &.banking-details-container {
    .card {
      border-radius: $global-border-radius;
      border-radius: 0.9375rem;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
      .card-body {
        padding: 1rem;
        .stretched-link {
          padding: 0 0.75rem;
          font-weight: bold;
        }
      }
      .btn-link {
        color: $primary;
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 1em;
        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }
    }
  }
  .service-card,
  .purchase-card {
    border-radius: $global-border-radius;
    border-radius: 0.9375rem;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    .card-body {
      padding: 1rem;
      .stretched-link {
        padding: 0 0.75rem;
        font-weight: bold;
      }
    }
    .purchase-card-service {
      padding: 0 0.75rem;
    }
  }

  .purchase-card {
    .card-body {
      .stretched-link {
        font-weight: normal;
      }
    }
  }

  .service-card-icon.mode-rounded {
    padding: 0.35rem;
    font-size: 1.2rem;
    color: #ffffff;
    border: $secondary;
    border-radius: 100rem;
    background-color: $secondary;
    margin-right: 1rem;
  }

  .service-card-amount,
  .purchase-card-amount {
    margin-left: 1rem;
    padding-left: 1rem;
    .amount-content {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.2rem 0.7rem;
      color: #ffffff;
      border-radius: 100rem;
      background-color: $secondary;
      font-weight: bold;
    }
  }
}

.monitoring-purchase-container.my-wallet-container {
  & > div:not(.text-center.btn-container) {
    border-radius: $global-border-radius;
    border-radius: 0.9375rem;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    a {
      text-align: left;
    }
    .card-body {
      padding: 1rem;
      .stretched-link {
        padding: 0 0.75rem;
        font-weight: bold;
      }
    }
    .purchase-card-service {
      padding: 0 0.75rem;
    }
  }
}

.monitoring-purchase-container.my-wallet-container {
  .purchase-card-title.font-weight-bold {
    margin-top: 0;
  }
}

.monitoring-purchase-container.my-wallet-container{
  .custom-switch.custom-switch-right
  .custom-control-input
  ~ .custom-control-label:after {
  right: 0.875rem;
}

  .btn-primary {
    font-weight: bold;
    padding: 0.75em;
    color: #ffffff;
    background-color: $primary;
    border-color: $primary;
    border-radius: $global-border-radius;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
      0 1px 1px rgba(0, 0, 0, 0.075);
  }
  .btn-link {
    color: $primary;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 1em;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.monitoring-type-container.my-wallet-container{
  button.btn-link{
    color : $primary;
  }
  .text-center.btn-container > a{
    color : $primary;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 1em;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

ngb-datepicker {
  .ngb-dp-navigation-chevron {
    color: $primary;
  }

  .ngb-dp-weekday{
    color: $primary-contrast;
  }


  .ngb-dp-day > span {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .ngb-dp-day:has(.range){
    background-color:  $primary;
    color:white;
    
  }
  
  .ngb-dp-day:has(.faded){
    background-color: $primary;
    opacity: 0.6;
    
  }
  
  .ngb-dp-day:has(.focus) {
    background-color:  $primary;
    color:white;
  }

  .ngb-dp-day:has(.range-custom-day):hover {
    background-color:  $primary;
    color:white;
  }

}

// Style pour ma carte
.credit-card-container{
  .card-body{
    // Partie carte
    .credit-card-my-card{
      width: 100%;
      max-width: 350px;
      min-height: 200px;
      margin: 0 auto 1.5rem;
      padding: 1.5rem;
      color: #fff;
      text-shadow: 1px 1px 2px black;
      border-radius: 0.5rem;
      background-color: #0b4f6c;
      background-image: linear-gradient(to bottom, #10719a 0%, #062d3e 100%);
      background-repeat: repeat-x;
    }
    // Partie pour les boutons
    .btn-container{
      display: flex;
      justify-content: center;
      flex-direction: column;
      // all buttons
      .btn {
        margin: 0 auto 1rem;
      }
      // delete button  
      .btn-secondary {
        background-color: #fff;
        border-color: #b10058;
        box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
        color: #b10058;
        border: 2px solid;
      }
      .btn-secondary:hover {
        color: #212529;
        background-color: #ececec;
        border-color: #b10058;
      }
    }
  }
}

// Partie formulaire dans mes titres
ctw-mobility-account{
  // la div qui contient toute la page
  .container{
    margin-bottom: 50px; // espace en bas de la page pour eloigner le bouton du footer
    .btn-primary{
        background-color: $primary;
    }
    // le formulaire à remplir avec les infos personnelles pour la souscription
    .card{
        // checkbox pour Fauteuil roulant
        .custom-control.custom-switch.custom-switch-right .custom-control-label {
            margin-left: 0.5rem !important; // important because already defined in thems.scss
            margin-right: 0.5rem !important; // important because already defined in thems.scss
        }
        .custom-switch .custom-control-label::before {
            background-color: #6a6a6a;
            left: -2.5rem;
            width: 2rem;
            pointer-events: all;
            border-radius: 100rem;
        }
        .custom-control-label::before {
            position: absolute;
            top: 0.25rem;
            display: block;
            height: 1rem;
            content: "";
            border: 0.063rem solid #646464;
            box-shadow: 0 0 0.438rem #0000001a;
        }
        .custom-switch .custom-control-label::after {
            top: 0.376rem;
            left: -2.4rem;
            width: 1rem;
            height: 0.748rem;
            border-radius: 100rem;
            border-color: #fff;
            background-color: #fff;
            transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .custom-control-label:after {
            position: absolute;
            display: block;
            content: "";
            background: 50%/50% 50% no-repeat;
        }
    }
  }
}

// Partie profile m-ticket
ctw-mobility-account{
  ctw-profile-selection{
    .list-unstyled{
      .card-hover:not(.card-disabled):hover, .cityway-v8 .card-hover:not(.card-disabled):active, .cityway-v8 .card-hover:not(.card-disabled):focus {
        box-shadow: 0 0 0.4375rem #00000059;
      }
    }
  }
  // Page de confirmation de demande deposée
  ctw-profile-confirmation{
    .card-body{
      .modal-header-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7rem;
        height: 7rem;
        margin: -5rem auto 1rem;
        font-size: 3rem;
        color: #fff;
        border-radius: 100rem;
      }
    }
    .card-footer{
      .btn.btn-primary{
        color: white;
      }
    }
  }
}

// Style identique aux alertes dans le composant ctw-mobility-account
ctw-mobility-account{
  // Partie alert
  ctw-alert{
    .alert {
      .alert-icon{
        font-size: $font-size-large-icon;
      }
      .alert-content{
        .alert-title{
          font-weight: $font-weight-bold;
        }
        .alert-text{
          font-size: $font-size-content;
          .alert-link{
            font-weight: 700;
            display: inline;
            padding: 0;
            font-size: $font-size-content;
            border: 0;
            text-decoration: underline;
            line-height: 1.3;
          }
        }
      }
    }
  }
}

// Partie "coordonnées bancaires SEPA" dans "Mes informations de paiement"
ctw-mobility-account{
  ctw-banking-details{
    // Icon dans la artie IBAN (corps de la card)
    .mode-rounded.mode-rounded-big {
      background-color: $primary;
      border-radius: 100%;
      padding: 0.55rem;
      font-size: 2.2rem;
      color: white;
  }
  }
}

// Partie "Acheter des titres" dans "Mes titres"
ctw-mobility-account{
  // header
  .my-wallet-page-header .basket-btn{
    border-radius: 1.25rem;
    padding: 1em;
    font-weight: 700;
    color: #fff;
    background-color: $primary;
    border: none;
    // Le compteur de panier dans le coin supérieur droit du bouton
    span.basket-btn-nb-item {
      position: absolute;
      top: 0;
      right: 0;
      /* padding: .2rem; */
      padding-left: .5rem;
      padding-right: .5rem;
      border-radius: 100rem;
      background-color: $red;
      box-shadow: 0 0 .4375rem #0003;
      transform: translate(30%, -30%);
    }
  }
  .my-wallet-page-header .basket-btn:hover{
    background-color: $primary-contrast;
  }
  // body 
  .my-wallet-container{
    // bouton ajouter au panier.
    .btn-container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .add-to-cart-total-price-btn, .btn-primary{
      border-color: $primary;
    }
    // partie "Mes coordonnées bancaires"
    .tooltip{
      transform: translate(183px, -250px) !important;
      .arrow{
        transform: translateY(71px)
      }
    }
    .file-to-download-link {
      padding: .75rem 1rem;
      border-radius: .9375rem;
      background-color: #fff;
      box-shadow: 0 0 .4375rem #0003;
    }
    .file-to-download-name {
      flex: 1;
    }
  } 
}

// Partie suivi de conso, choisir une date (datepicker)
ctw-mobility-account{

  // first and last element of the range
  .datepicker-table .range-first span,
  .datepicker-table .range-first[tabindex="0"] span,
  .datepicker-table .range-first.disabled span,
  .datepicker-table .range-last span,
  .datepicker-table .range-last[tabindex="0"] span,
  .datepicker-table .range-last.disabled span {
    background-color: $primary;
    opacity: 1 !important;
  }

  // middle elements of the range
  .datepicker-table .range span,
  .datepicker-table .range.disabled span,
  .datepicker-table .range[tabindex="0"] span {
    background-color: $primary;
    opacity: .6;
  }

  // selected date
  .datepicker-table [aria-selected=true] span,
  .datepicker-table [aria-selected=true][tabindex="0"] span {
    background-color: $primary;
  }

  // last selectable date
  .datepicker-table [tabindex="0"] span {
    color: $primary;
  }

  // hover effect
  .datepicker-table tbody td:hover {
    color: $primary;
  }
  .datepicker-range .datepicker-table tbody td:not(.disabled)[tabindex="0"]:hover span {
    background-color: $primary;
  }

  // Focus effect
  .datepicker-range .datepicker-table tbody td:not(.disabled)[tabindex="-1"]:hover span,
  .datepicker-range .datepicker-table tbody td:not(.disabled)[tabindex="-1"]:focus span {
    background-color: $primary;
  }  
}

// Partie mes échéancier de prélevement
ctw-mobility-account{
  .btn-container .btn-link{
    margin-top: 1em;
    color: $primary;
  }
  .btn-container .btn-link:hover{
    color: $primary;
  }
}