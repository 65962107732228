@charset "UTF-8";

//
// Variables font OPTYWEB
// --------------------------------------------------

@mixin optyweb {
  font-size: 1.25rem;
  font-family: $cw-font-transinfo;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$cw-font-transinfo: "optyweb";

$cw-misc-tariff-profile: "\e91d";
$cw-mode-multi-train: "\e91c";
$cw-account-service: "\e917";
$cw-sale-add-card: "\e918";
$cw-sale-price-dollar: "\e919";
$cw-sale-price-euro: "\e91a";
$cw-sale-wallet: "\e91b";
$cw-poi-ski-resort: "\e916";
$cw-poi-event: "\e914";
$cw-poi-military: "\e915";
$cw-misc-sepa: "\e913";
$cw-poi-business-facilities: "\e001";
$cw-toolbar-table: "\e002";
$cw-poi-bike-station: "\e003";
$cw-mode-realtime-vehicle: "\e004";
$cw-poi-fireman: "\e005";
$cw-poi-community-services: "\e006";
$cw-poi-bank: "\e007";
$cw-toolbar-login: "\e010";
$cw-data-protection: "\e011";
$cw-toolbar-more: "\e012";
$cw-toolbar-new-window: "\e013";
$cw-toolbar-edit: "\e026";
$cw-toolbar-download: "\e028";
$cw-toolbar-delete: "\e02c";
$cw-toolbar-destroy: "\e02d";
$cw-toolbar-disconnect: "\e02e";
$cw-toolbar-calendar: "\e032";
$cw-social-comment: "\e049";
$cw-alert-comment: "\e04a";
$cw-social-facebook: "\e04b";
$cw-social-facebook-alternate: "\e04c";
$cw-social-mail: "\e04d";
$cw-poi-road: "\e04f";
$cw-social-mail2: "\e050";
$cw-social-rss: "\e051";
$cw-social-share: "\e052";
$cw-social-sms: "\e053";
$cw-social-twitter: "\e054";
$cw-social-twitter-alternate: "\e055";
$cw-social-linkedin-alternate: "\e056";
$cw-social-linkedin: "\e057";
$cw-social-instagram: "\e058";
$cw-regularize-payment: "\e059";
$cw-incident-payment: "\e05a";
$cw-payement: "\e05b";
$cw-toolbar-add-favorite: "\e05c";
$cw-toolbar-add: "\e05d";
$cw-toolbar-cancel-filter: "\e05e";
$cw-toolbar-close1: "\e05f";
$cw-toolbar-export: "\e060";
$cw-boarding-only: "\e06b";
$cw-drop-off-forbidden: "\e06c";
$cw-boarding-forbidden: "\e06d";
$cw-social-instagram1: "\e06e";
$cw-toolbar-search2: "\e06f";
$cw-quit: "\e07a";
$cw-mode-rer: "\e07b";
$cw-nav-next-right: "\e07c";
$cw-nav-next-left: "\e07d";
$cw-event-fog: "\e07e";
$cw-event-snow: "\e07f";
$cw-favourite-alarm: "\e089";
$cw-mode-wait: "\e08a";
$cw-toolbar-duration: "\e08b";
$cw-toolbar-schedules: "\e08c";
$cw-nav-small-menu: "\e08d";
$cw-show-map: "\e08e";
$cw-mode-coach-access: "\e08f";
$cw-poi-police: "\e09a";
$cw-poi-airport: "\e09b";
$cw-poi-tourist-office: "\e09c";
$cw-poi-museum: "\e09d";
$cw-poi-hotel: "\e09e";
$cw-poi-marina: "\e09f";
$cw-pole-echange: "\e0a0";
$cw-poi-carpool-area: "\e0a1";
$cw-agence-information: "\e0a2";
$cw-poi-beach: "\e0a3";
$cw-misc-phone2: "\e0a4";
$cw-misc-newsletter: "\e0a5";
$cw-event-rain: "\e0a6";
$cw-streetview: "\e0ba";
$cw-poi-carpool: "\e0bb";
$cw-poi-carpool-transition: "\e0bc";
$cw-poi-tod-stop: "\e0bf";
$cw-poi-taxi-station: "\e0c6";
$cw-poi-taxi-terminal: "\e0c7";
$cw-mode-school2: "\e0c8";
$cw-poi-club: "\e0c9";
$cw-poi-companies: "\e0ca";
$cw-mode-ler: "\e0cb";
$cw-poi-camping: "\e0cc";
$cw-poi-autopartage-station: "\e0d0";
$cw-poi-intersection: "\e0d1";
$cw-poi-cable-car: "\e0d5";
$cw-misc-news: "\e0eb";
$cw-misc-money: "\e0ed";
$cw-misc-next-departure-time: "\e0ee";
$cw-misc-list-circle1: "\e0f6";
$cw-misc-list-arrow2: "\e0f8";
$cw-misc-fuel: "\e0ff";
$cw-misc-health: "\e100";
$cw-misc-ecology: "\e103";
$cw-misc-ecology1: "\e104";
$cw-misc-copyright: "\e107";
$cw-misc-call: "\e10b";
$cw-location-agency: "\e129";
$cw-misc-sale: "\e12a";
$cw-access-accompanied-hearing-impaired-user: "\e149";
$cw-access-accompanied-learning-impaired-user: "\e14a";
$cw-access-accompanied-visually-challenged-user: "\e14b";
$cw-access-accompanied-wheelchair-user: "\e14c";
$cw-access-hearing-impaired-user: "\e14d";
$cw-access-learning-impaired-user: "\e14e";
$cw-access-visually-challenged-user: "\e14f";
$cw-social-addthis: "\e15b";
$cw-misc-google-plus: "\e15c";
$cw-misc-info: "\e15d";
$cw-misc-text: "\e15e";
$cw-misc-tourims: "\e15f";
$cw-poi-stop2: "\e161";
$cw-poi-bike-park3: "\e167";
$cw-poi-district2: "\e16b";
$cw-poi-culture2: "\e16c";
$cw-poi-bike-rental: "\e16d";
$cw-poi-bike-park2: "\e16e";
$cw-poi-administration2: "\e16f";
$cw-poi-health2: "\e172";
$cw-poi-borne-electric: "\e174";
$cw-poi-worship2: "\e17a";
$cw-poi-sport2: "\e17c";
$cw-poi-pool: "\e17d";
$cw-poi-service2: "\e17e";
$cw-poi-sale2: "\e17f";
$cw-poi-zone2: "\e182";
$cw-poi-zcommercial2: "\e183";
$cw-poi-vaccination: "\e189";
$cw-misc-screen: "\e18c";
$cw-online-agency: "\e18d";
$cw-online-services: "\e18e";
$cw-misc-man2: "\e18f";
$cw-toolbar-file: "\e193";
$cw-poi-entertainment2: "\e195";
$cw-iti-return-trip21: "\e196";
$cw-misc-tablet: "\e197";
$cw-poi-sncf: "\e199";
$cw-poi-park2: "\e19a";
$cw-mode-train: "\e19b";
$cw-misc-hourglass: "\e19c";
$cw-alert-warning: "\e19d";
$cw-map-map4: "\e19e";
$cw-poi-ski-resort2: "\e19f";
$cw-poi-parking2: "\e1a0";
$cw-poi-park-and-ride2: "\e1a1";
$cw-poi-park-and-bike: "\e1a2";
$cw-poi-education2: "\e1a3";
$cw-toolbar-full-screen2: "\e1a4";
$cw-misc-route4: "\e1a5";
$cw-misc-route3: "\e1a6";
$cw-misc-windows-phone: "\e1a7";
$cw-map-map3: "\e1a8";
$cw-misc-sale1: "\e1a9";
$cw-misc-ticket2: "\e1aa";
$cw-misc-ticket3: "\e1ab";
$cw-reload-card: "\e1ac";
$cw-past-journey: "\e1ad";
$cw-access-wheelchair-user: "\e1ae";
$cw-alert-error2: "\e1af";
$cw-alert-notif: "\e1b0";
$cw-alert-help: "\e1b1";
$cw-alert-info2: "\e1b2";
$cw-alert-success2: "\e1b3";
$cw-arrow-left: "\e1b4";
$cw-arrow-right: "\e1b5";
$cw-event-comercial: "\e1b8";
$cw-event-disrupt-favorite-line: "\e1b9";
$cw-event-disrupt-public-transport: "\e1ba";
$cw-event-disrupt: "\e1bb";
$cw-event-march: "\e1bc";
$cw-event-social-movement: "\e1bd";
$cw-event-warning: "\e1bf";
$cw-activity: "\e1c0";
$cw-center-commercial: "\e1c1";
$cw-alert-commercial: "\e1c2";
$cw-event-weather: "\e1c3";
$cw-iti-border: "\e1c4";
$cw-iti-favorite: "\e1c5";
$cw-iti-from: "\e1c6";
$cw-iti-journey: "\e1c7";
$cw-iti-plan: "\e1c8";
$cw-iti-recent-trip: "\e1c9";
$cw-iti-return-trip2: "\e1ca";
$cw-iti-smart-assistant: "\e1cb";
$cw-iti-to: "\e1cc";
$cw-iti-turn-left: "\e1cd";
$cw-iti-turn-right: "\e1ce";
$cw-iti-via: "\e1cf";
$cw-map-full-screen-return: "\e1d0";
$cw-map-full-screen: "\e1d1";
$cw-map-geolocalisation: "\e1d2";
$cw-map-location: "\e1d3";
$cw-map-map1: "\e1d4";
$cw-map-map2: "\e1d5";
$cw-map-displacement: "\e1d6";
$cw-map-move: "\e1d7";
$cw-map-navigation: "\e1d8";
$cw-map-nearest: "\e1d9";
$cw-map-itinary: "\e1da";
$cw-map-satellite: "\e1db";
$cw-misc-access: "\e1dc";
$cw-misc-alarm: "\e1dd";
$cw-misc-android: "\e1de";
$cw-misc-apple: "\e1df";
$cw-misc-arrival: "\e1e0";
$cw-misc-basket: "\e1e1";
$cw-misc-camcorder: "\e1e2";
$cw-misc-camera: "\e1e3";
$cw-misc-departure: "\e1e4";
$cw-misc-hour1: "\e1e5";
$cw-misc-hour2: "\e1e6";
$cw-misc-legal-info: "\e1e7";
$cw-misc-legend: "\e1e8";
$cw-misc-list-circle2: "\e1e9";
$cw-misc-list-diamond1: "\e1ea";
$cw-misc-list-diamond2: "\e1eb";
$cw-misc-list-square1: "\e1ec";
$cw-misc-list-square2: "\e1ed";
$cw-misc-list-triangle1: "\e1ee";
$cw-misc-phone: "\e1ef";
$cw-misc-registered: "\e1f0";
$cw-misc-route: "\e1f1";
$cw-misc-route2: "\e1f2";
$cw-misc-smartphone: "\e1f3";
$cw-misc-smartphone2: "\e1f4";
$cw-misc-ticket: "\e1f5";
$cw-card: "\e1f6";
$cw-misc-trick: "\e1f7";
$cw-misc-via: "\e1f8";
$cw-misc-youtube: "\e1f9";
$cw-mode-bike-bus: "\e1fa";
$cw-mode-bike-service: "\e1fb";
$cw-mode-bike: "\e1fc";
$cw-mode-harbor: "\e1fd";
$cw-mode-boat: "\e1fe";
$cw-mode-bus-access: "\e1ff";
$cw-mode-bus: "\e200";
$cw-mode-cable-car: "\e201";
$cw-mode-car-pool: "\e202";
$cw-mode-car: "\e203";
$cw-toilets: "\e205";
$cw-mode-coach-ter: "\e206";
$cw-bus-shelter: "\e20a";
$cw-poi-civil-security: "\e20b";
$cw-poi-sociocultural: "\e20c";
$cw-mode-coach: "\e20d";
$cw-mode-cyclopouss: "\e20e";
$cw-mode-elevator: "\e20f";
$cw-pin-arrival: "\e210";
$cw-poi-lieudit: "\e217";
$cw-poi-transition-point: "\e218";
$cw-poi-exit-point: "\e219";
$cw-poi-infos-bus: "\e21a";
$cw-poi-adress: "\e21b";
$cw-pin-via: "\e21c";
$cw-pin-departure: "\e21d";
$cw-pin-interest: "\e21e";
$cw-pin: "\e21f";
$cw-poi-enter-point: "\e220";
$cw-poi-parking-electric-vehicle: "\e221";
$cw-mode-bus-electric: "\e222";
$cw-poi-info-quartier: "\e223";
$cw-poi-restaurant: "\e224";
$cw-poi-bar: "\e225";
$cw-mode-car-bus: "\e226";
$cw-mode-escalator: "\e227";
$cw-mode-funicular: "\e228";
$cw-mode-ler2: "\e229";
$cw-mode-metro: "\e22a";
$cw-mode-minibus: "\e22b";
$cw-mode-plane: "\e22c";
$cw-mode-school: "\e22d";
$cw-mode-school3: "\e22e";
$cw-mode-taxi: "\e22f";
$cw-mode-taxibus: "\e230";
$cw-mode-ter: "\e231";
$cw-mode-tgv: "\e232";
$cw-mode-tod-ufr: "\e233";
$cw-mode-tod: "\e234";
$cw-mode-trolley: "\e235";
$cw-mode-fastest: "\e236";
$cw-mode-walk: "\e237";
$cw-mode-correspondence: "\e238";
$cw-nav-collapse1: "\e239";
$cw-nav-collapse2: "\e23a";
$cw-nav-connexion: "\e23b";
$cw-nav-dashboard: "\e23c";
$cw-nav-expand1: "\e23d";
$cw-nav-expand2: "\e23e";
$cw-nav-first: "\e23f";
$cw-nav-home: "\e240";
$cw-nav-last: "\e241";
$cw-nav-media-pause: "\e242";
$cw-nav-media-play: "\e243";
$cw-nav-menu: "\e244";
$cw-nav-next1: "\e245";
$cw-nav-next2: "\e246";
$cw-nav-page-back: "\e247";
$cw-nav-page-bottom: "\e248";
$cw-nav-page-top: "\e249";
$cw-nav-previous1: "\e24a";
$cw-nav-previous2: "\e24b";
$cw-mode-tram: "\e24c";
$cw-event-wind: "\e24d";
$cw-event-ice: "\e24e";
$cw-drop-off-only: "\e24f";
$cw-direct: "\e250";
$cw-real-time: "\e251";
$cw-misc-basket-2: "\e252";
$cw-hide-map: "\e253";
$cw-choose-map: "\e254";
$cw-toolbar-favorite: "\e255";
$cw-toolbar-file-bmp: "\e256";
$cw-toolbar-file-excel: "\e257";
$cw-toolbar-file-gif: "\e258";
$cw-toolbar-file-jpg: "\e259";
$cw-toolbar-file-openoffice-calc: "\e25a";
$cw-toolbar-file-openoffice-impress: "\e25b";
$cw-toolbar-file-openoffice-writer: "\e25c";
$cw-toolbar-file-openoffice: "\e25d";
$cw-toolbar-file-pdf: "\e25e";
$cw-toolbar-file-png: "\e25f";
$cw-toolbar-file-powerpoint: "\e260";
$cw-toolbar-file-rar: "\e261";
$cw-toolbar-file-word: "\e262";
$cw-toolbar-file-zip: "\e263";
$cw-toolbar-filter: "\e264";
$cw-toolbar-paste: "\e265";
$cw-toolbar-print: "\e266";
$cw-toolbar-reload: "\e267";
$cw-toolbar-search: "\e268";
$cw-misc-discover: "\e269";
$cw-toolbar-secure: "\e26a";
$cw-toolbar-settings: "\e26b";
$cw-misc-alarm2: "\e77a";
$cw-mode-car-sharing: "\e77b";
$cw-misc-work: "\e77c";
$cw-toolbar-user: "\e782";
$cw-misc-meeting: "\e783";
$cw-event-traffic-accident: "\e900";
$cw-event-traffic-congestion: "\e901";
$cw-event-traffic-construction: "\e902";
$cw-event-traffic-disabledvehicle: "\e903";
$cw-event-traffic-miscellaneous: "\e904";
$cw-event-traffic-obstruction: "\e905";
$cw-event-traffic-sportevent: "\e906";
$cw-event-traffic-blockedroad: "\e907";
$cw-event-traffic-weather: "\e921";
$cw-event-traffic-undefined: "\e922";
$cw-event-cloud: "\e908";
$cw-iti-turn-completely-right: "\e909";
$cw-iti-turn-slightly-right: "\e90a";
$cw-iti-straight: "\e90b";
$cw-iti-turn-completely-left: "\e90c";
$cw-iti-turn-slightly-left: "\e90d";
$cw-event-few-cloud: "\e90e";
$cw-event-sun: "\e90f";
$cw-event-disruption-end: "\e920";
$cw-event-change-road: "\e1b9";
$cw-poi-market: "\e910";
$cw-misc-kcal: "\e911";
$cw-mode-tod-snow: "\e912";
$cw-misc-bag: "\e91e";
$cw-misc-free: "\e923";
$cw-toolbar-calendar-checked: "\e928";
$cw-toolbar-calendar-schedule: "\e929";
$cw-misc-plug-type2: "\e92a";
$cw-misc-plug-chademo: "\e92b";
$cw-misc-plug-comboccs: "\e92c";
$cw-misc-plug-typeEF: "\e92d";
$cw-misc-watts: "\e92e";
