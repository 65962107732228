@import "../../variables.scss";

.map-schedule {
  left: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: $font;

  // Ciblez le conteneur de la liste des lignes existantes pour un arrêt physique
  .schedule-physical-stop-extra-features-lines {
    ul.list-unstyled {
      li.list-inline-item {
        margin-bottom: 1rem; // Ajoutez de l'espace en dessous de chaque ligne de la liste pour eviter le collages des lignes
      }
    }
  }

  .item-line {
    position: relative;
  }

  ctw-btn-previous-page {
    display: flex;
    align-items: center;
  }

  .nearby-poi-popup {
    margin-top: 1em;
  }
  .nearby-poi-popup > ul {
    position: absolute;
    right: 10px;
    margin: 0;
  }

  .marker-label-terminus {
    font-weight: 600;
    width: auto;
    color: #fff;
    text-align: center;
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;
    background-color: #212529;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 1.25rem;
  }

  .cw-social-comment,
  .cw-streetview,
  .cw-misc-hour1,
  .cw-pin-arrival,
  .cw-pin-departure {
    color: $primary;
  }

  .dropdown-menu-right {
    @media screen and (max-width: 600px) {
      left: -65px !important;
    }
  }

  #autocompletion-panel {
    @media screen and (max-width: 600px) {
      position: absolute !important;
      left: 1px;
      top: 5em;
    }
    @media screen and (max-width: 768px) {
      position: absolute !important;
      left: 0px;
    }
  }

  .schedule-physical-stop-update,
  .schedule-physical-stop-info {
    $font-size-line-number: 0.75rem;
  }

  .item-stop-schedule-disabled {
    color: grey !important;
  }

  .card-jp-solution-rt-item,
  .schedule-line-direction-stops-item-details,
  .next-departure-item .next-departure-item-time,
  .next-departure-single .next-departure-item-time,
  ctw-item-stop-schedule .item-stop-schedule {
    padding: 0.25rem 0.5rem;
    border-radius: 0.75rem;
    font-size: $font-size-line-number;
    line-height: 1.33333;
    color: #212529;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  .schedule-physical-stop-title-container .schedule-physical-stop-title,
  .schedule-physical-stop
    .schedule-physical-stop-card
    .schedule-physical-stop-hour {
    $font-size-content: 1rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-right: 1em;
  }
  .schedule-physical-stop-title{
    margin-right: unset;
  }

  .card-body {
    padding: 1rem 1.5rem !important;
  }
  .list-inline {
    justify-content: flex-start !important;
    flex-wrap: wrap;
    gap: 0.2rem;
  }
  .schedule-line-direction {
    $font-size-line-number: 0.75rem;
    margin-bottom: 1em;
  }
  .panel-content-scroll {
    padding: 1.5rem 1rem 1rem 1rem !important;
    @media screen and (max-width: 600px) {
      padding: 1.5rem 1rem 5rem 1rem !important;
    }
  }
  .schedule-line-direction-stops-item:nth-child(odd):not(:first-child):not(
      :last-child
    )
    .schedule-line-direction-stops-item-infos
    .schedule-line-direction-stops-item-details {
    background-color: #eaecee;
    padding: 0.5em;
    border-radius: 1em;
  }

  .schedule-line-direction-stops-item:nth-child(odd):not(:first-child):not(
      :last-child
    )
    .schedule-line-direction-stops-item-infos {
    background-color: #f8f9fa;
  }
  .schedule-line-direction-stops
    .schedule-line-direction-stops-item:first-child
    .schedule-line-direction-stops-item-icon
    .optyweb:before,
  .schedule-line-direction-stops
    .schedule-line-direction-stops-item:last-child
    .schedule-line-direction-stops-item-icon
    .optyweb:before {
    color: #ec6608;
  }

  .schedule-line-direction-stops
    .schedule-line-direction-stops-item:last-child
    .schedule-line-direction-stops-item-icon
    .optyweb:before {
    background-color: #ec6608;
  }

  .schedule-line-direction-stops-item-name ctw-item-line {
    margin-top: 0.5rem;
  }
  .schedule-line-direction-stops-item-lines-more-line {
    margin-top: 0.5rem;
    border: 2px solid $primary;
    border-radius: $global-border-radius;
    margin-top: 0.5rem;
    padding-left: 0.1em;
    font-weight: bold;
    text-align: center;
    width: 10%;
    height: 100%;
    $font-size-content: 0.9em;
  }
  .cw-poi-stop2 {
    margin-left: 0.25em;
  }

  .schedule-line-direction-stops-item-time {
    $font-size-line-number: 0.75rem;
    font-weight: bold;
  }
  .cw-drop-off-only,
  .cw-boarding-only {
    display: flex;
    align-items: center;
    margin-right: 0.25rem;
  }
  .accessibility-icon {
    color: $secondary;
  }

  .schedule-line-direction-stops-item-details {
    padding: 0.5em;
  }
  .schedule-line-direction-stops-item.first
    .schedule-line-direction-stops-item-infos
    .schedule-line-direction-stops-item-details {
    background-color: #ffe8cc;
    padding: 0.5em;
    border-radius: 1em;
  }

  .schedule-line-direction-stops-item-name-locality {
    flex-basis: 100%;
  }

  .schedule-line-direction-stops-item-name {
    display: flex;
    flex-wrap: wrap;
  }

  .stretched-link {
    padding: 0;
    font-weight: bold;
    flex-basis: 100%;
  }

  .schedule-line-direction-stops-item.first
    .schedule-line-direction-stops-item-infos {
    background-color: #fff4e6;
  }
  .schedule-line-direction-stops-item
    .schedule-line-direction-stops-item-infos {
    border-radius: 2rem;
  }
  .schedule-line-direction-stops-item
    .schedule-line-direction-stops-item-infos {
    padding: 0.75rem 1rem;
    border-radius: 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    margin-left: 1.25rem;
  }

  .line-select-direction-form-control {
    width: 100% !important;
    padding: 0;
    padding-left: 10px;
  }

  #time {
    margin-bottom: 0 !important;
  }

  .schedule-line-direction-paging {
    align-items: center;
    justify-content: space-between !important;
    margin-top: 1rem;
  }
  .schedule-line-direction-paging-last,
  .schedule-line-direction-paging-next,
  .schedule-line-direction-paging-previous,
  .schedule-line-direction-paging-first {
    background-color: inherit !important;
    color: black;
    border: none;
  }

  .disabled-click {
    border: none !important;
  }
  .btn-secondary:focus {
    border: none !important;
    box-shadow: none !important;
    color: black;
  }

  .btn-secondary:disabled {
    background-color: inherit !important;
    color: #868e96 !important;
    border: none;
  }

  .schedule-line-direction-paging-last:hover,
  .schedule-line-direction-paging-next:hover,
  .schedule-line-direction-paging-previous:hover,
  .schedule-line-direction-paging-first:hover {
    color: black;
  }

  .schedule-physical-stop-header .item-line .item-line-number,
  .schedule-physical-stop-header .item-line .item-line-name,
  .schedule-line-direction-header .item-line .item-line-number,
  .schedule-line-direction-header .item-line .item-line-name,
  .schedule-header .item-line .item-line-number,
  .schedule-header .item-line .item-line-name {
    color: #212529;
    font-size: $font-size-title;
    line-height: 1.33333;
    font-weight: 700;
    min-width: 3.75rem;
  }
  .schedule-physical-stop-header .item-line .item-line-mode,
  .schedule-line-direction-header .item-line .item-line-mode,
  .schedule-header .item-line .item-line-mode {
    width: 1.75rem;
    height: 1.75rem;
    font-size: $font-size-big-icon;
    margin-left: 0.5rem;
  }
  .schedule-physical-stop-header .item-line,
  .schedule-line-direction-header .item-line,
  .schedule-header .item-line {
    padding: 1.5rem 0.35rem;
  }

  .schedule-line-direction-header .item-line{
    margin-top: 10px;
  }

  h2 {
    margin-top: 1.5rem;
    font-size: $font-size-title;
  }
  h3 {
    margin-top: 1.5rem;
    font-size: $font-size-content;
  }

  #pan-panel {
    max-width: 700px;
    margin-left: 17px;
    border-radius: $global-border-radius;
  }
  #panel-content-scroll-header {
    padding: 0rem 1rem;
    margin-top: 0;
  }

  #searchElem {
    // Configure la zone de saisie
    height: $size-round-button;
    border-radius: $global-border-radius;
    border: solid;
    border-color: $primary;
    margin-left: 10px;
  }

  .alert {
    position: relative;
    margin: 0.75rem 1.25rem;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: $global-border-radius;
  }

  .item-line {
    margin-right: 10px;
  }
  .item-line-name {
    font-size: $font-size-line-number;
  }
  .cw-real-time {
    color: $primary;
  }

  .item-stop-schedule {
    display: flex;
    flex-direction: row-reverse;
    padding: 0.25rem 0.5rem;
    background-color: #f1f3f5;
    border-radius: 0.75rem;
    font-size: $font-size-line-number;
    line-height: 1.33333;
    color: #212529;
    font-weight: 700;
    border-radius: $global-border-radius;
  }
  .item-line-name {
    color: white;
  }

  .line-select-direction {
    margin-top: 1.5rem;
    display: inline-flex;
    align-items: center;
    padding: 0 0 0 .75rem;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    background-color: #fff;
    max-width: 100%;
  }
  .line-select-direction-label {
    margin: 0;
    line-height: 2.5;
  }
  .line-select-direction-form-control {
    border-style: none;
    font-weight: bold;
  }

  .nearby-mode-items {
    flex-grow: 1;
    padding-top : 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem; 
    border-radius: $global-border-radius;
    background-color: #fff;
    border-style: solid;
    width: fit-content;
    border-color: $primary;
    border-width: thin;
    .nearby-mode-item {
      display: flex;
      justify-content: center;
      align-items: center; // This centers the button icon and text vertically within the button
      .nearby-mode-item-btn:last-child {
        &:hover {
          color: $primary;
        }
      }
      .nearby-mode-item-btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100rem;
        box-shadow: 0 0 0.4375rem #0003;
        font-size: 1.5em;
      }
    }
  }

  .next-departure-items .next-departure-item {
    padding: 0.5rem 1rem;
  }
  .next-departure-item-time {
    padding: 0.25rem 0.5rem;
    color: #e79200;
    border-radius: 0.75rem;
    font-size: $font-size-line-number;
    line-height: 1.33333;
    color: #212529;
    font-weight: 600;
  }
  .next-departure-items-container {
    margin-top: 1rem;
  }
  .panel-content-scroll-body {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10em;
  }
  .schedule-search-back-btn {
    margin-bottom: 1em;
    margin-top: 0.4em;
  }

  .schedule-search-input-group {
    filter: drop-shadow(0 8px 16px rgba(33, 37, 41, 0.08));
  }

  .schedule-logical-physical-stop-mode-icon,
  .nearby-address-popup-poi-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    color: #fff;
    color: #868e96;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    background-color: #212529;
  }

  .schedule-physical-stop-hour {
    line-height: 1.33333;
  }
  .schedule-logical-physical-stop-mode-title {
    text-transform: uppercase;
  }
  .schedule-physical-stop-title {
    font-size: $font-size-title;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  .ctw-line-select-direction .line-select-direction select {
    padding: 0 1rem 0 0;
    width: auto;
    height: auto;
    box-shadow: none;
    border-radius: 0;
    font-size: $font-size-content;
    line-height: 1.5rem;
    font-weight: 700;
    position: relative;
  }

  .schedule-physical-stop-header,
  .schedule-line-direction-header,
  .schedule-header {
    justify-content: center;
    position: relative;
    min-height: 3rem;
  }
  .schedule-header-menu {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    flex-wrap: unset;
  }
  .dropdown-option-menu {
    //position specifique au composant "Horaires (translate)"
    position: absolute;
    top: 0px;
    left: 0px;
    will-change: transform;
    transform: translate(-222px, 48px) !important;
  }

  .schedule-physical-stop-title-period {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }
  .schedule-physical-stop-card {
    filter: $global-shadow;
  }

  #pan-panel #date.form-control,
  #pan-panel #time.form-control {
    border-radius: 0.75rem !important;
    border-color: $primary;
    padding: 0.75rem 1rem;
    height: 3rem;
    font-size: $font-size-line-number;
    line-height: 1.66667;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1em;
  }
}

ctw-schedule{
  // Pan-panel à gauche dd l'écran
  #pan-panel{
    margin-bottom: 200px;
  }

  // Partie Favoris dans le panel a gauche (pas dans la bar de complétion)
  ctw-schedule-search-favourites{
    .schedule-search-favourites{
      // un peu de margin
      margin-bottom: 1.5rem;
      // Title of the panel 
      .schedule-search-favourites-title{
        margin-top: 0 !important;
      }
      // content of the panel 
      .schedule-search-favourites-content{
        position: relative;
        padding: 0.75rem 1rem 0;
        border: 0.175rem solid $primary;
        border-radius: 0.9375rem;
        // icon favoris sous forme de coeur
        .schedule-search-favourites-icon {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 1.5rem;
          color: #fff;
          font-size: 1rem;
          border-radius: 100rem;
          background-color: $primary;
          transform: translateY(-50%);
        }
        .cw-toolbar-favorite:before {
          color: white;
        }
        // les cartes de favoris
        .card{
          margin-bottom: 1rem;
          filter: none;
          // drop down menu
          .dropdown-menu{
            top : 0;
            left: 100%;
          }
        }
        // card on hover
        .card:hover{
          background-color: #f8f9fa;
        }
      }
    }
  }
  // Partie alert
  ctw-alert{
    .alert {
      .alert-icon{
        font-size: $font-size-large-icon;
        margin-right: 0;
      }
      .alert-content{
        .alert-text{
          font-size: $font-size-content;
          .alert-link{
            font-weight: 700;
            display: inline;
            padding: 0;
            font-size: $font-size-content;
            border: 0;
            text-decoration: underline;
            line-height: 1.3;
          }
        }
      }
    }
  }
  // partie liste
  ctw-schedule-lines-list{
    .list-unstyled.schedule-lines-list-items{
      .schedule-lines-list-item{
        .btn.btn-collapse {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 0.5rem;
          padding-left: 0;
          padding-right: 0;
          color: #6a6a6a;
          text-align: left;
        }
        .btn-collapse:focus {
          border-radius: 0.5rem;
        }
        .btn-collapse:hover {
          color: #b10058;
        }
        .btn-collapse::before(:hover) {
          color: #b10058;
        }
        .btn-collapse::before{
          top: unset;
          transform: unset;
          background-position: unset;
          background-size: unset;
          background-repeat: unset;
          background-image: unset;
          content: "\e23a";
          display: inline-block;
          margin-left: auto;
          font-family: optyweb;
          font-style: normal;
          font-weight: 400;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          transition: all .2s ease-in-out;
          font-size: .9rem;
        }
        .collapsed::before {
          transform: rotate(180deg);
        }
      }
    }
  }
}
