@import "../../variables.scss";

ctw-taxi-map{
    // les solutions de taxi
    ctw-taxi-solutions{
        // Style de la carte avec les infos des taxi solutions
        ctw-tod-reservation-da{
            // premier niveau de la carte
            .tod-reservation-da {
                margin-bottom: 1.5rem;
                font-size: 0.875rem;
                .card-body {
                    padding: 1rem;
                    .tod-reservation-da-item{
                        margin: 0.25rem 0.5rem 0.25rem 0;
                    }
                    .tod-reservation-info-item{
                        margin: 0.25rem 0.5rem 0.25rem 0;
                    }
                    .optyweb{
                        margin-right: 0.5rem;
                        color: $primary;
                    }
                }
            }
        }
        // les solutions de taxi
        ctw-taxi-solution-item{
            .taxi-solution-item {
                margin-bottom: 1rem;
                .card-header{
                    padding-bottom: 0;
                    .btn{
                        font-weight: $font-weight-bold;
                    }
                    .stretched-link{
                        padding: 0;
                    }
                }
                .taxi-solution-item-mode{
                    padding-bottom: 0;
                    .taxi-solution-item-mode-line {
                        height: 0.25rem;
                        background-color: #444;
                    }
                }
            }
        }
    }

    // solution detail de taxi
    ctw-taxi-solution-detail{
        // card header (date and price)
        .tod-reservation{
            .tod-reservation-date{
                margin: 0 0 1.5rem;
            }
            .item-price{
                padding: 0.2rem 0.5rem;
                font-size: .875rem;
                color: #fff;
                font-weight: 400;
                white-space: nowrap;
                border-radius: 100rem;
                background-color: #0b4f6c;
            }
        }
        // card body (road sheet)
        .tod-detail-card{
            margin-bottom: 1rem;
            .taxi-detail-card-header {
                margin-bottom: 2rem;
                padding: 0.75rem;
                border-radius: 0.9375rem;
                background-color: #f0f2f5;
                .mode-rounded{
                    margin: 0;
                    width: 2em;
                    height: 2em;
                }
                .tod-detail-card-info {
                    line-height: 1.2;
                    margin-left: 1rem;
                }
                .tod-detail-card-duration {
                    font-size: .875rem;
                    line-height: 1.2;
                    font-weight: $font-weight-bold;
                }
            }
            .tod-road-sheet-items{
                margin-top: 1.5rem;
            }
            .tod-road-sheet-item-icon-departure {
                border-radius: 100rem;
                background-color: $primary;
            }
            .tod-road-sheet-item-infos {
                transform: translateY(-30%);
                margin: 0 1rem 1rem;
                .tod-road-sheet-item-infos-time {
                    font-weight: $font-weight-bold;
                    .cw-real-time {
                        color: #e79200;
                        font-size: .9rem;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    // booking details pour une solution dans les reservations
    ctw-taxi-booking-detail{
        // card header (date and dropdown button to cancel the reservation)
        .tod-reservation-date{
            margin: 0 0 1.5rem;
        }
        .tod-dropdown-actions-btn{
            width: 2rem;
            height: 2rem;
            box-shadow: 0 0 8px #0003;
            border-radius: 2rem;
            padding: 0;
        }
        // status de la réservation
        .tod-trip-status{
            margin: 0 -1rem 1.5rem;
            padding: 0.75rem 1rem;
            color: #fff;
            background-color: $primary;
            // opacity 0.65 for the primary color
            opacity: 0.8;
            // style pour l'icon du status
            .tod-trip-status-icon{
                margin-right: 1rem;
            }
        }
        // Partie sous le status de la réservation
        .taxi-booking-card-info-driver {
            font-size: 1rem;
            font-weight: 700;
        }
        // Partie details de la réservation (4èmme block)
        .taxi-booking-card-date {
            padding: 0.2rem 0.5rem;
            color: #fff;
            border-radius: 393rem;
            background-color: $primary;
        }
        .taxi-booking-card-date:hover {
            color: #fff;
        }
        .taxi-booking-card-fare {
            margin-top: 1rem;
            .taxi-booking-card-fare-title {
                font-size: .9rem;
            }
            .item-price{
                padding: 0.2rem 0.5rem;
                font-size: .875rem;
                color: #fff;
                font-weight: 400;
                white-space: nowrap;
                border-radius: 100rem;
                background-color: #0b4f6c;
            }
        }
        .tod-road-sheet-items{
            margin-top: 1.5rem;
        }
        // icon de départ taxi
        .tod-road-sheet-item-icon.tod-road-sheet-item-icon-rounded{
            left: -1.08rem;
            top: -1.4rem;
        }
        // sous icon de départ taxi
        .taxi-road-sheet-item-icon-departure {
            background-color: white;
            height: unset !important;
            width: unset !important;
            padding: 0.1rem
        }
        .tod-road-sheet-item-taxi-infos {
            transform: translateY(-30%);
            margin: 0 1rem 1rem;
            .tod-road-sheet-item-taxi-infos-time {
                font-weight: 700;
                .cw-real-time {
                    color: #e79200;
                    font-size: .9rem;
                    transform: translateY(-50%);
                }
            }
        }
    }

    // Logo du taxi sur la carte
    .rt-vehicle-icon.optyweb.cw-mode-taxi {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        font-size: 3rem;
        color: #fff;
        border-radius: 100rem;
        background-color: $primary;
        box-shadow: 0 0.125rem 0.6875rem #0006;
    }
}
