@import "../../variables.scss";
@import "../../mixins.scss";

.fine-page-header {
  margin-bottom: 2rem;
  h1 {
    font-size: $font-size-title;
  }
}
.fine-container {
  .card {
    margin-bottom: 5rem;
    .fine-confirmation {
      text-align: center !important;
      .cw-alert-success2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 3.55rem;
        height: 3.55rem;
        margin: 0 auto;
        font-size: 1.9rem;
        color: #ffffff;
        border-radius: 100rem;
        background-color: $primary;
      }
      h2 {
        font-size: 1rem;
        font-weight: 700;
        margin: 1rem 0;
      }
      padding: 0 0 10px 0;
      .btn-secondary {
        background-color: #fff;
        border-color: $primary;
        box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
        color: $primary;
        border-radius: 1.25rem;
        margin: 0 auto 1rem auto;
        width: 200px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .mandatory-fields {
      text-align: right;
      font-size: $font-size-small;
    }
    a {
      color: $primary;
    }
    .btn-primary {
      border-radius: $global-border-radius;
      background-color: $primary;
      border-color: $primary;
      padding: 1em 3rem;
      font-weight: 700;
      margin: 1rem 3rem;
    }
    .member-login-signin-rgpd {
      color: $text-color;
      font-size: $font-size-small;
      .btn-link {
        color: $primary;
        font-size: $font-size-small;
        margin-bottom: 0;
      }
    }
  }
  .fine-detail-card {
    .item-location-content {
      font-weight: 700;
      margin-left: 0.75rem;
    }
  }
}

/*Style des logos et titres sur la page de paiment des amendes*/
ctw-hi-pay{
  h2{
    font-size: $font-size-title;
  }
}