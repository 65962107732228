@import "../../variables.scss";
@import "../../mixins.scss";
/**
Style commun MRN pour tous les composants de type map
*/
.map-common {
  
  // Partie favoris dans la bar de complétion
  .autocompletion-panel{
    box-shadow: 0 0 0.4375rem #0003;
    .autocompletion-panel-favorite-items{
      margin-top: 1rem;
      padding: 0.5rem 1rem 0.5rem 1.5rem;
      .autocompletion-panel-favorite-item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 0;
        padding: 1rem 0.5rem;
        line-height: 1.1;
        font-size: .875rem;
        border-radius: 0.9375rem;
        box-shadow: 0 0 0.4375rem #0003;
        margin-bottom: 1rem;
        // icon favoris sous forme de coeur
        span.optyweb.cw-toolbar-favorite.autocompletion-panel-favorite-item-fav-icon {
          position: absolute;
          top: 0;
          right: 0;
          padding: 0.2rem;
          font-size: 1rem;
          border-radius: 100rem;
          background-color: #fff;
          box-shadow: 0 0 0.4375rem #0003;
          transform: translate(25%, -25%);
        }
        // icon Bus
        .autocompletion-panel-favorite-item-icon {
          padding: 0.5rem;
          margin-right: 0.5rem;
        }
      }
      /*item list on hover
        - The cursor will be a pointer
        - add some effect for the hover*/
      .autocompletion-panel-favorite-item:hover {
        cursor: pointer;
        background-color: #f8f9fa;
      }
    }
  }

  /**
  FAVORITE
  **/
  .schedule-physical-stop-update,
  .schedule-physical-stop-info {
    font-weight: bold;
  }

  .cw-toolbar-add-favorite:before {
    content: "\e05c";
    color: $primary;
  }
  .btn-favourite:not(.dropdown-item) {
    background-color: white !important;
    border: none !important;
    filter: $global-shadow;
  }
  .is-not-favourite:not(.dropdown-item) {
    background-color: $primary;
  }

  .cw-toolbar-favorite:before {
    color: $primary;
  }
  .schedule-physical-stop-header .item-line-disruption-icon,
  .schedule-line-direction-header .item-line-disruption-icon,
  .schedule-header .item-line-disruption-icon {
    width: 1.5rem;
    height: 1.5rem;
    top: -0.75rem;
  }
  .item-line-disruption-icon {
    background-color: #b10058;
    filter: drop-shadow(0 8px 16px rgba(33, 37, 41, 0.08));
    border-radius: 100%;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  .item-line-disruption-icon:before {
    font-size: 0.75rem;
    color: #fff;
  }
  .road-sheet-details-go-to-item {
    margin-top: 2px !important;
    padding: 0.75rem 0.5rem;
    .road-sheet-details-go-to-item-icon {
      align-self: center;
      font-size: 1.375rem;
      margin: 0 1rem 0 0;
    }
  }
  .road-sheet-details-go-to-item:first-child {
    border-radius: 1.25rem 1.25rem 0.25rem 0.25rem;
  }
  .road-sheet-details-go-to-item:last-child {
    border-radius: 0.25rem 0.25rem 1.25rem 1.25rem;
  }
  .road-sheet-details-go-to-item:not(:first-child):not(:last-child) {
    margin-top: 0.2em;
    border-radius: 0em !important;
  }

  .schedule-physical-stop-card {
    margin-bottom: 1rem;
    border-radius: 0.9375rem;
  }

  .road-sheet-card-detail-link {
    color: $primary;
    font-weight: bold;
  }
  #autocompletion-panel {
    border-radius: $global-border-radius;
  }

  .autocompletion-item-title {
    margin-top: 1em;
    font-size: $font-size-title;
    line-height: 1.5rem;
    font-weight: $font-weight-bold;
    padding: 0.2rem 1.3rem;
  }

  .autocompletion-item-title:first-of-type {
    margin-top: 1em;
  }

  .autocompletion-item-subitem {
    display: flex;
    flex-direction: row;
    align-items: center;
    display: flex;
    border-radius: 10px;
    background-color: #f1f3f5;
    margin-bottom: 0.1em;
    margin-right: 0.5em;
    margin-left: 0.5em;
    padding-left: 20px;
  }
  .autocompletion-item-subitem-infos {
    font-family: $font;
  }

  .autocompletion-item-subitem-infos-locality {
    font-size: $font-size-medium;
    font-family: $font;
    color: $primary;
    font-weight: $font-weight-bold;
  }

  .autocompletion-item-subitem-infos-name {
    font-size: $font-size-medium;
  }

  .autocompletion-item-subitem-btn {
    padding: 0.75rem 1.25rem;
    width: 100%;
    filter: none;
    font-size: $font-size-medium;
    line-height: 1.25rem;
    font-weight: normal;
    border-radius: 0.5rem;
    background-color: #f8f9fa;
  }

  .autocompletion-item-subitem-infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    background-color: #f1f3f5;
    padding: 0.5rem;
    margin: 0.5rem 1rem;
  }

  .custom-switch.custom-switch-right
    .custom-control-input
    ~ .custom-control-label:before {
    right: 0;
    left: auto;
  }
  .custom-switch.custom-switch-right
    .custom-control-input
    ~ .custom-control-label:after {
    right: $font-size-medium;
    left: auto;
  }

  .custom-control-access-trip-input {
    right: 0;
    left: auto;
  }
  .custom-switch {
    padding-left: 1.6rem;
  }
  .alert {
    position: relative;
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: $global-border-radius;
  }
  .alert:last-child {
    margin-bottom: 1rem;
  }

  .alert-content {
    margin: 0 0 0 10px;
  }
  .alert-text {
    margin: 0;
  }

  .no-result-alert {
    display: flex !important;
    align-items: center;
  }
  .btn-previous-page {
    height: $size-round-button;
    width: $size-round-button;
    transition: all 150ms ease 0s;
    border-radius: 50%;
    background-color: $primary;
    filter: $global-shadow;
    border: none;
    color: white;
  }

  .card {
    border-radius: $global-border-radius;
    filter: $global-shadow;
    border: none;
  }
  .card-header {
    border-radius: $global-border-radius;
    background-color: inherit !important;
    align-items: center;
    border: none;
  }

  .card-physical-stop {
    filter: $global-shadow;
    border-radius: $global-border-radius;
  }

  .card-physical-stop-info-locality,
  .card-physical-stop-network {
    font-size: $font-size-medium;
    font-weight: normal;
    color: #6a6a6a;
  }

  .cw-nav-page-back {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropdown-option-btn,
  .is-favourite:not(.dropdown-item),
  .is-not-favourite:not(.dropdown-item) {
    height: $size-round-button;
    width: $size-round-button;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100%;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    color: black;
    border-style: none;
    filter: $global-shadow;
    box-shadow: 0 0 0.4375rem rgba(0, 0, 0, 0.2);
    right: 0;
  }

  .dropdown-option-menu {
    border-radius: $global-border-radius;
    filter: $global-shadow;
    border-style: none;
  }
  .input-group button:not(.btn-previous-page) {
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 0;
    font-weight: $font-weight-bold;
    background-color: white;
  }

  .item-line {
    padding: 0.25rem;
    border-radius: $global-border-radius;
    height: 30px;
    width: fit-content;
  }
  .item-line-mode {
    background-color: transparent;
  }
  .item-line-number {
    border-radius: $global-border-radius;
    min-width: 1em;
    text-align: center;
    background-color: white;
    font-size: 0.875em;
    font-weight: $font-weight-bold;
    padding: 0.3em;
    margin-left: 0.3em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100px;
  }

  .item-location {
    background-color: transparent;
  }
  .item-location-label {
    font-size: $font-size-medium;
    color: #6a6a6a;
    margin-right: 0.2em;
    font-weight: normal;
  }
  .item-location-content {
    font-size: $font-size-medium;
  }
  .item-location .item-location-label + .item-location-content {
    margin-left: 4px;
  }
  .leaflet-top {
    top: 90px;
    @include media-max(768px) {
      top: 5px;
    }
  }
  .leaflet-locate-display,
  .leaflet-touch .leaflet-control-layers-toggle {
    height: $size-round-button;
    width: $size-round-button;
    filter: $global-shadow;
    background-color: white;
    border-radius: 100%;
  }
  .leaflet-touch .leaflet-bar a {
    width: $size-round-button;
    height: $size-round-button;
    line-height: $size-round-button;
  }
  .leaflet-bar a:first-child {
    border-top-left-radius: 100% !important;
    border-top-right-radius: 100% !important;
  }
  .leaflet-touch .leaflet-bar a:last-child {
    margin-top: 10px;
    border-bottom-left-radius: 100% !important;
    border-bottom-right-radius: 100% !important;
  }
  .leaflet-control-zoom {
    border: none !important;
  }
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    height: 45px;
    width: 45px;
    border-radius: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .leaflet-control-zoom-out {
    margin-top: 10px;
  }

  /**
  ----------------------------------------------------------------
  --------------  panel-content-scroll-header  -------------------
  ----------------------------------------------------------------
  **/

  .panel-content-scroll-header {
    .road-sheet-header {
      margin: -1rem -1rem 1rem;
      padding: 1rem;
      background-color: #f9f9f9;
      font-size: $font-size-medium;
      border-top-left-radius: $global-border-radius;
      border-top-right-radius: $global-border-radius;
      .road-sheet-header-duration {
        font-size: $font-size-medium;
        font-weight: 700;
        margin: 0;
      }
      .road-sheet-header-distance {
        font-size: $font-size-medium;
      }
      .road-sheet-header-walk {
        margin-left: 0.5rem;
        color: $secondary;
        font-size: $font-size-medium;
        font-weight: normal;
        padding: 0;
        background-color: #f9f9f9;
        .road-sheet-header-walk-icon {
          font-size: 0.9rem;
          color: $secondary;
        }
      }
      .road-sheet-header-arrival {
        font-size: $font-size-medium;
        font-weight: 400;
      }
      .item-price {
        padding: 0.2rem 0.5rem;
        font-size: $font-size-medium;
        color: #fff;
        font-weight: 400;
        white-space: nowrap;
        border-radius: 100rem;
        background-color: $secondary;
        .item-price-icon {
          display: block;
          margin: 0.2rem 0 0.3rem 0.5rem;
          font-size: 1.25rem;
        }
        .item-price-num {
          margin-left: 0.2rem;
        }
      }
    }
  }

  .road-sheet-paging {
    margin: 0;
    .btn-step {
      margin: 0;
      font-size: $font-size-medium;
    }
  }

  .road-sheet-details-header {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    background-color: #f9f9f9;
    .road-sheet-details-title-duration {
      background-color: white;
      color: #212529;
    }
    .item-price-num {
      gap: 10px;
      font-weight: 400;
      border-radius: 4em;
      padding: 0.2em 1em;
      background-color: #312783;
      color: white;
    }
    .road-sheet-details-title-instruction {
      font-size: $font-size-medium;
    }
    &:focus {
      box-shadow: none !important;
      border-color: none !important;
    }
  }
  .panel-content-scroll-body {
    .carpool-road-sheet-items {
      margin-left: 1rem;
      .carpool-road-sheet-item {
        &:first-child {
          padding-bottom: 1rem;
          &::before {
            position: absolute;
            top: 0;
            bottom: 50%;
            left: -0.5rem;
            content: "";
            border-left: 0.5rem solid #ffffff;
          }
        }
        &:last-child {
          &::before {
            position: absolute;
            top: 50%;
            bottom: 0;
            left: -0.5rem;
            content: "";
            border-left: 0.5rem solid #ffffff;
          }
        }

        position: relative;
        border-left: 0.5rem solid #000000;
        .carpool-road-sheet-item-icon {
          position: absolute;
          left: -0.85rem;
          z-index: 2;
          .cw-misc-departure {
            border-radius: 100rem;
            background-color: $primary;
          }
          .cw-misc-arrival {
            border-radius: 100rem;
            background-color: #fff;
          }
        }
        .carpool-road-sheet-item-infos {
          margin-left: 2rem;
          line-height: 1.2;
          .carpool-road-sheet-item-infos-label {
            font-size: 0.75rem;
            color: #6a6a6a;
          }
          .carpool-road-sheet-item-infos-time {
            font-weight: 700;
          }
        }
      }
    }
  }

  /**
  ----------------------------------------------------------------
  ----------------------------------------------------------------
  **/

  .list-inline {
    flex-direction: row;
  }
  .road-sheet-details-tc-waiting-stop-lines li {
    margin: 0 1rem 1rem 0;
  }
  .nav-tabs {
    border: none;
    padding: 0.5rem;
    border-radius: $global-border-radius;
    background-color: #f8f9fa;
    margin-bottom: 1.5rem;
  }

  .next-departure-container {
    background-color: white;
    border-radius: $global-border-radius;
    margin-bottom: 1em;
  }

  .alert-icon {
    display: flex;
    align-items: center;
  }
  .next-departure-items .next-departure-item:first-child:not(:last-child) {
    border-radius: 1.25rem 1.25rem 0.25rem 0.25rem;
    padding: 0.5rem 1rem;
  }

  .next-departure-item:not(:first-child):not(:last-child) {
    border-radius: 0em !important;
  }
  .next-departure-items .next-departure-item {
    padding: 0.5rem 1rem;
    background-color: #f8f9fa;
    transition: all 150ms ease 0s;
  }
  .next-departure-items .next-departure-item + .next-departure-item {
    margin-top: 2px !important;
  }
  .next-departure-items .next-departure-item:last-child:not(:first-child) {
    border-radius: 0.25rem 0.25rem 1.25rem 1.25rem;
  }

  .next-departure-item-time {
    padding: 0.25rem 0.5rem;
    border-radius: $global-border-radius;
    font-size: $font-size-medium;
    line-height: 1.33333;
    color: #212529;
    font-weight: $font-weight-bold;
  }
  .next-departure-item-terminus {
    font-size: $font-size-medium;
    padding: 0.2rem 0.3rem;
    background-color: #f8f9fa;
    border-radius: $global-border-radius;
  }

  .schedule-logical-physical-stop-title {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: $font-size-title;
    font-weight: $font-weight-bold;
  }
  .schedule-logical-physical-stop-city {
    font-size: $font-size-content;
    font-weight: normal;
  }
  .schedule-logical-physical-stop-item {
    margin-bottom: 1em;
  }

  .legend-accordion-item {
    margin: 0 0 10px 0;
  }

  .legend-accordion-item-label {
    position: absolute;
    left: 50px;
  }

  /**
  Icones POI
  **/
  .cw-poi-stop2:before {
    background-color: white;
    border-radius: 1em;
  }
  .poi-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.9rem;
    color: #fff;
    border-radius: 100rem;
    background-color: $secondary;
    box-shadow: 0 0.125rem 0.6875rem #0006;
  }
  .poi-marker.categ-parkandride,
  .poi-marker.categ-bikestation,
  .poi-marker.categ-bikepark,
  .poi-marker.categ-parking {
    &::before {
      position: relative;
      top: -0.0125rem;
      left: 0rem;
      display: inline-block;
      color: #fff;
      font-size: 1rem;
      transform: rotate(45deg);
    }
    position: relative;
    top: 0.6875rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50% 50% 50% 0.3125rem;
    background: #6a6a6a;
    box-shadow: 0 0.125rem 0.3125rem #0006;
    transform: rotate(-45deg);
    &.occupancy-marker-low {
      background: $red;
      .occupancy-marker-gauge:before {
        height: 50%;
        background-color: $red;
      }
    }
    &.occupancy-marker-middle {
      background: $orange;
      .occupancy-marker-gauge:before {
        height: 50%;
        background-color: $orange;
      }
    }
    .occupancy-marker-gauge {
      position: absolute;
      top: 16px;
      right: -2px;
      width: 6px;
      height: 2.075rem;
      overflow: hidden;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0 0 0.4375rem #0003;
      transform: rotate(45deg);
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        display: block;
        width: 6px;
      }
    }
  }
  /*.maker-in-front : The class used to the marked element on the map
    .poi-marker : The class used for all markers except public transport, for example : velo
    .stop-mode-marler : The class used for the markers of transport en publique, like metro and bus*/
  .marker-in-front {
    .poi-marker.optyweb{
      display: flex; // flexbox to center the child element
      justify-content: center; // Center horizontally
      align-items: center; // Center vertically
      width: 44px;
      height: 44px;
      font-size: 1.4rem;
      color: #fff;
      background-color: $secondary;
      &::before {
        display: block;
      }
      .occupancy-marker-gauge {
        top: 25px;
        right: -2px;
        width: 9px;
        height: calc(44px + 0.2rem);
        &:before {
          width: 9px;
        }
      }
    }
    .stop-mode-marker.optyweb:not(.cw-mode-unknown){
      display: flex; // flexbox to center the child element
      justify-content: center; // Center horizontally
      align-items: center; // Center vertically
      width: 44px;
      height: 44px;
      font-size: 1.4rem;
      color: #fff;
      background-color: $secondary;
      &::before {
        display: block;
      }
    }
  }

  .iti-direction-right:before {
    content: "\e1ce";
  }
  .iti-direction-continue:before {
    content: "\e90b";
  }
  .iti-direction-left:before {
    content: "\e1cd";
  }
  .iti-direction-light-right:before {
    content: "\e90a";
  }
  .iti-direction-light-left:before {
    content: "\e90a";
  }

  .categ-school::before {
    content: "\e0c8";
  }
  .categ-bus::before {
    content: "\e1fa";
  }
  .categ-bikestation::before {
    content: "\e16e";
  }
  .categ-bikepark:before {
    content: "\e167";
  }
  .categ-parking::before {
    content: "\e1a0";
  }
  .categ-port::before {
  }
  .cityway-v8 .categ-line:before {
    content: "\e1f2";
  }
  .categ-carpool::before {
    content: "\e0a1";
  }
  .categ-car::before {
    content: "\e203";
  }
  .categ-electricstation:before {
    content: "\e174";
  }
  .cw-misc-watts:before {
    content: "\e92e";
  }
  .cw-toolbar-calendar-schedule:before {
    content: "\e929";
  }
  .cw-toolbar-calendar-checked:before {
    content: "\e928";
  }
  .categ-culture::before {
    content: "\e16c";
  }
  .categ-cityhall::before {
    content: "\e16f";
  }
  .categ-taxistation:before {
    content: "\e0c6";
  }
  .categ-health::before {
    content: "\e172";
  }
  .categ-service::before {
    content: "\e17e";
  }
  .categ-placeofworship::before {
    content: "\e17a";
  }
  .categ-park::before {
    content: "\e19a";
  }
  .categ-parkandride:before {
    content: "\e1a1";
  }
  .categ-sport::before {
    content: "\e17c";
  }
  .categ-info::before {
    content: "\e15d";
  }

  .nearby-poi-popup {
    margin-top: 1em;
  }
  .nearby-poi-popup > ul {
    position: absolute;
    right: 10px;
    margin: 0;
  }
  .nearby-poi-popup-place {
    &-title {
      font-size: $font-size-content;
      margin-bottom: 4px;
    }
    .nearby-poi-popup-place-distance {
      color: $secondary;
    }
    .nearby-poi-popup-place-address {
      display: flex;
      justify-content: space-between;
    }
  }
  .nearby-poi-popup-poi-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    color: #fff;
    color: white;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    background-color: $secondary;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  .nearby-poi-popup-poi-label {
    margin-bottom: 1.5rem;
    font-size: $font-size-locality;
    color: $text-color;
  }
  .nearby-mode-title {
    font-weight: $font-weight-bold;
    font-size: $font-size-content;
    margin-top: 0.8em;
    margin-bottom: 1.5rem;
  }
  .nearby-mode-item-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nearby-modal-context,
  .nearby-home,
  .nearby-popup {
    .title-with-icon {
      margin: 0 0 1.5rem;
      font-size: 1rem;
      line-height: 1.3;
    }
    .card.place-card {
      margin-bottom: 1.5rem;
      border-radius: $global-border-radius;
      box-shadow: 0 0 7px #0000001a;
      .card-body {
        padding: 1rem;
        align-items: center;
        .place-card-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 2.625rem;
          height: 2.625rem;
          margin-right: 0.875rem;
          font-size: 1.6rem;
          color: #fff;
          border-radius: 100rem;
          background-color: $secondary;
        }
        .place-card-category {
          font-size: $font-size-medium;
          color: #6a6a6a;
          padding: 0;
          border: 0;
        }
        .place-card-label {
          font-size: 1rem;
          color: #333;
        }
        .place-card-address {
          font-size: $font-size-medium;
          color: #6a6a6a;
          .place-card-distance {
            color: $secondary;
          }
        }
      }
    }
    .text-center {
      .nearby-modal-context-lines-map-btn {
        margin: 0 0 1rem 0;
        padding: 1em 2rem;
        border: 1px solid $primary;
        border-radius: $global-border-radius;
        background-color: #fff;
        color: $text-color;
        &:hover {
          color: $primary;
        }
      }
    }
  }

  .occupancy-card {
    box-shadow: 0 0 7px #0000001a;
    margin-top: 1rem;
    .card-body {
      padding: 1rem;
    }
  }

  .nearby-mode-item-form-check-label-icon {
    align-self: unset !important;
    position: unset !important;
    left: unset !important;
    transform: unset !important;
    top: unset !important;
  }
  .nearby-mode-item-form-check-label-icon {
    padding: 0.7rem;
  }

  .btn-from-to-item {
    background-color: white !important;
    color: black;
    border: 3px solid $primary;
    border-radius: $global-border-radius;
  }
  .btn-from-to-item-btn {
    background-color: transparent;
    border-color: transparent;
    color: black;
    display: flex !important;
    gap: 5px;
  }
  .btn-from-to-item-btn:focus {
    background-color: transparent;
    color: black;
  }
  .btn-from-to-item-btn:hover {
    background-color: transparent;
    color: black;
  }

  .ngb-toasts {
    top: 80px;
  }

  /** Mobile */
  @media screen and (max-width: 600px) {
    .autocompletion-panel {
      height: calc(100vh - 60px);
      border-radius: 0px !important;
    }
    .autocompletion-panel-position-btn {
      background-color: #f1f3f5;
      filter: none;
      border-radius: 0.5rem;
      margin: 0;
      width: 91%;
      padding: 0.5rem;
      margin: 0.5rem 1rem;
      font-family: $font;
      display: flex !important;
      align-items: center;
      .cw-map-geolocalisation {
        margin-right: 10px;
      }
    }
    .btn-previous-page {
      margin-right: 1em;
    }

    .panel-content {
      width: 28.5rem !important;
      margin-left: 1em;
    }
    #pan-panel .panel-content-scroll {
      padding: 1.5rem 2.5rem 2.5rem 1.5rem;
    }

    .nav-tabs .nav-item .nav-link.active {
      flex: 1;
      background-color: $primary !important;
      color: white !important;
      border: none;
      padding: 0.5rem;
      border-radius: 1rem;
      background-color: #f8f9fa;
      margin-bottom: 1.5rem;
    }
  }

  // Alerte qui s'affiche lorsque la map est disabled.
  .alert.alert-warning.d-flex.map-disabled-alert {
    position: absolute;
    z-index: 9999;
    text-align: center;
    width: 30%;
    word-wrap: break-word;
    top: 0;
  }

  .network-logo {
    max-width: 4.6rem;
    max-height: 1.5rem;
  }
  .network-logo-road-sheet{
    max-width: 100px;
  }
  .network-logo-schedules {
    max-width: 6rem;
    max-height: 4.3rem;
  }
  .schedule-header-line {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  

  .btn-favourite:hover {
    background-color: #f4f4f4 !important;
  }
}
