@import "../../variables.scss";
@import "../../mixins.scss";

.basket-container,
.cart-summary-container {
  .cw-misc-basket.modal-header-icon {
    background-color: $primary !important;
    color: #fff;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 2rem auto;
  }
  .basket-summary-btn {
    background: $secondary;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  .btn-primary {
    width: 100%;
  }
  .d-block.item-location {
    .item-location-label {
      margin-right: calc(1rem * 0.5);
      font-size: $font-size-medium;
      color: $text-color;
    }
    .item-location-content {
      font-weight: bold;
      font-size: $font-size-medium;
    }
  }
  .basket-confirmation {
    .modal-header-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7rem;
      height: 7rem;
      margin: -5rem auto 1rem;
      font-size: 3rem;
      color: #fff;
      border-radius: 100rem;
    }
    .btn-secondary {
      background-color: $primary;
      border-color: $primary;
      border-radius: $global-border-radius;
      font-weight: bold;
      padding: 1rem 3rem;
    }
  }
}
.my-wallet-page-h1cart-summary-h1,
.my-wallet-page-h1cart-payment-h1,
.my-wallet-page-h1cart-confirmation-h1 {
  font-size: $font-size-title;
  margin-bottom: 2rem;
}
.cart-summary-container {
  h2 {
    margin-top: 2rem;
    font-size: $font-size-large-icon;
    font-weight: bold;
    line-height: 1.7;
  }
  .title-with-icon {
    margin-top: 2rem;
    font-size: $font-size-content;
    font-weight: bold;
    line-height: 1.3;
    .title-with-icon-icon {
      padding: 0.35rem;
      font-size: 1.2rem;
      color: #ffffff;
      border: $secondary;
      border-radius: 100rem;
      background-color: $secondary;
      margin-right: 0.5rem;
    }
  }
  .cart-summary-card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 1rem;
    border-radius: $global-border-radius;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);

    .cart-summary-card-delete-btn {
      background: #6a6a6a;
      border-radius: 100px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      color: #fff;
      &:hover {
        background-color: black;
      }
    }
  }
  .btn-link {
    color: $primary;
    font-weight: bold;
    text-decoration: underline;
    &:not(.p-0) {
      //margin-bottom: 1em;
    }
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}
.cart-payment-container {
  .card {
    border-radius: 0.9375rem;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  }
  .btn-primary {
    font-weight: bold;
    padding: 0.75em;
    color: #ffffff;
    background-color: $primary;
    border-color: $primary;
    border-radius: $global-border-radius;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
      0 1px 1px rgba(0, 0, 0, 0.075);
  }
  .btn-link {
    color: $primary;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 1em;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.cart-confirmation-container {
  .card.cart-confirmation-card {
    margin-top: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border-radius: $global-border-radius;
    background-clip: border-box;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    .modal-header-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7rem;
      height: 7rem;
      margin: -5rem auto 1rem;
      font-size: 3rem;
      color: #ffffff;
      border-radius: 100rem;
      background-color: $primary;
    }
    .btn-secondary {
      background-color: #ffffff;
      border-color: $primary;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075);
      color: $primary;
      border-radius: $global-border-radius;
      margin-bottom: 1rem;
    }
  }
}
