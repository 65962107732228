// La liste des logos dans toutes les pages de paiements et les pages qui contiennet ces logos
ctw-payment-logo-list {
    ul {
      align-items: center; // Centers items vertically
      justify-content: center;
      .credit-card-logo {
        max-width: 35px; 
        min-width: 35px;
        padding:5px;
      }
    }
    .credit-card-logo-sepa{
      font-size: 2.5rem;
    }
  }