@import "ctw-icomoon-variables";

.optyweb {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "optyweb" !important;
  src: url("../fonts/optyweb/optyweb.woff") format("woff"),
    url("../fonts/optyweb/optyweb.woff2") format("woff2");
  // chemins en absolus
  font-weight: bold;
  font-style: normal;
}
.optyweb {
  @include optyweb;
  &.cw-misc-tariff-profile:before {
    content: $cw-misc-tariff-profile;
  }

  &.cw-mode-multi-train:before {
    content: $cw-mode-multi-train;
  }

  &.cw-account-service:before {
    content: $cw-account-service;
  }

  &.cw-sale-add-card:before {
    content: $cw-sale-add-card;
  }

  &.cw-sale-price-dollar:before {
    content: $cw-sale-price-dollar;
  }

  &.cw-sale-price-euro:before {
    content: $cw-sale-price-euro;
  }

  &.cw-sale-wallet:before {
    content: $cw-sale-wallet;
  }

  &.cw-poi-ski-resort:before {
    content: $cw-poi-ski-resort;
  }

  &.cw-poi-event:before {
    content: $cw-poi-event;
  }

  &.cw-poi-military:before {
    content: $cw-poi-military;
  }

  &.cw-misc-sepa::before {
    content: $cw-misc-sepa;
  }

  &.cw-poi-business-facilities::before {
    content: $cw-poi-business-facilities;
  }

  &.cw-toolbar-table::before {
    content: $cw-toolbar-table;
  }

  &.cw-poi-bike-station::before {
    content: $cw-poi-bike-station;
  }

  &.cw-mode-realtime-vehicle::before {
    content: $cw-mode-realtime-vehicle;
  }

  &.cw-poi-fireman::before {
    content: $cw-poi-fireman;
  }

  &.cw-poi-community-services::before {
    content: $cw-poi-community-services;
  }

  &.cw-poi-bank::before {
    content: $cw-poi-bank;
  }

  &.cw-toolbar-login::before {
    content: $cw-toolbar-login;
  }

  &.cw-data-protection::before {
    content: $cw-data-protection;
  }

  &.cw-toolbar-more::before {
    content: $cw-toolbar-more;
  }

  &.cw-toolbar-new-window::before {
    content: $cw-toolbar-new-window;
  }

  &.cw-toolbar-edit::before {
    content: $cw-toolbar-edit;
  }

  &.cw-toolbar-download::before {
    content: $cw-toolbar-download;
  }

  &.cw-toolbar-delete::before {
    content: $cw-toolbar-delete;
  }

  &.cw-toolbar-destroy::before {
    content: $cw-toolbar-destroy;
  }

  &.cw-toolbar-disconnect::before {
    content: $cw-toolbar-disconnect;
  }

  &.cw-toolbar-calendar::before {
    content: $cw-toolbar-calendar;
  }

  &.cw-social-comment::before {
    content: $cw-social-comment;
  }

  &.cw-alert-comment::before {
    content: $cw-alert-comment;
  }

  &.cw-social-facebook::before {
    content: $cw-social-facebook;
  }

  &.cw-social-facebook-alternate::before {
    content: $cw-social-facebook-alternate;
  }

  &.cw-social-mail::before {
    content: $cw-social-mail;
  }

  &.cw-poi-road::before {
    content: $cw-poi-road;
  }

  &.cw-social-mail2::before {
    content: $cw-social-mail2;
  }

  &.cw-social-rss::before {
    content: $cw-social-rss;
  }

  &.cw-social-share::before {
    content: $cw-social-share;
  }

  &.cw-social-sms::before {
    content: $cw-social-sms;
  }

  &.cw-social-twitter::before {
    content: $cw-social-twitter;
  }

  &.cw-social-twitter-alternate::before {
    content: $cw-social-twitter-alternate;
  }

  &.cw-social-linkedin-alternate::before {
    content: $cw-social-linkedin-alternate;
  }

  &.cw-social-linkedin::before {
    content: $cw-social-linkedin;
  }

  &.cw-social-instagram::before {
    content: $cw-social-instagram;
  }

  &.cw-regularize-payment::before {
    content: $cw-regularize-payment;
  }

  &.cw-incident-payment::before {
    content: $cw-incident-payment;
  }

  &.cw-payement::before {
    content: $cw-payement;
  }

  &.cw-toolbar-add-favorite::before {
    content: $cw-toolbar-add-favorite;
  }

  &.cw-toolbar-add::before {
    content: $cw-toolbar-add;
  }

  &.cw-toolbar-cancel-filter::before {
    content: $cw-toolbar-cancel-filter;
  }

  &.cw-toolbar-close1::before {
    content: $cw-toolbar-close1;
  }

  &.cw-toolbar-export::before {
    content: $cw-toolbar-export;
  }

  &.cw-boarding-only::before {
    content: $cw-boarding-only;
  }

  &.cw-drop-off-forbidden::before {
    content: $cw-drop-off-forbidden;
  }

  &.cw-boarding-forbidden::before {
    content: $cw-boarding-forbidden;
  }

  &.cw-social-instagram1::before {
    content: $cw-social-instagram1;
  }

  &.cw-toolbar-search2::before {
    content: $cw-toolbar-search2;
  }

  &.cw-quit::before {
    content: $cw-quit;
  }

  &.cw-mode-rer::before {
    content: $cw-mode-rer;
  }

  &.cw-nav-next-right::before {
    content: $cw-nav-next-right;
  }

  &.cw-nav-next-left::before {
    content: $cw-nav-next-left;
  }

  &.cw-event-fog::before {
    content: $cw-event-fog;
  }

  &.cw-event-snow::before {
    content: $cw-event-snow;
  }

  &.cw-favourite-alarm::before {
    content: $cw-favourite-alarm;
  }

  &.cw-mode-wait::before {
    content: $cw-mode-wait;
  }

  &.cw-toolbar-duration::before {
    content: $cw-toolbar-duration;
  }

  &.cw-toolbar-schedules::before {
    content: $cw-toolbar-schedules;
  }

  &.cw-nav-small-menu::before {
    content: $cw-nav-small-menu;
  }

  &.cw-show-map::before {
    content: $cw-show-map;
  }

  &.cw-mode-coach-access::before {
    content: $cw-mode-coach-access;
  }

  &.cw-poi-police::before {
    content: $cw-poi-police;
  }

  &.cw-poi-airport::before {
    content: $cw-poi-airport;
  }

  &.cw-poi-tourist-office::before {
    content: $cw-poi-tourist-office;
  }

  &.cw-poi-museum::before {
    content: $cw-poi-museum;
  }

  &.cw-poi-hotel::before {
    content: $cw-poi-hotel;
  }

  &.cw-poi-marina::before {
    content: $cw-poi-marina;
  }

  &.cw-pole-echange::before {
    content: $cw-pole-echange;
  }

  &.cw-poi-carpool-area::before {
    content: $cw-poi-carpool-area;
  }

  &.cw-agence-information::before {
    content: $cw-agence-information;
  }

  &.cw-poi-beach::before {
    content: $cw-poi-beach;
  }

  &.cw-misc-phone2::before {
    content: $cw-misc-phone2;
  }

  &.cw-misc-newsletter::before {
    content: $cw-misc-newsletter;
  }

  &.cw-event-rain::before {
    content: $cw-event-rain;
  }

  &.cw-streetview::before {
    content: $cw-streetview;
  }

  &.cw-poi-carpool::before {
    content: $cw-poi-carpool;
  }

  &.cw-poi-carpool-transition::before {
    content: $cw-poi-carpool-transition;
  }

  &.cw-poi-tod-stop::before {
    content: $cw-poi-tod-stop;
  }

  &.cw-poi-taxi-station::before {
    content: $cw-poi-taxi-station;
  }

  &.cw-poi-taxi-terminal::before {
    content: $cw-poi-taxi-terminal;
  }

  &.cw-mode-school2::before {
    content: $cw-mode-school2;
  }

  &.cw-poi-club::before {
    content: $cw-poi-club;
  }

  &.cw-poi-companies::before {
    content: $cw-poi-companies;
  }

  &.cw-mode-ler::before {
    content: $cw-mode-ler;
  }

  &.cw-poi-camping::before {
    content: $cw-poi-camping;
  }

  &.cw-poi-autopartage-station::before {
    content: $cw-poi-autopartage-station;
  }

  &.cw-poi-intersection::before {
    content: $cw-poi-intersection;
  }

  &.cw-poi-cable-car::before {
    content: $cw-poi-cable-car;
  }

  &.cw-misc-news::before {
    content: $cw-misc-news;
  }

  &.cw-misc-money::before {
    content: $cw-misc-money;
  }

  &.cw-misc-next-departure-time::before {
    content: $cw-misc-next-departure-time;
  }

  &.cw-misc-list-circle1::before {
    content: $cw-misc-list-circle1;
  }

  &.cw-misc-list-arrow2::before {
    content: $cw-misc-list-arrow2;
  }

  &.cw-misc-fuel::before {
    content: $cw-misc-fuel;
  }

  &.cw-misc-health::before {
    content: $cw-misc-health;
  }

  &.cw-misc-ecology::before {
    content: $cw-misc-ecology;
  }

  &.cw-misc-ecology1::before {
    content: $cw-misc-ecology1;
  }

  &.cw-misc-copyright::before {
    content: $cw-misc-copyright;
  }

  &.cw-misc-call::before {
    content: $cw-misc-call;
  }

  &.cw-location-agency::before {
    content: $cw-location-agency;
  }

  &.cw-misc-sale::before {
    content: $cw-misc-sale;
  }

  &.cw-access-accompanied-hearing-impaired-user::before {
    content: $cw-access-accompanied-hearing-impaired-user;
  }

  &.cw-access-accompanied-learning-impaired-user::before {
    content: $cw-access-accompanied-learning-impaired-user;
  }

  &.cw-access-accompanied-visually-challenged-user::before {
    content: $cw-access-accompanied-visually-challenged-user;
  }

  &.cw-access-accompanied-wheelchair-user::before {
    content: $cw-access-accompanied-wheelchair-user;
  }

  &.cw-access-hearing-impaired-user::before {
    content: $cw-access-hearing-impaired-user;
  }

  &.cw-access-learning-impaired-user::before {
    content: $cw-access-learning-impaired-user;
  }

  &.cw-access-visually-challenged-user::before {
    content: $cw-access-visually-challenged-user;
  }

  &.cw-social-addthis::before {
    content: $cw-social-addthis;
  }

  &.cw-misc-google-plus::before {
    content: $cw-misc-google-plus;
  }

  &.cw-misc-info::before {
    content: $cw-misc-info;
  }

  &.cw-misc-text::before {
    content: $cw-misc-text;
  }

  &.cw-misc-tourims::before {
    content: $cw-misc-tourims;
  }

  &.cw-poi-stop2::before {
    content: $cw-poi-stop2;
  }

  &.cw-poi-bike-park3::before {
    content: $cw-poi-bike-park3;
  }

  &.cw-poi-district2::before {
    content: $cw-poi-district2;
  }

  &.cw-poi-culture2::before {
    content: $cw-poi-culture2;
  }

  &.cw-poi-bike-rental::before {
    content: $cw-poi-bike-rental;
  }

  &.cw-poi-bike-park2::before {
    content: $cw-poi-bike-park2;
  }

  &.cw-poi-administration2::before {
    content: $cw-poi-administration2;
  }

  &.cw-poi-health2::before {
    content: $cw-poi-health2;
  }

  &.cw-poi-borne-electric::before {
    content: $cw-poi-borne-electric;
  }

  &.cw-poi-worship2::before {
    content: $cw-poi-worship2;
  }

  &.cw-poi-sport2::before {
    content: $cw-poi-sport2;
  }

  &.cw-poi-pool::before {
    content: $cw-poi-pool;
  }

  &.cw-poi-service2::before {
    content: $cw-poi-service2;
  }

  &.cw-poi-sale2::before {
    content: $cw-poi-sale2;
  }

  &.cw-poi-zone2::before {
    content: $cw-poi-zone2;
  }

  &.cw-poi-zcommercial2::before {
    content: $cw-poi-zcommercial2;
  }

  &.cw-poi-vaccination::before {
    content: $cw-poi-vaccination;
  }

  &.cw-misc-screen::before {
    content: $cw-misc-screen;
  }

  &.cw-online-agency::before {
    content: $cw-online-agency;
  }

  &.cw-online-services::before {
    content: $cw-online-services;
  }

  &.cw-misc-man2::before {
    content: $cw-misc-man2;
  }

  &.cw-toolbar-file::before {
    content: $cw-toolbar-file;
  }

  &.cw-poi-entertainment2::before {
    content: $cw-poi-entertainment2;
  }

  &.cw-iti-return-trip21::before {
    content: $cw-iti-return-trip21;
  }

  &.cw-misc-tablet::before {
    content: $cw-misc-tablet;
  }

  &.cw-poi-sncf::before {
    content: $cw-poi-sncf;
  }

  &.cw-poi-park2::before {
    content: $cw-poi-park2;
  }

  &.cw-mode-train::before {
    content: $cw-mode-train;
  }

  &.cw-misc-hourglass::before {
    content: $cw-misc-hourglass;
  }

  &.cw-alert-warning::before {
    content: $cw-alert-warning;
  }

  &.cw-map-map4::before {
    content: $cw-map-map4;
  }

  &.cw-poi-ski-resort2::before {
    content: $cw-poi-ski-resort2;
  }

  &.cw-poi-parking2::before {
    content: $cw-poi-parking2;
  }

  &.cw-poi-park-and-ride2::before {
    content: $cw-poi-park-and-ride2;
  }

  &.cw-poi-park-and-bike::before {
    content: $cw-poi-park-and-bike;
  }

  &.cw-poi-education2::before {
    content: $cw-poi-education2;
  }

  &.cw-toolbar-full-screen2::before {
    content: $cw-toolbar-full-screen2;
  }

  &.cw-misc-route4::before {
    content: $cw-misc-route4;
  }

  &.cw-misc-route3::before {
    content: $cw-misc-route3;
  }

  &.cw-misc-windows-phone::before {
    content: $cw-misc-windows-phone;
  }

  &.cw-map-map3::before {
    content: $cw-map-map3;
  }

  &.cw-misc-sale1::before {
    content: $cw-misc-sale1;
  }

  &.cw-misc-ticket2::before {
    content: $cw-misc-ticket2;
  }

  &.cw-misc-ticket3::before {
    content: $cw-misc-ticket3;
  }

  &.cw-reload-card::before {
    content: $cw-reload-card;
  }

  &.cw-past-journey::before {
    content: $cw-past-journey;
  }

  &.cw-access-wheelchair-user::before {
    content: $cw-access-wheelchair-user;
  }

  &.cw-alert-error2::before {
    content: $cw-alert-error2;
  }

  &.cw-alert-notif::before {
    content: $cw-alert-notif;
  }

  &.cw-alert-help::before {
    content: $cw-alert-help;
  }

  &.cw-alert-info2::before {
    content: $cw-alert-info2;
  }

  &.cw-alert-success2::before {
    content: $cw-alert-success2;
  }

  &.cw-arrow-left::before {
    content: $cw-arrow-left;
  }

  &.cw-arrow-right::before {
    content: $cw-arrow-right;
  }

  &.cw-event-comercial::before {
    content: $cw-event-comercial;
  }

  &.cw-event-disrupt-favorite-line::before {
    content: $cw-event-disrupt-favorite-line;
  }

  &.cw-event-disrupt-public-transport::before {
    content: $cw-event-disrupt-public-transport;
  }

  &.cw-event-disrupt::before {
    content: $cw-event-disrupt;
  }

  &.cw-event-march::before {
    content: $cw-event-march;
  }

  &.cw-event-social-movement::before {
    content: $cw-event-social-movement;
  }

  &.cw-event-warning::before {
    content: $cw-event-warning;
  }

  &.cw-activity::before {
    content: $cw-activity;
  }

  &.cw-center-commercial::before {
    content: $cw-center-commercial;
  }

  &.cw-alert-commercial::before {
    content: $cw-alert-commercial;
  }

  &.cw-event-weather::before {
    content: $cw-event-weather;
  }

  &.cw-iti-border::before {
    content: $cw-iti-border;
  }

  &.cw-iti-favorite::before {
    content: $cw-iti-favorite;
  }

  &.cw-iti-from::before {
    content: $cw-iti-from;
  }

  &.cw-iti-journey::before {
    content: $cw-iti-journey;
  }

  &.cw-iti-plan::before {
    content: $cw-iti-plan;
  }

  &.cw-iti-recent-trip::before {
    content: $cw-iti-recent-trip;
  }

  &.cw-iti-return-trip2::before {
    content: $cw-iti-return-trip2;
  }

  &.cw-iti-smart-assistant::before {
    content: $cw-iti-smart-assistant;
  }

  &.cw-iti-to::before {
    content: $cw-iti-to;
  }

  &.cw-iti-turn-left::before {
    content: $cw-iti-turn-left;
  }

  &.cw-iti-turn-right::before {
    content: $cw-iti-turn-right;
  }

  &.cw-iti-via::before {
    content: $cw-iti-via;
  }

  &.cw-map-full-screen-return::before {
    content: $cw-map-full-screen-return;
  }

  &.cw-map-full-screen::before {
    content: $cw-map-full-screen;
  }

  &.cw-map-geolocalisation::before {
    content: $cw-map-geolocalisation;
  }

  &.cw-map-location::before {
    content: $cw-map-location;
  }

  &.cw-map-map1::before {
    content: $cw-map-map1;
  }

  &.cw-map-map2::before {
    content: $cw-map-map2;
  }

  &.cw-map-displacement::before {
    content: $cw-map-displacement;
  }

  &.cw-map-move::before {
    content: $cw-map-move;
  }

  &.cw-map-navigation::before {
    content: $cw-map-navigation;
  }

  &.cw-map-nearest::before {
    content: $cw-map-nearest;
  }

  &.cw-map-itinary::before {
    content: $cw-map-itinary;
  }

  &.cw-map-satellite::before {
    content: $cw-map-satellite;
  }

  &.cw-misc-access::before {
    content: $cw-misc-access;
  }

  &.cw-misc-alarm::before {
    content: $cw-misc-alarm;
  }

  &.cw-misc-android::before {
    content: $cw-misc-android;
  }

  &.cw-misc-apple::before {
    content: $cw-misc-apple;
  }

  &.cw-misc-arrival::before {
    content: $cw-misc-arrival;
  }

  &.cw-misc-basket::before {
    content: $cw-misc-basket;
  }

  &.cw-misc-camcorder::before {
    content: $cw-misc-camcorder;
  }

  &.cw-misc-camera::before {
    content: $cw-misc-camera;
  }

  &.cw-misc-departure::before {
    content: $cw-misc-departure;
  }

  &.cw-misc-hour1::before {
    content: $cw-misc-hour1;
  }

  &.cw-misc-hour2::before {
    content: $cw-misc-hour2;
  }

  &.cw-misc-legal-info::before {
    content: $cw-misc-legal-info;
  }

  &.cw-misc-legend::before {
    content: $cw-misc-legend;
  }

  &.cw-misc-list-circle2::before {
    content: $cw-misc-list-circle2;
  }

  &.cw-misc-list-diamond1::before {
    content: $cw-misc-list-diamond1;
  }

  &.cw-misc-list-diamond2::before {
    content: $cw-misc-list-diamond2;
  }

  &.cw-misc-list-square1::before {
    content: $cw-misc-list-square1;
  }

  &.cw-misc-list-square2::before {
    content: $cw-misc-list-square2;
  }

  &.cw-misc-list-triangle1::before {
    content: $cw-misc-list-triangle1;
  }

  &.cw-misc-phone::before {
    content: $cw-misc-phone;
  }

  &.cw-misc-registered::before {
    content: $cw-misc-registered;
  }

  &.cw-misc-route::before {
    content: $cw-misc-route;
  }

  &.cw-misc-route2::before {
    content: $cw-misc-route2;
  }

  &.cw-misc-smartphone::before {
    content: $cw-misc-smartphone;
  }

  &.cw-misc-smartphone2::before {
    content: $cw-misc-smartphone2;
  }

  &.cw-misc-ticket::before {
    content: $cw-misc-ticket;
  }

  &.cw-card::before {
    content: $cw-card;
  }

  &.cw-misc-trick::before {
    content: $cw-misc-trick;
  }

  &.cw-misc-via::before {
    content: $cw-misc-via;
  }

  &.cw-misc-youtube::before {
    content: $cw-misc-youtube;
  }

  &.cw-mode-bike-bus::before {
    content: $cw-mode-bike-bus;
  }

  &.cw-mode-bike-service::before {
    content: $cw-mode-bike-service;
  }

  &.cw-mode-bike::before {
    content: $cw-mode-bike;
  }

  &.cw-mode-harbor::before {
    content: $cw-mode-harbor;
  }

  &.cw-mode-boat::before {
    content: $cw-mode-boat;
  }

  &.cw-mode-bus-access::before {
    content: $cw-mode-bus-access;
  }

  &.cw-mode-bus::before {
    content: $cw-mode-bus;
  }

  &.cw-mode-cable-car::before {
    content: $cw-mode-cable-car;
  }

  &.cw-mode-car-pool::before {
    content: $cw-mode-car-pool;
  }

  &.cw-mode-car::before {
    content: $cw-mode-car;
  }

  &.cw-toilets::before {
    content: $cw-toilets;
  }

  &.cw-mode-coach-ter::before {
    content: $cw-mode-coach-ter;
  }

  &.cw-bus-shelter::before {
    content: $cw-bus-shelter;
  }

  &.cw-poi-civil-security::before {
    content: $cw-poi-civil-security;
  }

  &.cw-poi-sociocultural::before {
    content: $cw-poi-sociocultural;
  }

  &.cw-mode-coach::before {
    content: $cw-mode-coach;
  }

  &.cw-mode-cyclopouss::before {
    content: $cw-mode-cyclopouss;
  }

  &.cw-mode-elevator::before {
    content: $cw-mode-elevator;
  }

  &.cw-pin-arrival::before {
    content: $cw-pin-arrival;
  }

  &.cw-poi-lieudit::before {
    content: $cw-poi-lieudit;
  }

  &.cw-poi-transition-point::before {
    content: $cw-poi-transition-point;
  }

  &.cw-poi-exit-point::before {
    content: $cw-poi-exit-point;
  }

  &.cw-poi-infos-bus::before {
    content: $cw-poi-infos-bus;
  }

  &.cw-poi-adress::before {
    content: $cw-poi-adress;
  }

  &.cw-pin-via::before {
    content: $cw-pin-via;
  }

  &.cw-pin-departure::before {
    content: $cw-pin-departure;
  }

  &.cw-pin-interest::before {
    content: $cw-pin-interest;
  }

  &.cw-pin::before {
    content: $cw-pin;
  }

  &.cw-poi-enter-point::before {
    content: $cw-poi-enter-point;
  }

  &.cw-poi-parking-electric-vehicle::before {
    content: $cw-poi-parking-electric-vehicle;
  }

  &.cw-mode-bus-electric::before {
    content: $cw-mode-bus-electric;
  }

  &.cw-poi-info-quartier::before {
    content: $cw-poi-info-quartier;
  }

  &.cw-poi-restaurant::before {
    content: $cw-poi-restaurant;
  }

  &.cw-poi-bar::before {
    content: $cw-poi-bar;
  }

  &.cw-mode-car-bus::before {
    content: $cw-mode-car-bus;
  }

  &.cw-mode-escalator::before {
    content: $cw-mode-escalator;
  }

  &.cw-mode-funicular::before {
    content: $cw-mode-funicular;
  }

  &.cw-mode-ler2::before {
    content: $cw-mode-ler2;
  }

  &.cw-mode-metro::before {
    content: $cw-mode-metro;
  }

  &.cw-mode-minibus::before {
    content: $cw-mode-minibus;
  }

  &.cw-mode-plane::before {
    content: $cw-mode-plane;
  }

  &.cw-mode-school::before {
    content: $cw-mode-school;
  }

  &.cw-mode-school3::before {
    content: $cw-mode-school3;
  }

  &.cw-mode-taxi::before {
    content: $cw-mode-taxi;
  }

  &.cw-mode-taxibus::before {
    content: $cw-mode-taxibus;
  }

  &.cw-mode-ter::before {
    content: $cw-mode-ter;
  }

  &.cw-mode-tgv::before {
    content: $cw-mode-tgv;
  }

  &.cw-mode-tod-ufr::before {
    content: $cw-mode-tod-ufr;
  }

  &.cw-mode-tod::before {
    content: $cw-mode-tod;
  }

  &.cw-mode-trolley::before {
    content: $cw-mode-trolley;
  }

  &.cw-mode-fastest::before {
    content: $cw-mode-fastest;
  }

  &.cw-mode-walk::before {
    content: $cw-mode-walk;
  }

  &.cw-mode-correspondence::before {
    content: $cw-mode-correspondence;
  }

  &.cw-nav-collapse1::before {
    content: $cw-nav-collapse1;
  }

  &.cw-nav-collapse2::before {
    content: $cw-nav-collapse2;
  }

  &.cw-nav-connexion::before {
    content: $cw-nav-connexion;
  }

  &.cw-nav-dashboard::before {
    content: $cw-nav-dashboard;
  }

  &.cw-nav-expand1::before {
    content: $cw-nav-expand1;
  }

  &.cw-nav-expand2::before {
    content: $cw-nav-expand2;
  }

  &.cw-nav-first::before {
    content: $cw-nav-first;
  }

  &.cw-nav-home::before {
    content: $cw-nav-home;
  }

  &.cw-nav-last::before {
    content: $cw-nav-last;
  }

  &.cw-nav-media-pause::before {
    content: $cw-nav-media-pause;
  }

  &.cw-nav-media-play::before {
    content: $cw-nav-media-play;
  }

  &.cw-nav-menu::before {
    content: $cw-nav-menu;
  }

  &.cw-nav-next1::before {
    content: $cw-nav-next1;
  }

  &.cw-nav-next2::before {
    content: $cw-nav-next2;
  }

  &.cw-nav-page-back::before {
    content: $cw-nav-page-back;
  }

  &.cw-nav-page-bottom::before {
    content: $cw-nav-page-bottom;
  }

  &.cw-nav-page-top::before {
    content: $cw-nav-page-top;
  }

  &.cw-nav-previous1::before {
    content: $cw-nav-previous1;
  }

  &.cw-nav-previous2::before {
    content: $cw-nav-previous2;
  }

  &.cw-mode-tram::before {
    content: $cw-mode-tram;
  }

  &.cw-event-wind::before {
    content: $cw-event-wind;
  }

  &.cw-event-ice::before {
    content: $cw-event-ice;
  }

  &.cw-drop-off-only::before {
    content: $cw-drop-off-only;
  }

  &.cw-direct::before {
    content: $cw-direct;
  }

  &.cw-real-time::before {
    content: $cw-real-time;
  }

  &.cw-misc-basket-2::before {
    content: $cw-misc-basket-2;
  }

  &.cw-hide-map::before {
    content: $cw-hide-map;
  }

  &.cw-choose-map::before {
    content: $cw-choose-map;
  }

  &.cw-toolbar-favorite::before {
    content: $cw-toolbar-favorite;
  }

  &.cw-toolbar-file-bmp::before {
    content: $cw-toolbar-file-bmp;
  }

  &.cw-toolbar-file-excel::before {
    content: $cw-toolbar-file-excel;
  }

  &.cw-toolbar-file-gif::before {
    content: $cw-toolbar-file-gif;
  }

  &.cw-toolbar-file-jpg::before {
    content: $cw-toolbar-file-jpg;
  }

  &.cw-toolbar-file-openoffice-calc::before {
    content: $cw-toolbar-file-openoffice-calc;
  }

  &.cw-toolbar-file-openoffice-impress::before {
    content: $cw-toolbar-file-openoffice-impress;
  }

  &.cw-toolbar-file-openoffice-writer::before {
    content: $cw-toolbar-file-openoffice-writer;
  }

  &.cw-toolbar-file-openoffice::before {
    content: $cw-toolbar-file-openoffice;
  }

  &.cw-toolbar-file-pdf::before {
    content: $cw-toolbar-file-pdf;
  }

  &.cw-toolbar-file-png::before {
    content: $cw-toolbar-file-png;
  }

  &.cw-toolbar-file-powerpoint::before {
    content: $cw-toolbar-file-powerpoint;
  }

  &.cw-toolbar-file-rar::before {
    content: $cw-toolbar-file-rar;
  }

  &.cw-toolbar-file-word::before {
    content: $cw-toolbar-file-word;
  }

  &.cw-toolbar-file-zip::before {
    content: $cw-toolbar-file-zip;
  }

  &.cw-toolbar-filter::before {
    content: $cw-toolbar-filter;
  }

  &.cw-toolbar-paste::before {
    content: $cw-toolbar-paste;
  }

  &.cw-toolbar-print::before {
    content: $cw-toolbar-print;
  }

  &.cw-toolbar-reload::before {
    content: $cw-toolbar-reload;
  }

  &.cw-toolbar-search::before {
    content: $cw-toolbar-search;
  }

  &.cw-misc-discover::before {
    content: $cw-misc-discover;
  }

  &.cw-toolbar-secure::before {
    content: $cw-toolbar-secure;
  }

  &.cw-toolbar-settings::before {
    content: $cw-toolbar-settings;
  }

  &.cw-misc-alarm2::before {
    content: $cw-misc-alarm2;
  }

  &.cw-mode-car-sharing::before {
    content: $cw-mode-car-sharing;
  }

  &.cw-misc-work::before {
    content: $cw-misc-work;
  }

  &.cw-toolbar-user::before {
    content: $cw-toolbar-user;
  }

  &.cw-misc-meeting::before {
    content: $cw-misc-meeting;
  }

  &.cw-event-traffic-accident::before {
    content: $cw-event-traffic-accident;
  }

  &.cw-event-traffic-congestion::before {
    content: $cw-event-traffic-congestion;
  }

  &.cw-event-traffic-construction::before {
    content: $cw-event-traffic-construction;
  }

  &.cw-event-traffic-disabledvehicle::before {
    content: $cw-event-traffic-disabledvehicle;
  }

  &.cw-event-traffic-miscellaneous::before {
    content: $cw-event-traffic-miscellaneous;
  }

  &.cw-event-traffic-obstruction::before {
    content: $cw-event-traffic-obstruction;
  }

  &.cw-event-traffic-sportevent::before {
    content: $cw-event-traffic-sportevent;
  }

  &.cw-event-traffic-blockedroad::before {
    content: $cw-event-traffic-blockedroad;
  }

  &.cw-event-traffic-weather::before {
    content: $cw-event-traffic-weather;
  }

  &.cw-event-traffic-undefined::before {
    content: $cw-event-traffic-undefined;
  }

  &.cw-event-cloud::before {
    content: $cw-event-cloud;
  }

  &.cw-iti-turn-completely-right::before {
    content: $cw-iti-turn-completely-right;
  }

  &.cw-iti-turn-slightly-right::before {
    content: $cw-iti-turn-slightly-right;
  }

  &.cw-iti-straight::before {
    content: $cw-iti-straight;
  }

  &.cw-iti-turn-completely-left::before {
    content: $cw-iti-turn-completely-left;
  }

  &.cw-iti-turn-slightly-left::before {
    content: $cw-iti-turn-slightly-left;
  }

  &.cw-event-few-cloud::before {
    content: $cw-event-few-cloud;
  }

  &.cw-event-sun::before {
    content: $cw-event-sun;
  }

  &.cw-event-disruption-end::before {
    content: $cw-event-disruption-end;
  }

  &.cw-event-change-road::before {
    content: $cw-event-change-road;
  }

  &.cw-poi-market::before {
    content: $cw-poi-market;
  }

  &.cw-misc-kcal::before {
    content: $cw-misc-kcal;
  }

  &.cw-mode-tod-snow::before {
    content: $cw-mode-tod-snow;
  }

  &.cw-misc-bag::before {
    content: $cw-misc-bag;
  }

  &.cw-misc-free:before {
    content: $cw-misc-free;
  }

  &.cw-toolbar-calendar-checked:before {
    content: $cw-toolbar-calendar-checked;
  }

  &.cw-toolbar-calendar-schedule:before {
    content: $cw-toolbar-calendar-schedule;
  }

  &.cw-misc-plug-type2:before {
    content: $cw-misc-plug-type2;
  }

  &.cw-misc-plug-chademo:before {
    content: $cw-misc-plug-chademo;
  }

  &.cw-misc-plug-comboccs:before {
    content: $cw-misc-plug-comboccs;
  }

  &.cw-misc-plug-typeef:before {
    content: $cw-misc-plug-typeEF;
  }

  &.cw-misc-watts:before {
    content: $cw-misc-watts;
  }
}
