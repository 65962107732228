// Ajoute les icons IcoMoon
@import "./assets/theme/ctw-icomoon.scss";

// Style commun pour toutes les cartes (map-common)
@import "./assets/theme/ctw-common-map.scss";

// Style commun le composant login
@import "./assets/theme/ctw-login.scss";

//Style specifique MRN pour le composant schedule (Horaires) --carte
@import "./assets/theme/ctw-schedule.scss";

// Style specifique MRN pour le composant journey-planner (Itinéraire) --carte
@import "./assets/theme/ctw-journey-planner.scss";

// Style specifique MRN pour le composant ctw-journey-planner-options
@import "./assets/theme/ctw-journey-planner-options.scss";

// Style specifique MRN pour le composant disrupt (Infos Trafic)
@import "./assets/theme/ctw-disrupt.scss";

// Style specifique MRN pour le composant journey-planner-widget (Itinéraire de la homepage)
@import "./assets/theme/ctw-journey-planner-widget.scss";

// Style specifique MRN pour le composant journey-planner-panel-header
@import "./assets/theme/ctw-journey-planner-panel-header.scss";

// Style specifique MRN pour le composant nearby (carte interactive)
@import "./assets/theme/ctw-nearby.scss";

// Style specifique MRN pour le composant nearby (carte interactive)
@import "./assets/theme/ctw-disrupt-major.scss";

// Style specifique MRN pour les composant avec changement de mot de passe
@import "./assets/theme/ctw-change-password.scss";

// Style specifique MRN pour le profil
@import "./assets/theme/ctw-profile.scss";

// Style specifique MRN pour le suivi de conso
@import "./assets/theme/ctw-mobility-account.scss";

// Style specifique MRN pour le composant confirmation d'email
@import "./assets/theme/ctw-confirm-email.scss";

// Style specifique MRN pour les composant my tickets
@import "./assets/theme/ctw-my-tickets.scss";

// Style specifique MRN pour la modale
@import "./assets/theme/ctw-modal.scss";

// Style specifique MRN pour le composant breadcrumb-setp
@import "./assets/theme/ctw-breadcrumb-step.scss";

// Style specifique MRN pour le composant panier
@import "./assets/theme/ctw-basket.scss";

// Style specifique MRN pour la partie Mes Cartes
@import "./assets/theme/ctw-my-cards.scss";

// Style specifique MRN pour la partie Transport à la demande
@import "./assets/theme/ctw-tod.scss";

// Style specifique MRN pour la partie paiement des amendes
@import "./assets/theme/fine.scss";

/* Style specifique MRN pour le composant ctw-member */
@import "./assets/theme/ctw-member.scss";

/* Style specifique MRN pour le composant ctw-taxi */
@import "./assets/theme/ctw-taxi.scss";

/* Style specifique MRN pour le composant ctw-taxi-map */
@import "./assets/theme/ctw-taxi-map.scss";

/* Style specifique pour tous les formulaires dans l'application web, le composant form*/
@import "./assets/theme/ctw-form.scss";

/* Style specifique pour tous les composants de cityway de type toasts (alerts)*/
@import "./assets/theme/ctw-toasts.scss";

/* Style specifique pour le composant ctw-journey-planner-print */
@import "./assets/theme/ctw-journey-planner-print.scss";

/* Style spécifique pour le bouton mon compte dans le header de l'app*/
@import "./assets/theme/ctw-member-button.scss";

/* style spécifique à tout les logos de paiements dans lapplication*/
@import "./assets/theme/ctw-payment-logo-list.scss";

/* style spécifique au composant ctw-eshop (eboutique)*/
@import "./assets/theme/ctw-eshop.scss";

/* style spécifique à tous les icons de cityway dans le fichier ctw-common-icons.scss*/
@import "./assets/theme/ctw-common-icons.scss";